import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const breakpoint = {
  phone: '600px',
  tablet: '1100px',
}

export default makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      fill: "#fff"
    },
    textWrapper: {
      position: 'fixed',
      bottom: 40,
      right: 40,
      zIndex: 9000
    },
    [`@media (max-width: ${breakpoint.phone})`]: {
      textWrapper: {
        bottom: 20,
        right: 20
      }
    },
  }),
);
