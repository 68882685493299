import { StyleRules } from '@material-ui/core/styles';
import { ButtonClassKey } from '@material-ui/core';
import colors from '../palette-color';

const Button: Partial<StyleRules<ButtonClassKey>> = {
  root: {
    borderRadius: 8,
    color: colors.grayscale[50],
    fontFamily: ['Montserrat', 'Helvetica', 'sans-serif'].join(','),
    fontSize: '0.875rem',
    fontWeight: 600,
    height: 32,
    letterSpacing: '0.4px',
    lineHeight: '1rem',
    textTransform: 'none',
    padding: '0 16px',
  },
  contained: {
    boxShadow: 'none',
    '&:hover, &:focus': {
      boxShadow: 'none',
    },
  },
  containedPrimary: {
    backgroundColor: colors.primary[50],
    '&:hover, &:focus': {
      backgroundColor: colors.primary[60],
    },
  },
  sizeSmall: {
    fontSize: '0.75rem',
    height: 24,
    letterSpacing: '0.16px',
    lineHeight: '1rem',
    padding: '0 8px',
  },
  sizeLarge: {
    fontSize: '1rem',
    height: 48,
    letterSpacing: '0.8px',
    lineHeight: '2rem',
    padding: '0 24px',
  },
};

export default Button;
