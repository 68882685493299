import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';
import navGestaoSaude from '~images/nav-gestao-saude.svg';

interface SideImageProps {
  children: JSX.Element | JSX.Element[];
}

const SideImagePage = ({ children }: SideImageProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.img}>
        <Typography variant="h1" className={classes.title}>
          Sua
          <span> plataforma de gestão </span>
          da saúde
        </Typography>
        <img
          className={classes.navGestaoSaude}
          src={String(navGestaoSaude)}
          alt="NAV - Sua plataforma de gestão da saúde"
        />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default SideImagePage;
