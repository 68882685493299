import { createMuiTheme } from '@material-ui/core/styles';

import Typography from './typography';
import Palette from './palette';
import AppBar from './appbar';
import Button from './button';
import FormLabel from './formLabel';
import FormHelperText from './formHelperText';
import OutlinedInput from './outlinedInput';
import TableRow from './tableRow';
import TableCell from './tableCell';
import Toolbar from './toolbar';

/**
 * Material UI - https://material-ui.com/pt/customization/palette/
 * https://material-ui.com/customization/default-theme/#default-theme
 * Zeplin - https://app.zeplin.io/project/5f11eafdd98f4f9c08a9937a/dashboard?sid=5f15eae470e6d98c865cd553
 */
const theme = createMuiTheme({
  typography: Typography,
  palette: Palette,
  overrides: {
    MuiAppBar: AppBar,
    MuiButton: Button,
    MuiFormLabel: FormLabel,
    MuiFormHelperText: FormHelperText,
    MuiOutlinedInput: OutlinedInput,
    MuiTableRow: TableRow,
    MuiTableCell: TableCell,
    MuiToolbar: Toolbar,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1112,
      xl: 1920,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 57, // headerHeight for global usage
    },
  },
});

export default theme;
