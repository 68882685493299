import { AuthHospitalTypes, IAuthHospitalActions, IAuthHospitalLoginRequestPayload } from './types';

export const loginRequest = (
  payload: IAuthHospitalLoginRequestPayload,
): IAuthHospitalActions => 
  {
    return {
      type: AuthHospitalTypes.LOGIN_REQUEST_HOSPITAL,
      payload,
    }
  }

export const loginRequestError = (errorMessage: string): IAuthHospitalActions => ({
  type: AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL,
  errorMessage,
});

export const checkHospitalAuthentication = (): IAuthHospitalActions => ({
  type: AuthHospitalTypes.VERIFY_HOSPITAL,
});

export const logoutAction = (): IAuthHospitalActions => ({
  type: AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL,
});

export const updateTermsOfUse = (
  payload: IAuthHospitalLoginRequestPayload,
): IAuthHospitalActions => ({
  type: AuthHospitalTypes.UPDATE_TERMS_OF_USE_HOSPITAL,
  payload,
});

export const cleanRequestErrors = (): IAuthHospitalActions => ({
  type: AuthHospitalTypes.CLEAN_ERROR_MESSAGE_HOSPITAL,
});

export const showModalRedirectToRegister = (showModalToRegister: boolean): IAuthHospitalActions => ({
  type: AuthHospitalTypes.SHOW_MODAL_REDIRECT_TO_REGISTER,
  showModalToRegister,
});
