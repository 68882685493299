import { IAuthHospitalActions, IAuthHospitalState, AuthHospitalTypes } from './types';

const initialState: IAuthHospitalState = {
  isAuthenticated: false,
  loading: false,
  error: false,
  errorMessage: '',
  verified: false,
  hasAcceptedTerms: true,
  updatePassword: false,
  federalTaxIdRegistered: false,
  federalTaxIdAlreadyRegistered: false,
  showModalFeedBackFederalTaxId: false,
  federalTaxId: '',
  federalTaxIdRegisteredError: false,
  messageFederalTaxIdRegisteredError: '',
  federalTaxIdRegisteredFail: false,
};

export default function AuthHospitalReducer(
  state = initialState,
  action: IAuthHospitalActions,
): IAuthHospitalState {
  switch (action.type) {
    case AuthHospitalTypes.LOGIN_REQUEST_HOSPITAL:
      return {
        ...state,
        loading: true,
        error: false,
        hasAcceptedTerms: true,
        errorMessage: '',
      };

    case AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL:
      return {
        ...state,
        loading: false,
        error: true,
        hasAcceptedTerms: true,
        errorMessage: action.errorMessage,
      };

    case AuthHospitalTypes.VERIFY_HOSPITAL:
      return {
        ...state,
        verified: false,
        loading: true,
        error: false,
        hasAcceptedTerms: true,
      };

    case AuthHospitalTypes.AUTHORIZE_HOSPITAL:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        verified: true,
        hasAcceptedTerms: true,
        updatePassword: false,
      };

    case AuthHospitalTypes.UNAUTHORIZE_HOSPITAL:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        verified: true,
        hasAcceptedTerms: true,
      };

    case AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL:
      return { ...initialState, federalTaxId: state.federalTaxId, hasAcceptedTerms: true };

    case AuthHospitalTypes.HAS_ACCEPTED_TERMS_HOSPITAL:
      return {
        ...state,
        loading: false,
        error: false,
        hasAcceptedTerms: false,
      };

    case AuthHospitalTypes.UPDATE_TERMS_OF_USE_HOSPITAL:
      return { ...state, loading: true, error: false, hasAcceptedTerms: true };

    case AuthHospitalTypes.UPDATE_PASSWORD_HOSPITAL:
      return {
        ...state,
        loading: false,
        error: false,
        verified: true,
        updatePassword: true,
      };

    case AuthHospitalTypes.CLEAN_ERROR_MESSAGE_HOSPITAL:
      return {
        ...state,
        errorMessage: '',
        error: false,
      };

    case AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED:
      return {
        ...state,
        federalTaxIdRegistered: action.federalTaxIdRegistered,
      };

    case AuthHospitalTypes.SET_FEDERALTAX_ID_ALREADY_REGISTERED:
      return {
        ...state,
        federalTaxIdAlreadyRegistered: action.federalTaxIdAlreadyRegistered,
      };

    case AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_ERROR:
      return {
        ...state,
        federalTaxIdRegisteredError: action.federalTaxIdRegisteredError,
        messageFederalTaxIdRegisteredError: action.messageFederalTaxIdRegisteredError,
      };

    case AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_FAIL:
      return {
        ...state,
        federalTaxIdRegisteredFail: action.federalTaxIdRegisteredFail,
      };

    case AuthHospitalTypes.SET_FEDERALTAX_ID:
      return {
        ...state,
        federalTaxId: action.federalTaxId,
      };

    case AuthHospitalTypes.SET_SHOW_MODAL_FEEDBACK_FEDERALTAX_ID:
      return {
        ...state,
        showModalFeedBackFederalTaxId: action.showModalFeedBackFederalTaxId,
      };

    default:
      return state;
  }
}
