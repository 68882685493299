import { AxiosPromise } from 'axios';
import api, { pathUserManagementDasa } from '../axios/api';
import { ILoginRequest } from '../auth';

const basePath = `${pathUserManagementDasa}/terms-and-policies`;

export interface ITermOfUse {
  id: number;
  userId: number;
  version: string;
  createdAt: Date;
  updatedAt: Date;
}

const TermsOfUseServices = {
  update: (data: ILoginRequest): AxiosPromise<ITermOfUse> =>
    api.put<ITermOfUse>(`${basePath}`, data),
};

export default TermsOfUseServices;
