import React from 'react';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import useStyles from './styles';
import help from '~images/icons/help.svg';

interface FabButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const FabButton = ({ onClick }: FabButtonProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.textWrapper} >
      <Tooltip title={
        <>
          Precisa de Ajuda? <br/>
          Abra um chamado <OpenInNewIcon fontSize="inherit" className={classes.icon} />
        </>
      } aria-label="abrir-chamado">
        <Fab aria-label="add" variant="round" color="primary" onClick={onClick}>
          <img src={String(help)} alt="Nav logo" className={classes.logo} />
        </Fab>
      </Tooltip>
    </div>
  );
};

export default FabButton;

