import {
  call,
  put,
  all,
  takeLatest,
  AllEffect,
  ForkEffect,
  CallEffect,
  PutEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { FeatureFlagTypes, IFlagsPayload } from './types';
import { featureFlag } from '~services';

export function* handleFeatureFlagLoad(): Generator<CallEffect | PutEffect> {
  try {
    const { data } = (yield call(featureFlag.getFeatureFlags)) as AxiosResponse<IFlagsPayload>;
    yield put({
      type: FeatureFlagTypes.FETCH_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: FeatureFlagTypes.FETCH_FAIL,
      error,
    });
  }
}

export default function* featureFlagSagas(): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(FeatureFlagTypes.FETCH, handleFeatureFlagLoad)]);
}
