import { AuthHospitalTypes, IAuthHospitalActions, IAuthHospitalLoginRequestPayload, IAuthHospitalRegisterFederalTaxIdRequestPayload } from './types';

export const loginRequest = (
  payload: IAuthHospitalLoginRequestPayload,
): IAuthHospitalActions => 
  {
    return {
      type: AuthHospitalTypes.LOGIN_REQUEST_HOSPITAL,
      payload,
    }
  }

export const loginRequestUnauthorize = (): IAuthHospitalActions => ({
  type: AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL,
});

export const loginRequestError = (errorMessage: string): IAuthHospitalActions => ({
  type: AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL,
  errorMessage,
});

export const checkHospitalAuthentication = (): IAuthHospitalActions => ({
  type: AuthHospitalTypes.VERIFY_HOSPITAL,
});

export const logoutAction = (): IAuthHospitalActions => ({
  type: AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL,
});

export const updateTermsOfUse = (
  payload: IAuthHospitalLoginRequestPayload,
): IAuthHospitalActions => ({
  type: AuthHospitalTypes.UPDATE_TERMS_OF_USE_HOSPITAL,
  payload,
});

export const cleanRequestErrors = (): IAuthHospitalActions => ({
  type: AuthHospitalTypes.CLEAN_ERROR_MESSAGE_HOSPITAL,
});

export const setFederalTaxIdRegistered = (federalTaxIdRegistered: boolean): IAuthHospitalActions => ({
  type: AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED,
  federalTaxIdRegistered,
});

export const setFederalTaxIdAlreadyRegistered = (federalTaxIdAlreadyRegistered: boolean): IAuthHospitalActions => ({
  type: AuthHospitalTypes.SET_FEDERALTAX_ID_ALREADY_REGISTERED,
  federalTaxIdAlreadyRegistered,
});

export const setFederalTaxId = (federalTaxId: string): IAuthHospitalActions => ({
  type: AuthHospitalTypes.SET_FEDERALTAX_ID,
  federalTaxId,
});

export const setShowModalFeedBackFederalTaxId = (showModalFeedBackFederalTaxId: boolean): IAuthHospitalActions => ({
  type: AuthHospitalTypes.SET_SHOW_MODAL_FEEDBACK_FEDERALTAX_ID,
  showModalFeedBackFederalTaxId,
});

export const setFederalTaxIdRegisteredError = (federalTaxIdRegisteredError: boolean, messageFederalTaxIdRegisteredError: string): IAuthHospitalActions => ({
  type: AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_ERROR,
  federalTaxIdRegisteredError,
  messageFederalTaxIdRegisteredError,
});

export const setFederalTaxIdRegisteredFail = (federalTaxIdRegisteredFail: boolean): IAuthHospitalActions => ({
  type: AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_FAIL,
  federalTaxIdRegisteredFail,
});

export const registerFederalTaxIdRequest = (
  payload: IAuthHospitalRegisterFederalTaxIdRequestPayload,
): IAuthHospitalActions => 
  {
    return {
      type: AuthHospitalTypes.REGISTER_FEDERAL_TAX_ID_REQUEST,
      payload,
    }
  }
