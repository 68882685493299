import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import AppRoutes from '~routes';
import ApolloWrapper from './ApolloWrapper';
import Modal from '~components/Modal';
// REDUX
import store from '~store';
import './css/fonts.css';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme/dasa';
import { forceNoMargin } from './utils/styleFix';
import { Helper } from './modules/components/Helper';

interface IApp {
  children?: JSX.Element,
  bffUrl?: string
}

const AuthGate = ({children}: IApp): JSX.Element => {
  forceNoMargin();

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Modal />
        <Helper />

        <ApolloWrapper>
          <AppRoutes>
            {children}
          </AppRoutes>
        </ApolloWrapper>
      </ThemeProvider>
    </Provider>
  );
};

export default AuthGate;
