const setStorage = (name: string, value: string): void => {
  localStorage.setItem(name, value);
};

const setSessionStorage = (name: string, value: string): void => {
  sessionStorage.setItem(name, value);
};

const getStorage = (name: string): string | null => {
  const data = localStorage.getItem(name);
  if (data) {
    return data;
  }
  return sessionStorage.getItem(name);
};

const clearStorage = (): void => {
  /**
   * FIXME: paleativo para manter a flag do certificado no localStorage
   * remover `persistDigitalFlag` após encontrar uma melhor solução
   *  */
  const persistDigitalFlag = String(getStorage('showDigitalSignature'));

  localStorage.clear();
  sessionStorage.clear();

  setStorage('showDigitalSignature', persistDigitalFlag);
};

const removeFromStorage = (name: string): void => {
  localStorage.removeItem(name);
  sessionStorage.removeItem(name);
};

const clearMemed = (): void => {
  // console.error("not implemented");
  if ((window as any)?.MdHub?.command) {
    (window as any).MdHub.command.send('plataforma.sdk', 'logout');
  }
  removeScriptById('memed-script');
};

const removeScriptById = (id: string) => {
  const memedScriptComponent = document.getElementById(id);
  if (memedScriptComponent && memedScriptComponent.parentNode) {
    memedScriptComponent.parentNode.removeChild(memedScriptComponent);
  }
};

export {
  setStorage,
  setSessionStorage,
  getStorage,
  clearStorage,
  removeFromStorage,
  clearMemed,
};
