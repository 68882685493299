import styled from 'styled-components';


export const Container = styled.div`
    width:100%;
    height:100%;
    margin:0px;
    padding-top :0px;
    
    div:first-child{
        div:nth-child(3){
            top:0;
        }
    }
`
