import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from './styles';

const LoaderAnimation = () => (
  <Container>
    <CircularProgress />
  </Container>
);

export default React.memo(LoaderAnimation);
