import { AxiosPromise } from 'axios';
import api, { pathBffOnboarding } from '../axios/api';
import { IPersonalData, IProfessionalData, IContact, IUserContact } from './types';

const basePath = `${pathBffOnboarding}`;

interface ISelect {
  label: string;
  value: string;
}

export interface IGetCouncilResponse extends ISelect {
  groups: ISelect[];
  specialties: ISelect[];
}

export interface IGetUpdateUserPayload {
  digital: string | null;
  personalData: IPersonalData;
  professionalData: IProfessionalData;
  contacts: IContact[];
  trust: boolean;
  createdAt: string;
}

export interface IGetDoctorPatientPathUrlResponse {
  redirectPath: string;
}

export interface IGetContactsResponse {
  digital: string | null;
  personalData: IPersonalData;
  professionalData: IProfessionalData;
  contacts: IContact[];
}

export interface IResetPasswordSendTokenPayload {
  username: string;
  contactType: string;
  contactId: number;
}

export interface ICheckTokenPayload {
  username: string;
  token: string;
}

export interface IResetPasswordPayload {
  password: string;
  username: string;
  token: string;
}

export interface IPreRegisterPayload {
  name: string;
  federalTaxId: string;
  contacts?: IContact[];
}

const { REACT_APP_BFF_API_KEY } = process.env;
const headers = {
  apikey: REACT_APP_BFF_API_KEY,
};

const BFFUserServices = {
  getUser: (): AxiosPromise<IGetUpdateUserPayload> => {
    return api.get(`${basePath}/user`, { headers });
  },
  updateUser: (payload: Partial<IGetUpdateUserPayload>): AxiosPromise<any> => {
    return api.patch(`${basePath}/user`, payload, { headers });
  },
  getCouncil: (): AxiosPromise<IGetCouncilResponse> => {
    return api.get(`${basePath}/council`, { headers });
  },
  getDoctorPatientPathUrl: (id: string | null): AxiosPromise<IGetDoctorPatientPathUrlResponse> => {
    return api.put(`${basePath}/doctorPatient/eventLoggedIn`, { doctorPatientId: id }, { headers });
  },
  getUserContacts: (federalTaxId: string, recaptchaToken: string): AxiosPromise<IUserContact[]> => {
    const headersParams = { ...headers, Authorization: `Bearer ${recaptchaToken}` };
    return api.get(`${basePath}/user/contacts/${federalTaxId}`, { headers: headersParams });
  },
  resetPasswordSendToken: (
    payload: IResetPasswordSendTokenPayload,
    recaptchaToken: string,
  ): AxiosPromise<any> => {
    const headersParams = { ...headers, Authorization: `Bearer ${recaptchaToken}` };
    return api.post(`${basePath}/user/reset-password/send-token`, payload, {
      headers: headersParams,
    });
  },
  checkToken: (
    { username, token }: ICheckTokenPayload,
    recaptchaToken: string,
  ): AxiosPromise<any> => {
    const headersParams = { ...headers, Authorization: `Bearer ${recaptchaToken}` };
    return api.get(`${basePath}/user/check-token/${username}/${token}`, { headers: headersParams });
  },
  resetPassword: (payload: IResetPasswordPayload, recaptchaToken: string): AxiosPromise<any> => {
    const headersParams = { ...headers, Authorization: `Bearer ${recaptchaToken}` };
    return api.put(`${basePath}/user/reset-password`, payload, { headers: headersParams });
  },
  preRegister: (payload: IPreRegisterPayload): AxiosPromise<any> => {
    return api.post(`${basePath}/pre-register`, payload, { headers });
  },
};

export default BFFUserServices;
