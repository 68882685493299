import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import colors from '../palette-color';

const Palette: PaletteOptions = {
  primary: {
    main: colors.primary[50],
    '10': colors.primary[10],
    '20': colors.primary[20],
    '30': colors.primary[30],
    '40': colors.primary[40],
    '50': colors.primary[50],
    '60': colors.primary[60],
    '70': colors.primary[70],
    '80': colors.primary[80],
    '90': colors.primary[90],
    '97': colors.primary[97],
  },
  secondary: {
    main: colors.secondary[50],
    '10': colors.secondary[10],
    '20': colors.secondary[20],
    '30': colors.secondary[30],
    '40': colors.secondary[40],
    '50': colors.secondary[50],
    '60': colors.secondary[60],
    '70': colors.secondary[70],
    '80': colors.secondary[80],
    '90': colors.secondary[90],
    '97': colors.secondary[97],
  },
  variant: {
    main: colors.variant[50],
    '10': colors.variant[10],
    '20': colors.variant[20],
    '30': colors.variant[30],
    '40': colors.variant[40],
    '50': colors.variant[50],
    '60': colors.variant[60],
    '70': colors.variant[70],
    '80': colors.variant[80],
    '90': colors.variant[90],
    '97': colors.variant[97],
  },
  success: {
    main: colors.success[50],
    '10': colors.success[10],
    '20': colors.success[20],
    '30': colors.success[30],
    '40': colors.success[40],
    '50': colors.success[50],
    '60': colors.success[60],
    '70': colors.success[70],
    '80': colors.success[80],
    '90': colors.success[90],
    '97': colors.success[97],
  },
  alert: {
    main: colors.alert[50],
    '10': colors.alert[10],
    '20': colors.alert[20],
    '30': colors.alert[30],
    '40': colors.alert[40],
    '50': colors.alert[50],
    '60': colors.alert[60],
    '70': colors.alert[70],
    '80': colors.alert[80],
    '90': colors.alert[90],
    '97': colors.alert[97],
  },
  danger: {
    main: colors.danger[50],
    '10': colors.danger[10],
    '20': colors.danger[20],
    '30': colors.danger[30],
    '40': colors.danger[40],
    '50': colors.danger[50],
    '60': colors.danger[60],
    '70': colors.danger[70],
    '80': colors.danger[80],
    '90': colors.danger[90],
    '97': colors.danger[97],
  },
  grayscale: {
    main: colors.grayscale[50],
    '10': colors.grayscale[10],
    '20': colors.grayscale[20],
    '30': colors.grayscale[30],
    '40': colors.grayscale[40],
    '50': colors.grayscale[50],
    '60': colors.grayscale[60],
    '70': colors.grayscale[70],
    '80': colors.grayscale[80],
    '90': colors.grayscale[90],
    '97': colors.grayscale[97],
  },
  black: {
    main: colors.black[100],
    '8': colors.black[8],
    '16': colors.black[16],
    '24': colors.black[24],
    '48': colors.black[48],
    '64': colors.black[64],
    '80': colors.black[80],
    '100': colors.black[100],
  },
  white: {
    main: colors.white[100],
    '8': colors.white[8],
    '16': colors.white[16],
    '24': colors.white[24],
    '48': colors.white[48],
    '64': colors.white[64],
    '80': colors.white[80],
    '100': colors.white[100],
  },
};

export default Palette;
