export enum UserTypes {
  GET_USER = '@user/GET_USER',
  GET_USER_SUCCESS = '@user/GET_USER_SUCCESS',
  GET_USER_FAILURE = '@user/GET_USER_FAILURE',
  SET_USER_APPOINTMENT_TYPE = '@user/SET_USER_APPOINTMENT_TYPE',
  SET_ROLES_HIGIA = '@user/SET_ROLES_HIGIA',
}

export enum UserProfiles {
  DOCTOR = 'DOCTOR',
  OPERATOR = 'OPERATOR',
}

export enum UserAttendanceType {
  DIGITAL_PS = 'DIGITAL_PS',
  MY_PRACTITIONER = 'MY_PRACTITIONER',
}

interface ICouncil {
  document: string;
  documentState: string[];
  documentType: string;
  documentRegionalCouncil: string;
}

interface IGetUserAction {
  type: typeof UserTypes.GET_USER;
}

interface IGetUserSuccessAction {
  type: typeof UserTypes.GET_USER_SUCCESS;
  payload: IUserState;
}

interface IGetUserFailureAction {
  type: typeof UserTypes.GET_USER_FAILURE;
}

interface IDigital {
  trust: boolean;
  show: boolean;
}

export interface ISetAppointmentType {
  type: typeof UserTypes.SET_USER_APPOINTMENT_TYPE;
  appointmentType: UserAttendanceType;
}

export interface ISetRolesHigiaType {
  type: typeof UserTypes.SET_ROLES_HIGIA;
  payload: string[];
}

export type IUserActions =
  | IGetUserAction
  | IGetUserSuccessAction
  | IGetUserFailureAction
  | ISetAppointmentType
  | ISetRolesHigiaType;

export interface IUserState {
  readonly id: string;
  readonly appointmentType: string;
  readonly domain: string;
  readonly email: string;
  readonly exp: string;
  readonly federalTaxId: string;
  readonly gender: string;
  readonly iat: string;
  readonly roles: string[];
  readonly rolesHigia?: string[];
  readonly userCrmState: string;
  readonly userCrmValue: string;
  readonly userFederalTaxId: string;
  readonly userId: string;
  readonly userIdentifierValue: string;
  readonly userName: string;
  readonly userType: UserProfiles;
  readonly updatedPassword: boolean;
  readonly initialNames: string;
  readonly version: string;
  readonly council: ICouncil;
  readonly digital: IDigital;
}
