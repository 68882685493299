import React, {useState , useEffect, ReactChild, ReactChildren, Children} from "react";
import logoNav from '~images/nav-logo-white.svg'
import closeWhite from '~images/close-white.svg'
import { useHistory } from "react-router-dom";

import ContainerAdverts from "../ContainerAdverts";
import {
    Container,
    Title,
    Describe,
    ButtonRedirect,
} from './style'

interface IOptions{
    image:string;
    title: string;
    button?:boolean;
}

interface StorieProps {
  elements:IOptions;
  children: ReactChild | ReactChildren;
}


const ContentsAdverts = ({elements, children}: StorieProps): JSX.Element => {

  const {image, title, button} = elements
  const history = useHistory();

  const handleRedirectLogin =()=>{
      history.push('/login')
  }

  return (
    <ContainerAdverts redirect={handleRedirectLogin}>
        <Container>
            
            <img src={image} alt="" />
            
            <Title>{title}</Title>
            <Describe>
                {children}
            </Describe>
            {
                button && 
                <ButtonRedirect onClick={handleRedirectLogin}>
                    Acessar página
                </ButtonRedirect>
            }
        </Container>
    </ContainerAdverts>
  )
};

  
export default ContentsAdverts