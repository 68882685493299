import { EnumProductStatus } from '~/utils/enums';

export enum ApplicationTypes {
  APPLICATION_REQUEST = '@application/APPLICATION_REQUEST',
  APPLICATION_SUCCESSFUL = '@application/APPLICATION_SUCCESSFUL',
  APPLICATION_FAILED = '@application/APPLICATION_FAILED',
  APPLICATION_ACCESS_REQUEST = '@application/APPLICATION_ACCESS_REQUEST',
  APPLICATION_ACCESS_SUCCESSFUL = '@application/APPLICATION_ACCESS_SUCCESSFUL',
  APPLICATION_ACCESS_FAILED = '@application/APPLICATION_ACCESS_FAILED',
}

export interface IAppShowCase {
  id: string;
  name: string;
  url: string;
  public: boolean;
  hasAccessToApplication: boolean;
  status?: EnumProductStatus;
  reason?: string;
  roles?: string[];
  accessRequested?: boolean;
  accessRequestedError?: Error;
  createdAtApplication?: string | null;
}

export interface IAppShowCaseRequestPayload {
  applications: [IAppShowCase];
}

export interface IAppAccessRequestPayload {
  applicationsIds: string[];
}

export interface IAppShowRequestAction {
  type: typeof ApplicationTypes.APPLICATION_REQUEST;
}

export interface IAppShowSuccessfulAction {
  type: typeof ApplicationTypes.APPLICATION_SUCCESSFUL;
  payload: IAppShowCaseRequestPayload;
}

export interface IAppShowFailedAction {
  type: typeof ApplicationTypes.APPLICATION_FAILED;
  payload: IAppShowCaseRequestPayload;
}

export interface IAppAccessRequestAction {
  type: typeof ApplicationTypes.APPLICATION_ACCESS_REQUEST;
  payload: IAppAccessRequestPayload;
}

export interface IAppAccessRequestSuccessfulAction {
  type: typeof ApplicationTypes.APPLICATION_ACCESS_SUCCESSFUL;
  payload: IAppAccessRequestPayload;
}

export interface IAppAccessRequestFailedAction {
  type: typeof ApplicationTypes.APPLICATION_ACCESS_FAILED;
  payload: IAppAccessRequestPayload;
}

export interface IApplicationState {
  readonly request: boolean;
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorMessage: string;
  readonly applications?: IAppShowCase[] | undefined;
}

export type IApplicationActions =
  | IAppShowRequestAction
  | IAppShowSuccessfulAction
  | IAppShowFailedAction
  | IAppAccessRequestAction
  | IAppAccessRequestSuccessfulAction
  | IAppAccessRequestFailedAction;
