import { StyleRules } from '@material-ui/core/styles';
import { AppBarClassKey } from '@material-ui/core';
import colors from '../palette-color';

const AppBar: Partial<StyleRules<AppBarClassKey>> = {
  root: {},
  colorPrimary: {
    backgroundColor: colors.white[100],
    boxShadow: 'none',
    borderBottom: `1px solid ${colors.black[8]}`,
    color: colors.grayscale[10],
  },
};

export default AppBar;
