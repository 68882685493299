import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import { ApplicationState } from '~store/rootReducer';
import registerTags from '~/utils/googleTags/registerTags';
import FabButton from '../DesignSystem/Fab'

export const Helper = () => {
  const handleOpenModal = () => {
    registerTags.pushHelpClickTag();
    registerTags.pushOpenModalTag({modal:"Ajuda"});
  }
  const { featureFlag } = useSelector((state: ApplicationState) => state);

  if (!featureFlag.fetchLoaded || (featureFlag.flags['redirect-new-home'] && window.location.href.includes('/auth#home'))) return <></>

  return (
    <div onClick={handleOpenModal}>
      <Link href={process.env.REACT_APP_OPEN_TICKET_URL} target="_blank" rel="noopener noreferrer">
        <FabButton />
      </Link>
    </div>
  )
}
