import React from 'react';
import {
  Select,
  SelectProps,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import useStyles from './styles';

interface IOptions {
  text: string;
  value: string;
  id: number | string;
}

export interface IProps extends SelectProps {
  options: IOptions[];
  description: string;
  'data-testid'?: string;
  errorMessage?: string;
}

const SelectField = (props: IProps): JSX.Element => {
  const { options, description, required, errorMessage, label, labelId, variant, ...selectProps } =
    props;
  const classes = useStyles();

  return (
    <FormControl
      variant={variant}
      className={classes.root}
      error={!!errorMessage}
      required={required}
    >
      <InputLabel id={labelId} className={classes.label}>
        {label}
      </InputLabel>
      <Select className={classes.select} label={label} labelId={labelId} {...selectProps}>
        <MenuItem value="" disabled>
          {description}
        </MenuItem>
        {options.map(item => (
          <MenuItem value={item.value} key={item.id}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && (
        <FormHelperText className={classes.textHelper} error>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectField;
