import {
  FeatureFlagTypes,
  IFeatureFlagActions,
  IFeatureFlagState,
} from './types';

const initialState: IFeatureFlagState = {
  flags: {},
  error: {},
  fetchLoaded: false,
};

export default function featureFlagReducer(
  state = initialState,
  action: IFeatureFlagActions,
): IFeatureFlagState {
  switch (action.type) {
    case FeatureFlagTypes.FETCH:
      return {
        ...state,
        fetchLoaded: false,
      };
    case FeatureFlagTypes.FETCH_SUCCESS:
      return {
        ...state,
        flags: action.payload,
        fetchLoaded: true,
      };
    case FeatureFlagTypes.FETCH_FAIL:
      return {
        ...state,
        error: action.payload,
        fetchLoaded: true,
      };
    default:
      return state;
  }
}
