import { AxiosPromise } from 'axios';
import api, { pathTelemedicineDasa } from '../axios/api';

export interface ILoginRequest {
  username: string;
  password: string;
  application: string;
  userType: string;
}

export interface ILoginResponse {
  token: string;
  roles: string[];
  status: string;
  type: string;
}

export interface IRequestToChangePassword {
  username: string;
  brand: string;
  templateName: string;
  userType?: string;
}

export interface IChangePasswordRequest {
  username: string;
  newPassword: string;
  token: string;
  userType: string;
}

const basePath = `${pathTelemedicineDasa}/auth`;

const AuthServicesV3 = {
  login: (data: ILoginRequest): AxiosPromise<ILoginResponse> =>
    api.post(`${basePath}/login`, data),
  requestToChangePassword: (data: IRequestToChangePassword): AxiosPromise =>
    api.post(`${basePath}/request-to-change-password`, data, { headers: { env: process.env.REACT_APP_ENV || 'DEV' } }),
  changePassword: (data: IChangePasswordRequest): AxiosPromise =>
    api.post(`${basePath}/change-password`, data),
};

export default AuthServicesV3;
