import { EnumProductStatus } from '~/utils/enums';
import { IApplicationActions, IApplicationState, ApplicationTypes } from './types';

const initialState: IApplicationState = {
  request: false,
  error: false,
  loading: false,
  errorMessage: ""
};

export default function applicationReducer(
  state = initialState,
  action: IApplicationActions,
): IApplicationState {
  switch (action?.type) {
    case ApplicationTypes.APPLICATION_REQUEST:
      return {
        ...state,
        request:false,
        loading: true,
        error: false,
        errorMessage: '',
      };

    case ApplicationTypes.APPLICATION_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
        request: true,
        loading: false,
        error: false,
        errorMessage: ''
      };

    case ApplicationTypes.APPLICATION_FAILED:
      return {
        ...state,
        request: true,
        loading: false,
        error: true,
        errorMessage: 'Error'
      };

    case ApplicationTypes.APPLICATION_ACCESS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ApplicationTypes.APPLICATION_ACCESS_SUCCESSFUL:
      state.applications?.map((element) => {
        if (action.payload.applicationsIds.includes(element.id)) {
          element.accessRequested = true;
          element.status = EnumProductStatus.REVIEW;
        }
      })
      return {
        ...state,
        loading: false,
      }

    case ApplicationTypes.APPLICATION_ACCESS_FAILED:
      state.applications?.map((element) => {
        if(action.payload.applicationsIds.includes(element.id))
          element.accessRequested = false;
          element.accessRequestedError = new Error('Error');
      })
      return {
        ...state,
        loading: false,
      }
    default:
      return state;
  }
}