const addEventListener = (
  el: Document,
  event: string,
  fn: (arg0: any) => void,
) => {
  el.addEventListener(event, fn);
  return () => {
    el.removeEventListener(event, fn);
  };
};

const eventStream: { [key: string]: { [key: string]: () => void } } = {};

const eventBus = {
  on(event: string, callback: (arg0: any) => void, id: string): void {
    const remove = addEventListener(document, event, (e: any) =>
      callback(e.detail),
    );
    eventStream[event] = { [id]: remove };
  },
  dispatch(event: string, data?: any): void {
    document.dispatchEvent(new CustomEvent(event, { detail: data || {} }));
  },
  remove(event: string, id: string): void {
    eventStream[event][id]();
  },
};

export default eventBus;
