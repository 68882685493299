import {
  FeatureFlagTypes,
  IFeatureFlagActions,
  IFlagsPayload,
  IErrorPayload,
} from './types';

export const fetchFeatureFlag = (): IFeatureFlagActions => {
  return {
    type: FeatureFlagTypes.FETCH,
  };
};

export const fetchFeatureFlagSuccess = (
  data: IFlagsPayload,
): IFeatureFlagActions => {
  return {
    type: FeatureFlagTypes.FETCH_SUCCESS,
    payload: data,
  };
};

export const fetchFeatureFlagError = (
  data: IErrorPayload,
): IFeatureFlagActions => {
  return {
    type: FeatureFlagTypes.FETCH_FAIL,
    payload: data,
  };
};
