import { combineReducers } from 'redux';

import auth from './auth';
import featureFlag from './feature-flag';
import user from './user';
import userHospital from './userHospital';
import authHospital from './authHospital';
import authHospitalCpf from './authHospitalCpf';
import application from './application';
import register from './register';
import myAccount from './myAccount';
import notification from './notification';

import { IAuthState } from './auth/types';
import { IFeatureFlagState } from './feature-flag/types';
import { IUserState } from './user/types';
import { IUserHospitalState } from './userHospital/types';
import { IAuthHospitalState } from './authHospital/types';
import { IAuthHospitalCpfState } from './authHospitalCpf/types';
import { IApplicationState } from './application/types';
import { IRegisterState } from './register/types';
import { IMyAccountState } from './myAccount/types';
import { INotificationState } from './notification/types';

export interface ApplicationState {
  auth: IAuthState;
  featureFlag: IFeatureFlagState;
  user: IUserState;
  userHospital: IUserHospitalState;
  authHospital: IAuthHospitalState;
  authHospitalCpf: IAuthHospitalCpfState;
  application: IApplicationState;
  register: IRegisterState;
  myAccount: IMyAccountState;
  notification: INotificationState;
}

export default combineReducers({
  auth,
  featureFlag,
  user,
  userHospital,
  authHospital,
  authHospitalCpf,
  application,
  register,
  myAccount,
  notification,
});
