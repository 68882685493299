import { gql } from "@apollo/client";

const LOGIN_REQUEST = gql`
  mutation LoginRequest($username: String!, $password: String!) {
    authLoginJwt(
      loginJwtInput: {
        username: $username,
        password: $password
      }
    ) {
      __typename
      ... on LoginJwtOutput {
        access_token,
        refresh_token,
        expires_in,
        refresh_expires_in,
        access_token_pes,
        refresh_token_pes
        expires_in_pes,
        refresh_expires_in_pes
      }
      ... on InternalServerError {
        code
        message
      }
      ... on NotFoundError {
        message
        code
      }
      ... on UnauthenticatedError {
        message
        code
      }
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    authLoginJwt(
      loginJwtInput: {
        refreshToken: $refreshToken
      }
    ) {
      __typename
      ... on LoginJwtOutput {
        access_token,
        refresh_token,
      }
      ... on InternalServerError {
        code
        message
      }
      ... on NotFoundError {
        message
        code
      }
      ... on UnauthenticatedError {
        message
        code
      }
    }
  }
`;

export { LOGIN_REQUEST, REFRESH_TOKEN };