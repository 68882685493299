import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 32,
      padding: 0,
      width: 32,
      '&:hover': {
        backgroundColor: 'rgba(25, 76, 230, 0.04)',
      },
    },
    icon: {
      alignItems: 'center',
      backgroundColor: theme.palette.grayscale[80],
      border: '1px solid #dce1e7',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      height: 16,
      width: 16,
      '&:before': {
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: '50%',
        content: '""',
        display: 'block',
        height: 8,
        width: 8,
      },
      'input:hover ~ &': {
        backgroundColor: theme.palette.grayscale[70],
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: theme.palette.primary[50],
      border: 0,
      'input:hover ~ &': {
        backgroundColor: theme.palette.primary[40],
      },
    },
  }),
);
