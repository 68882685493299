import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Montserrat, Helvetica, sans-serif, icomoon',
      borderRadius: '8px',
    },
    container: {
      height: 'auto',
      padding: '42px 42px 0px 42px',
      maxWidth: '427px',
    },
    logo: {
      height: 24,
      display: 'block',
      margin: '10px auto 40px auto',
    },
    close: {
      position: 'absolute',
      right: 20,
      top: 20,
      color: theme.palette.grayscale[50],
      cursor: 'pointer',
    },
    title: {
    },
    text: {
      fontSize: 16,
      textAlign: 'left',
      color: theme.palette.grayscale[60],
      textSize: '16px',
      fontFamily: ['Dasa Sans', 'Montserrat', 'Helvetica', 'sans-serif'].join(','),
    },
    buttonWrap: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-end'
    },
    checkbox: {
      display: 'block',
      textAlign: 'center',
      marginTop: 15,
      '& .MuiFormControlLabel-label': {
        color: theme.palette.grayscale[60],
        fontSize: 14,
        fontWeight: 400,
      },
    },
    button: {
      height: 48,
      paddingLeft: 40,
      paddingRight: 40,
      marginTop: 22,
      marginBottom: 42
    },
    textInfo: {
      fontSize: 16,
      fontFamily: 'Montserrat',
      margin: '8px 0px'
    },
    titleInfo: {
      fontSize: 20,
      fontFamily: 'Montserrat'
    }
  }),
);
