import { pushTag } from "./dataLayerTag"

enum events {
    MODAL_OPEN = 'modalOpen',
    MODAL_OPEN_TAG = 'modal_open',
    VIRTUAL_PAGE_VIEW = 'virtualPageView',
    REGISTER = 'register',
    FORM_STEPS = 'formSteps',
    PASSWORD_RESET = 'password_reset_steps',
    EXAM_STEPS = 'exam_steps',
    LOGIN_STEPS = 'login_steps',
    STEP_BACK = 'step_back',
    FORM_ERROR = 'form_error',
    HELP_CLICK = 'help_click',
}

interface IUserTypeTag {
    type: string,
}

interface IOpenModalTag {
    modal: string,
}

interface IVirtualPageViewTag {
    step: string,
    route?: string
}

interface IFormStepsTag {
    step: string;
    isRegistered?: boolean;
    singleLoginActive?: boolean;
    user?: IUserTypeTag;
}

interface IRegisterTag {
    speciality: string,
    council: string,
    state: string,
    sex?: string,
}

interface IForgotPasswordStepTag {
    step: string
}

interface ILoginStepTag {
    step: string,
    user_id?: string
}

interface IExamStepTag {
    step: string
}

interface IPage {
    page: string
}

interface IFormErrorTag {
    errorMessage: string
}

interface IModalOpenTag {
    modalMessage: string
}

const pushOpenModalTag = (tag: IOpenModalTag) => {
    pushTag({
        event: events.MODAL_OPEN,
        ...tag,
    })
}

const pushVirtualPageViewTag = (tag: IVirtualPageViewTag) => {
    pushTag({
        event: events.VIRTUAL_PAGE_VIEW,
        ...tag,
    })
}

const pushFormStepsTag = (tag: IFormStepsTag) => {
    pushTag({
        event: events.FORM_STEPS,
        ...tag,
    })
}

const pushRegisterTag = (tag: IRegisterTag) => {
    pushTag({
        event: events.REGISTER,
        ...tag,
    })
}

const pushForgotPasswordTag = (tag: IForgotPasswordStepTag) => {
    pushTag({
        event: events.PASSWORD_RESET,
        ...tag,
    })
}

const pushLoginStepTag = (tag: ILoginStepTag) => {
    pushTag({
        event: events.LOGIN_STEPS,
        ...tag,
    })
}

const pushExamsStepTag = (tag: IExamStepTag) => {
    pushTag({
        event: events.EXAM_STEPS,
        ...tag,
    })
}

const pushPageVirtualPageViewTag = (tag: IPage) => {
    pushTag({
        event: events.VIRTUAL_PAGE_VIEW,
        ...tag,
    })
}

const pushFormErrorTag = (tag: IFormErrorTag) => {
    pushTag({
        event: events.FORM_ERROR,
        ...tag,
    })
}

const pushModalOpenTag = (tag: IModalOpenTag) => {
    pushTag({
        event: events.MODAL_OPEN_TAG,
        ...tag,
    })
}

const pushHelpClickTag = () => {
    pushTag({ event: events.HELP_CLICK })
}

const pushStepBackTag = () => {
    pushTag({ event: events.STEP_BACK })
}

export default {
    pushRegisterTag,
    pushFormStepsTag,
    pushVirtualPageViewTag,
    pushOpenModalTag,
    pushForgotPasswordTag,
    pushLoginStepTag,
    pushExamsStepTag,
    pushPageVirtualPageViewTag,
    pushFormErrorTag,
    pushModalOpenTag,
    pushHelpClickTag,
    pushStepBackTag,
}
