import styled from 'styled-components';


export const Container = styled.div`
    width:100%;
    height:100%;
    display: flex;
    justify-content:center;
    margin:0px;
    padding:50px 0px 0px 0px;  
    background:#0c42f9;
    color:#ffffff;
    overflow:hidden;

`

export const Contents = styled.div`
    width: 55%; 
    height:100%;
    padding-top:0px;
    display: flex;
    flex-direction:column;
    align-items:center;

    @media only screen and (max-width: 1025px){
        width: 80%;
    }
`
export const Header = styled.header`
    width: 100%;
    height:25px;
    padding: 10px 0px;
    display: flex;
    justify-content:space-between;
    align-items:center;

    & img{
        height:18px;
    }

    .close{
        outline:none;
        cursor:pointer;
        background: none;
        border:none;
        z-index:1000;
    }

`
export const Main = styled.main`
    width: 100%;
    height:100%;
    display: flex;
    flex-direction:column;
    margin-top:40px;
    /* justify-content:center; */
    align-items:center;
    
`