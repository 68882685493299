import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
    createStyles({
        navBar:{
          display: 'flex',
          width: 'calc(100vw - 20px)',
          margin: 'auto',
          maxWidth: '480px',
          paddingBottom: 45,
        },
        logo: {
            height: 24,
        },
        logoHolder:{
            margin: 'auto',
            width: 145,
        },
        buttonBack: {
          color: theme.palette.text.secondary,
          fontWeight: 100,
        },
        buttonBackNone:{
          display: 'none',
        },
        logoHolderbuttonBackNone:{
            margin: 'auto',
        },
        '@media (max-width: 959px)': {
          logoHolder:{
            position: 'fixed',
            top: 0,
            width: '100%',
            left: 0,
            height: 65,
            borderBottom: '1px solid #E4E5E7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
          },
          navBar:{
            marginTop: 0,
            top: 83,
            display: 'flex',
            position: 'fixed',

          },
          logoHolderbuttonBackNone:{
            position: 'fixed',
            top: 0,
            width: '100%',
            left: 0,
            height: 65,
            borderBottom: '1px solid #E4E5E7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
          },
        },
    }),
);
