interface IFunnelStepAttrs {
  currentStep: string;
  previousStep?: string;
  registerType?: string;
  newAttr?: any;
}

const dtoPath = (step: string) => {
  switch(step) {
    case 'welcome-operator':
      return '/cadastro/boas-vindas-operador';
    case 'form-verification-operator':
      return '/cadastro/cpf-operador';
    case 'welcome':
      return '/cadastro/boas-vindas';
    case 'form-verification':
      return '/cadastro/cpf';
    case 'form-personal':
      return '/cadastro/dados-pessoais';
    case 'form-professional':
      return '/cadastro/dados-profissionais';
    case 'form-contacts':
      return '/cadastro/dados-contato';
    case 'form-password':
      return '/cadastro/criar-senha';
    case 'form-token':
      return '/cadastro/token';
    case 'token-valid':
      return '/cadastro/cadastro-concluido';
    case 'token-invalid':
      return '/cadastro/token-invalido';
    default:
      return 'nao-mapeado';
  }
}

const dtoStepName = (step: string) => {
  switch(step) {
    case 'welcome-operator':
      return 'boas_vindas_operador';
    case 'form-verification-operator':
      return 'cpf_operador';
    case 'welcome':
      return 'boas_vindas';
    case 'form-verification':
      return 'cpf';
    case 'form-personal':
      return 'dados_pessoais';
    case 'form-professional':
      return 'dados_profissionais';
    case 'form-contacts':
      return 'dados_contato';
    case 'form-password':
      return 'criar_senha';
    case 'form-token':
      return 'token';
    case 'token-valid':
      return 'cadastro_concluido';
    case 'token-invalid':
      return 'token_invalido';
    default:
      'nao_mapeado'
  }
}

const dtoRegisterType = (type: string) => {
  switch(type) {
    case 'REGULAR':
      return 'regular';
    case 'USING_MDM':
    case 'USING_MDM_PRO':
      return 'mdm_profissional';
    case 'USING_MDM_PATIENT':
      return 'mdm_paciente';
    case 'PRE_USER':
      return 'pre_user';
    case 'PRE_USER_HIGIA':
      return 'pre_use_higia';
    default:
      'nao_mapeado'
  }
}

export const getBaseLayerStructure = (event: string, path: string, newAttr?: any) => ({
  event,
  page: {
    path,
    title: 'NAV Login',
  },
  context: {
    current_service: 'onboarding',
  },
  metadata: {
    data_layer_version: '',
    author: 'Digital Analytics Team',
  },
  _clear: true,
  ...newAttr,
});

export const getFunnelStepLayerStructure = (attr: IFunnelStepAttrs) => {
  const { currentStep, previousStep, registerType, newAttr } = attr;
  const path = dtoPath(currentStep);
  const currentStepName = dtoStepName(currentStep);
  const previousStepName = previousStep ? dtoStepName(previousStep) : undefined;
  const registerTypeName = registerType ? dtoRegisterType(registerType) : undefined;
  const funnel = {
    name: 'onboarding_cadastro',
    type: registerTypeName,
    current_step: currentStepName,
    previous_step: previousStepName,
  }
  const layer = getBaseLayerStructure('funnel_step', path, { funnel, ...newAttr });
  return layer;
}

export const push = (layer: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(layer)
};