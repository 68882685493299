import { useState } from 'react';

import {TextFieldProps} from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import TextField from '../TextField';

export default (props: TextFieldProps) => {
  const [showPassword, handleClickShowPassword] = useState(false);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword(!showPassword)}
            >
              {
              showPassword
              ? <VisibilityOutlined style={{fontSize: 16}} />
              : <VisibilityOffOutlined style={{fontSize: 16}} />
              }
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}
