import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height:100%;
    display: flex;
    flex-direction:column;
    margin-top:1%;
    align-items:center;
    font-family: Sans-serif;
    & img{
        width: 300px;
        height:240px;
    }
`
export const Title = styled.h1`
    text-align:center;
    margin-top: 5%;
    margin-bottom: 0px;
    line-height: 30px;
    font-weight: bold;
    width: 40%;
    font-size: 150%;
    word-spacing: -0.1em;
 

`
export const Describe = styled.div`
    width:38%;
    margin-top:0.5em;
    & p {
        text-align: center;
        font-size: 100%;
        line-height: 24px;          
    }
`
export const ButtonRedirect = styled.button`
    z-index:1001;
    width: 50%;
    max-width:312px;
    padding:1em 1.5em ;
    box-sizing: border-box;
    appearance:none;
    text-align:center;
    line-height:inherit;
    text-decoration:none;
    border:0px;
    border-radius:8px;
    font-family: Montserrat, BlinkMacSystemFont, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size:1em;
    color:#0c42f9;
    font-weight: 600;
    height:3em;
    cursor:pointer;
    margin-bottom:40px;
`