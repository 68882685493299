import { INotificationRequestAction, NotificationTypes } from './types';
import { call, put, all, takeLatest, AllEffect, ForkEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import NotificationService, { INotification } from '~/services/notification';

export function* getNotifications({ params }: INotificationRequestAction): Generator {
  try {
    const { status, data = [] } = (yield call(NotificationService.get, params)) as AxiosResponse<
      INotification[]
    >;
    if (status === 200) {
      return yield put({
        type: NotificationTypes.NOTIFICATION_SUCCESSFUL,
        payload: data,
      });
    }
    return yield put({
      type: NotificationTypes.NOTIFICATION_FAILED,
    });
  } catch (err) {
    return yield put({ type: NotificationTypes.NOTIFICATION_FAILED });
  }
}

export default function* NotificationSagas(): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(NotificationTypes.NOTIFICATION_REQUEST, getNotifications)]);
}
