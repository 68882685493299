import { StyleRules } from '@material-ui/core/styles';
import { FormLabelClassKey } from '@material-ui/core';
import colors from '../palette-color';

const FormLabel: Partial<StyleRules<FormLabelClassKey>> = {
  root: {
    fontWeight: 400,
    '&.Mui-error': {
      color: colors.danger[40],
    },
  },
};

export default FormLabel;
