export enum ExamsDeniedTypes {
  GET_EXAMS_DENIED = '@examsDenied/GET_EXAMS_DENIED',
  GET_EXAMS_DENIED_SUCCESS = '@examsDenied/GET_EXAMS_DENIED_SUCCESS',
  GET_EXAMS_DENIED_FAILURE = '@examsDenied/GET_EXAMS_DENIED_FAILURE',
  RESET_EXAMS_DENIED = '@examsDenied/RESET_EXAMS_DENIED',
}

export type IExamsDeniedState = IExamsDeniedResponse & {
  readonly loading: boolean;
};

export interface IExamsDeniedResponse {
  isPresent: boolean;
}

export interface IExamsDeniedParams {
  listName: string;
  value: string;
}

export interface IExamsDeniedRequest {
  type: typeof ExamsDeniedTypes.GET_EXAMS_DENIED;
  params: IExamsDeniedParams;
}

export interface IExamsDeniedSuccess {
  type: typeof ExamsDeniedTypes.GET_EXAMS_DENIED_SUCCESS;
  payload: IExamsDeniedResponse;
}

export interface IExamsDeniedFailure {
  type: typeof ExamsDeniedTypes.GET_EXAMS_DENIED_FAILURE;
}

interface IExamsDeniedReset {
  type: typeof ExamsDeniedTypes.RESET_EXAMS_DENIED;
}

export type IExamsDeniedActions =
  | IExamsDeniedRequest
  | IExamsDeniedSuccess
  | IExamsDeniedFailure
  | IExamsDeniedReset;
