export enum NotificationTypes {
  NOTIFICATION_REQUEST = '@notification/NOTIFICATION_REQUEST',
  NOTIFICATION_SUCCESSFUL = '@notification/NOTIFICATION_SUCCESSFUL',
  NOTIFICATION_FAILED = '@notification/NOTIFICATION_FAILED',
}

export interface INotification {
  _id: string;
  vertical: string;
  application: string;
  active: boolean;
  title: string;
  name: string;
  message: string;
  create_date: string;
}

export interface INotificationRequestParams {
  vertical: string;
  application: string;
}

export interface INotificationRequestAction {
  type: typeof NotificationTypes.NOTIFICATION_REQUEST;
  params: INotificationRequestParams;
}

export interface INotificationSuccessfulAction {
  type: typeof NotificationTypes.NOTIFICATION_SUCCESSFUL;
  payload: [INotification];
}

export interface INotificationFailedAction {
  type: typeof NotificationTypes.NOTIFICATION_FAILED;
}

export interface INotificationState {
  readonly request: boolean;
  readonly loading: boolean;
  readonly error: boolean;
  readonly notifications?: INotification[] | undefined;
}

export type INotificationActions =
  | INotificationRequestAction
  | INotificationSuccessfulAction
  | INotificationFailedAction;
