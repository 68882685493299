import { AxiosPromise } from 'axios';
import api, { pathUserManagementDasa } from '../axios/api';

export interface IResetPassword {
  id?: string;
  password: string;
}

const basePath = `${pathUserManagementDasa}/account`;

const ResetPasswordService = {
  reset: (data: IResetPassword): AxiosPromise =>
    api.post(`${basePath}/reset-forgotten-password`, data),
};

export default ResetPasswordService;
