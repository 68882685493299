import React from 'react';
import { CircularProgress } from '@material-ui/core';
import useStyles from './styles';

export interface IProps {
  color?: string | undefined;
  size?: number | string;
}

const SpinnerProgress = ({ color, size }: IProps): JSX.Element => {
  const classes = useStyles();
  return <CircularProgress className={`${classes.loading} ${color}`} size={size} />;
};

export default SpinnerProgress;
