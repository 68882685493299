import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.white[100],
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'stretch',
      minHeight: '100vh',
      position: 'absolute',
      width: '100%',
      overflow: 'hidden',
    },
    img: {
      width: '42%',
      background: '#F7F7F8',
      overflow: 'hidden',
      height: '100vh',
      '&, content': {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
    navGestaoSaude: {
      flex: '0 0 auto',
      height: '100%',
      minHeight: 420,
      maxHeight: 420,
      [theme.breakpoints.down('md')]: {
        minHeight: 380,
        maxHeight: 380,
      },
    },
    title: {
      fontSize: 64,
      color: theme.palette.grayscale[20],
      textAlign: 'center',
      fontWeight: 100,
      maxWidth: 420,
      lineHeight: '64px',
      marginTop: 70,
      marginLeft: 'auto',
      marginRight: 'auto',
      '& span': {
        fontWeight: 600,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 48,
        lineHeight: '48px',
        maxWidth: 304,
      },
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
      overflow: 'auto',
      height: '100vh',
    },
    [theme.breakpoints.down('sm')]: {
      root: {
        flexDirection: 'column',
      },

      img: {
        display: 'none',
        ', content': {
          minHeight: '100vh',
          width: '100%',
        },
      },
    },
  }),
);
