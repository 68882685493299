import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      color: '#797c85',
      padding: 10,
      '&.white': {
        color: theme.palette.white[100],
      },
    },
  }),
);
