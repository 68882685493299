import { all, fork, StrictEffect } from 'redux-saga/effects';

import authSagas from './auth/sagas';
import authHospitalSagas from './authHospital/sagas';
import authHospitalCpfSagas from './authHospitalCpf/sagas';
import featureSagas from './feature-flag/sagas';
import userSagas from './user/sagas';
import examsDeniedSagas from './featureFlagLists/examsDenied/sagas';
import applicationSagas from './application/sagas';
import registerSagas from './register/sagas';
import myAccountSagas from './myAccount/sagas';
import notificationSagas from './notification/sagas';

export default function* rootSaga(): Generator<StrictEffect> {
  return yield all([
    fork(authSagas),
    fork(authHospitalSagas),
    fork(authHospitalCpfSagas),
    fork(featureSagas),
    fork(userSagas),
    fork(examsDeniedSagas),
    fork(applicationSagas),
    fork(registerSagas),
    fork(myAccountSagas),
    fork(notificationSagas),
  ]);
}
