import {
  select,
  call,
  put,
  all,
  takeLatest,
  AllEffect,
  ForkEffect,
} from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import APIMAuthServices, { ILoginTempResponse, ILoginErrorResponse } from '~services/apim';
import BFFOnboardingServices, { IGetDoctorPatientPathUrlResponse } from '~services/bff';
import TermsAndPoliciesService from '~services/terms-and-policies';
import { UserTypes, IUserState } from '~store/user/types';
import {
  AuthTypes,
  IAuthLoginRequestAction,
  IAuthUpdateTermsOfUseAction,
  IAuthVerifyAction,
} from './types';
import { IRegisterState } from '~store/register/types';
import { ApplicationState } from '~store/rootReducer';
import { decryptToken, helpers } from '~utils';
import {
  setSessionStorage,
  getStorage,
  clearStorage,
  clearMemed,
} from '~utils/storage';
import format from '~utils/format';
import { push } from '~utils/gtm';

const gtmPushDataLayerAutomaticLogin = (registerStore: IRegisterState, id: string) => {
  const { userType, sex, specialities, documentNumber, documentOrg, documentState } =
    registerStore.registerUser;
  const specialtiesName = specialities
    ? specialities.map((obj: any) => obj.specialityDescription)
    : [];
  const dataLayer = {
    event: 'login',
    login: {
      status: 'success',
      method: 'automatico',
    },
    user: {
      id,
      type: userType,
      biological_sex: sex,
      specialty: specialtiesName,
      council: documentOrg,
      council_state: documentState,
      council_number: documentNumber,
    },
    _clear: true,
  };
  push(dataLayer);
};

const handleError = (error: string | AxiosError<ILoginErrorResponse>) => {
  if (typeof error === 'string') return error;
  if (error.response?.data) {
    const { service_code } = error.response.data;
    if (service_code === 'DP3') {
      return 'CPF ou senha inválido';
    }
    // if (errorsData && errorsData.includes('Approval pending for user')) {
    //   return 'Usuário em fase de aprovação';
    // }
    // if (errorsData && errorsData.includes('user not verified')) {
    //   return 'Token não validado';
    // }
    // if (errorsData && errorsData.includes('User approvation was denied')) {
    //   return `No momento sua solicitação de cadastro foi reprovada.\nPara mais informações, entre em contato com seu gestor`;
    // }
  }
  return 'Não foi possível realizar o login. Verifique seus dados.';
};

const getRegisterStore = (state: ApplicationState): IRegisterState => state.register;

function* getRedirect(idRedirect: string) {
  try {
    const { data } = (yield call(
      BFFOnboardingServices.getDoctorPatientPathUrl,
      idRedirect,
    )) as AxiosResponse<IGetDoctorPatientPathUrlResponse>;
    const { redirectPath } = data;
    if (redirectPath) {
      yield call(setSessionStorage, 'doctorPatientId', idRedirect);
      const path = `${window.location.origin}${redirectPath}`;
      window.location.href = path;
    }
  } catch (error: any) {
    yield put({ type: AuthTypes.SET_REDIRECT_ERROR, payload: true });
  }
}

export function* login(action: IAuthLoginRequestAction): Generator {
  try {
    const { federalTaxId, password, idRedirect, recaptchaToken, automaticLogin } = action.payload;
    const username = format.toOnlyNumbers(federalTaxId);

    const headers = {
      'Content-Type': 'application/json',
      'captcha-token': recaptchaToken,
    };

    const payloadLoginTemp = { idp: 'sso', username, password };
    const response = (yield call(
      APIMAuthServices.loginTemp,
      payloadLoginTemp,
      headers,
    )) as AxiosResponse<ILoginTempResponse>;

    const {
      orchestrator_access_token,
      orchestrator_refresh_token,
      data: dataLegacy,
    } = response.data;
    const {
      access_token: access_token_sso,
      refresh_token: refresh_token_sso,
      access_token_pes,
    } = dataLegacy;

    if (!access_token_pes && !access_token_sso && !orchestrator_refresh_token) {
      throw new Error('Ocorreu um erro inesperado');
    }

    yield call(setSessionStorage, 'token', btoa(access_token_pes));
    yield call(setSessionStorage, 'access_token', access_token_sso);
    yield call(setSessionStorage, 'refresh_token', refresh_token_sso);
    yield call(setSessionStorage, 'orchestrator_access_token', orchestrator_access_token);
    yield call(setSessionStorage, 'orchestrator_refresh_token', orchestrator_refresh_token);

    const user = decryptToken.decode(access_token_pes) as unknown as IUserState;
    const appointmentType = helpers.translateRolesToAppointmentType(user.roles, user.userType);
    const payloadUserSuccess = { ...user, appointmentType };

    if (automaticLogin) {
      const registerStore = (yield select(getRegisterStore)) as IRegisterState;
      gtmPushDataLayerAutomaticLogin(registerStore, user.userId);
    }

    yield put({ type: UserTypes.GET_USER_SUCCESS, payload: payloadUserSuccess });

    yield put({ type: AuthTypes.AUTHORIZE });
    (window as any)?.dataLayer?.push({
      event: 'login_success',
      userId: user.userId,
      perfil: user.roles,
    });

    if (idRedirect) yield call(getRedirect, idRedirect);
  } catch (error: any) {
    const errorMessage = handleError(error);
    yield put({ type: AuthTypes.LOGIN_FAILURE, errorMessage });
    yield put({ type: AuthTypes.UNATHORIZE });
  }
}

export function* checkAuth(action: IAuthVerifyAction): Generator {
  try {
    const { payload: idRedirect } = action;
    if (idRedirect) yield call(getRedirect, idRedirect);

    const token = getStorage('token');
    if (!token) throw new Error('Unathorize');

    const tokenDecode: unknown = decryptToken.decode(atob(token));
    const user = tokenDecode as IUserState;
    if (!user) throw new Error('Invalid token');

    const storageAppointmentType = getStorage('appointmentType');
    const appointmentType =
      storageAppointmentType || helpers.translateRolesToAppointmentType(user.roles, user.userType);
    const payload = { ...user, appointmentType };

    yield put({ type: UserTypes.GET_USER_SUCCESS, payload });

    yield put({ type: AuthTypes.AUTHORIZE });
  } catch (error: any) {
    yield put({ type: AuthTypes.UNATHORIZE });
  }
}

export function* logout(): Generator {
  clearStorage();
  clearMemed();
  yield put({ type: AuthTypes.LOGOUT });
}

export function* updateTermOfUse(action: IAuthUpdateTermsOfUseAction): Generator {
  try {
    const { federalTaxId, password } = action.payload;
    const unformattedFederalTaxId = format.toOnlyNumbers(federalTaxId);
    yield call(TermsAndPoliciesService.update, {
      federalTaxId: unformattedFederalTaxId,
      password,
    });

    yield put({ type: AuthTypes.LOGIN_REQUEST, payload: action.payload });
  } catch (error: any) {
    // TEDE: Please show a snackbar error when implemented
    yield put({ type: AuthTypes.LOGIN_REQUEST, payload: action.payload });
  }
}

export default function* authSagas(): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(AuthTypes.VERIFY, checkAuth),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logout),
    takeLatest(AuthTypes.UPDATE_TERMS_OF_USE, updateTermOfUse),
  ]);
}
