export enum MyAccountTypes {
  RESET = '@myAccount/RESET',
  RESET_REQUEST_STATUS = '@myAccount/RESET_REQUEST_STATUS',
  GET_USER_REQUEST = '@myAccount/GET_USER_REQUEST',
  GET_USER_SUCCESS = '@myAccount/GET_USER_SUCCESS',
  GET_USER_FAILURE = '@myAccount/GET_USER_FAILURE',
  UPDATE_PERSONAL_DATA = '@myAccount/UPDATE_PERSONAL_DATA',
  UPDATE_PROFESSIONAL_DATA = '@myAccount/UPDATE_PROFESSIONAL_DATA',
  UPDATE_CONTACTS_DATA = '@myAccount/UPDATE_CONTACTS_DATA',
  UPDATE_SUCCESS = '@myAccount/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@myAccount/UPDATE_FAILURE',
  SET_DIGITAL = '@myAccount/SET_DIGITAL',
  SET_PERSONAL_DATA = '@myAccount/SET_PERSONAL_DATA',
  SET_PROFESSIONAL_DATA = '@myAccount/SET_PROFESSIONAL_DATA',
  SET_CONTACTS_DATA = '@myAccount/SET_CONTACTS_DATA',
  SET_COUNCIL_AND_SPECIALTIES = '@myAccount/SET_COUNCIL_AND_SPECIALTIES',
  SET_OPEN_DIGITAL_SIGNATURE = '@myAccount/SET_OPEN_DIGITAL_SIGNATURE',
  SET_TRUST = '@myAccount/SET_TRUST',
  SET_CREATED_AT = '@myAccount/SET_CREATED_AT',
}

export interface ISelect {
  label: string;
  value: string | number;
}

export interface ICouncilAndSpecialty extends ISelect {
  groups: ISelect[];
  specialties: ISelect[];
}

export interface IContact {
  id?: number;
  contactType: string;
  value: string;
  validated?: boolean | null;
}

export interface IPersonalData {
  fullName: string;
  federalTaxId: string;
  birthDate: string;
  sex: string;
  gender?: string;
}

export interface IProfessionalData {
  councilRegionalAbbreviation: string;
  councilRegistryNumber: string;
  uf: string;
  specialities: (string | number)[];
}

export interface IContactsData {
  email: IContact;
  phone: IContact;
  contacts: IContact[];
}

export interface IUpdatePayload {
  personalData: IPersonalData;
  professionalData: IProfessionalData;
  contacts: IContactsData;
}

type TRequestStatus = 'loading' | 'success' | 'failure' | 'idle';

export interface IRequestStatusBySection {
  digital: TRequestStatus;
  personalData: TRequestStatus;
  professionalData: TRequestStatus;
  contactsData: TRequestStatus;
}

export const emptyPersonalData = {
  fullName: '',
  federalTaxId: '',
  birthDate: '',
  sex: '',
  gender: '',
};

export const emptyProfessionalData = {
  councilRegionalAbbreviation: '',
  councilRegistryNumber: '',
  uf: '',
  specialities: [],
};

export const emptyContactsData = {
  email: {
    contactType: 'EMAIL',
    value: '',
    validated: false,
  },
  phone: {
    contactType: 'CELULAR',
    value: '',
    validated: false,
  },
  contacts: [],
};

export const emptyRequestStatusBySection: IRequestStatusBySection = {
  digital: 'idle',
  personalData: 'idle',
  professionalData: 'idle',
  contactsData: 'idle',
};

export interface IMyAccountState {
  readonly id?: number;
  readonly openDigitalSignature: boolean;
  readonly digital: string | null;
  readonly personalData: IPersonalData;
  readonly professionalData: IProfessionalData;
  readonly contactsData: IContactsData;
  readonly requestStatusBySection: IRequestStatusBySection;
  readonly councilAndSpecialties: ICouncilAndSpecialty[];
  readonly requestLoading: boolean;
  readonly requestSuccess: boolean;
  readonly requestFailure: boolean;
  readonly toastMessage: string;
  readonly trust: boolean;
  readonly createdAt: string;
}

export interface IResetAction {
  type: typeof MyAccountTypes.RESET;
}

export interface IResetRequestStatusAction {
  type: typeof MyAccountTypes.RESET_REQUEST_STATUS;
  payload: keyof IRequestStatusBySection;
}

export interface IGetUserResquestAction {
  type: typeof MyAccountTypes.GET_USER_REQUEST;
}
export interface IGetUserSuccessAction {
  type: typeof MyAccountTypes.GET_USER_SUCCESS;
}
export interface IGetUserFailureAction {
  type: typeof MyAccountTypes.GET_USER_FAILURE;
}

export interface IUpdatePersonalDataAction {
  type: typeof MyAccountTypes.UPDATE_PERSONAL_DATA;
  payload: IPersonalData;
}
export interface IUpdateProfessionalDataAction {
  type: typeof MyAccountTypes.UPDATE_PROFESSIONAL_DATA;
  payload: IProfessionalData;
}
export interface IUpdateContactsDataAction {
  type: typeof MyAccountTypes.UPDATE_CONTACTS_DATA;
  payload: IContactsData;
}
export interface IUpdateSuccessAction {
  type: typeof MyAccountTypes.UPDATE_SUCCESS;
  payload: keyof IRequestStatusBySection;
  messageSuccess?: string;
}
export interface IUpdateFailureAction {
  type: typeof MyAccountTypes.UPDATE_FAILURE;
  payload: keyof IRequestStatusBySection;
}

export interface ISetDigitalAction {
  type: typeof MyAccountTypes.SET_DIGITAL;
  payload: string | null;
}
export interface ISetPersonalDataAction {
  type: typeof MyAccountTypes.SET_PERSONAL_DATA;
  payload: IPersonalData;
}
export interface ISetProfessionalDataAction {
  type: typeof MyAccountTypes.SET_PROFESSIONAL_DATA;
  payload: IProfessionalData;
}
export interface ISetContactsDataAction {
  type: typeof MyAccountTypes.SET_CONTACTS_DATA;
  payload: IContactsData;
}
export interface ISetCouncilAndSpecialtiesAction {
  type: typeof MyAccountTypes.SET_COUNCIL_AND_SPECIALTIES;
  payload: ICouncilAndSpecialty[];
}

export interface ISetOpenDigitalSignatureAction {
  type: typeof MyAccountTypes.SET_OPEN_DIGITAL_SIGNATURE;
  payload: boolean;
}
export interface ISetTrustAction {
  type: typeof MyAccountTypes.SET_TRUST;
  payload: boolean;
}
export interface ISetCreatedAtAction {
  type: typeof MyAccountTypes.SET_CREATED_AT;
  payload: string;
}

export type IMyAccountActions =
  | IResetAction
  | IResetRequestStatusAction
  | IGetUserResquestAction
  | IGetUserSuccessAction
  | IGetUserFailureAction
  | IUpdatePersonalDataAction
  | IUpdateProfessionalDataAction
  | IUpdateContactsDataAction
  | IUpdateSuccessAction
  | IUpdateFailureAction
  | ISetDigitalAction
  | ISetPersonalDataAction
  | ISetProfessionalDataAction
  | ISetContactsDataAction
  | ISetCouncilAndSpecialtiesAction
  | ISetOpenDigitalSignatureAction
  | ISetTrustAction
  | ISetCreatedAtAction;
