import { StyleRules } from '@material-ui/core/styles';
import { TableCellClassKey } from '@material-ui/core';
import colors from '../palette-color';

const TableCell: Partial<StyleRules<TableCellClassKey>> = {
  root: {
    color: colors.grayscale[30],
    fontFamily: ['Montserrat', 'Helvetica', 'sans-serif'].join(','),
  },
  head: {
    color: colors.grayscale[10],
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.2px',
    lineHeight: '1rem',
    textTransform: 'uppercase',
    padding: '0 0 0 8px',
  },
  body: {
    color: colors.grayscale[40],
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '1.5rem',
    padding: '0 0 0 8px',
  },
};

export default TableCell;
