export enum FeatureFlagTypes {
  FETCH = '@featureFlag/FETCH',
  FETCH_SUCCESS = '@featureFlag/FETCH SUCCESS',
  FETCH_FAIL = '@featureFlag/FETCH FAIL',
}

export interface IFlagsPayload {
  'exams-unified'?: boolean;
  'adverts-hospital'?: boolean;
  'login-graphql'?: boolean;
  'login-apim'?: boolean;
  'recaptcha-enterprise'?: boolean;
  'modal-announcement'?: boolean;
  'dynatrace-script'?: boolean;
  'attendance-history-calendar'?: boolean;
  'average-queue-time'?: boolean;
  'biometric-data'?: boolean;
  'care-orientation'?: boolean;
  'clinical-study-data'?: boolean;
  'error-alerts'?: boolean;
  'event-medications-prescription'?: boolean;
  'evolution-event-timeline'?: boolean;
  exams?: boolean;
  'feedback-link'?: boolean;
  'impar-stay-field'?: boolean;
  login?: boolean;
  'mdm-service'?: boolean;
  memed?: boolean;
  'monitoring-panel'?: boolean;
  'patient-evolution-data'?: boolean;
  'patient-search'?: boolean;
  'push-notification'?: boolean;
  'realtime-updater'?: boolean;
  recaptcha?: boolean;
  'refresh-patient-data'?: boolean;
  'right-tray-controls'?: boolean;
  'risk-factors'?: boolean;
  'risk-score'?: boolean;
  'scheduling-period'?: boolean;
  signup?: boolean;
  'signup-complete'?: boolean;
  telemedicine?: boolean;
  'telemedicine-chat'?: boolean;
  'telemedicine-clinical-study'?: boolean;
  'telemedicine-dev-session'?: boolean;
  'telemedicine-evolution'?: boolean;
  'telemedicine-report'?: boolean;
  'telemedicine-reports-history-filters'?: boolean;
  'telemedicine-reports-history-v2'?: boolean;
  'unstructured-content-attention-points'?: boolean;
  uploads?: boolean;
  'nurse-journey-route'?: boolean;
  'google-tags-register'?: boolean;
  'mdm-validate'?: boolean;
  'redirect-new-home'?: boolean;
}

export interface IErrorPayload {
  message?: string;
}

interface IFetchFeatureFlagAction {
  type: typeof FeatureFlagTypes.FETCH;
}

interface IFetchFeatureFlagSuccessAction {
  type: typeof FeatureFlagTypes.FETCH_SUCCESS;
  payload: IFlagsPayload;
}

interface IFetchFeatureFlagErrorAction {
  type: typeof FeatureFlagTypes.FETCH_FAIL;
  payload: IErrorPayload;
}

export interface IFeatureFlagState {
  flags: IFlagsPayload;
  error: IErrorPayload;
  fetchLoaded: boolean;
}

export type IFeatureFlagActions =
  | IFetchFeatureFlagAction
  | IFetchFeatureFlagSuccessAction
  | IFetchFeatureFlagErrorAction;
