import { StyleRules } from '@material-ui/core/styles';
import { TableRowClassKey } from '@material-ui/core';
import colors from '../palette-color';

const TableRow: Partial<StyleRules<TableRowClassKey>> = {
  root: {
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    height: 48,
    '&:not(.MuiTableRow-head):hover': {
      backgroundColor: colors.grayscale[97],
      boxShadow: `4px 0 0 inset ${colors.primary[70]}`,
    },
  },
  head: {
    height: 56,
  },
};

export default TableRow;
