import helpers from '~utils/helpers';

const maskTo = (text: string, pattern: string) => {
  const strippedValue = text.replace(/[^0-9]/g, '');
  const chars = strippedValue.split('');
  let count = 0;

  let formatted = '';
  for (let i = 0; i < pattern.length; i += 1) {
    const c = pattern[i];
    if (chars[count]) {
      if (/\*/.test(c)) {
        formatted += chars[count];
        count += 1;
      } else {
        formatted += c;
      }
    }
  }

  return formatted;
};

const toAge = (date: string): string => {
  const age = helpers.getAge(date);
  return age >= 0 ? `${age} anos` : '-';
};

const toGender = (gender?: string): string => {
  if (!gender) return 'Outros';
  switch (gender) {
    case 'male':
      return 'Masculino';
    case 'female':
      return 'Feminino';
    default:
      return 'Outros';
  }
};

const toPhone = (phone: string): string => {
  if (phone.length > 9) {
    const regex =
      phone.length < 11 ? /^(\d{2})(\d{4})(\d{4})$/ : /^(\d{2})(\d{5})(\d{4})$/;
    const match = phone.match(regex);
    if (match) return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return '-';
};

const toCPF = (text: string): string => maskTo(text, '***.***.***-**');

const toOnlyNumbers = (text: string): string => text.replace(/\D/g, '');

const toDate = (text: string): string => maskTo(text, '**/**/****');

const toPhoneMask = (text: string): string => maskTo(text, '(**) *****-****');

const toGlobalPhoneMask = (phone: string, isMobile?: boolean): string => {
  let value = phone.replace(/\D/g, '');

  if (isMobile) {
    if (value.length > 11) {
      return helpers.revertString(maskTo(helpers.revertString(value), helpers.revertString('*** ** *****-****')));
    }
    if (value.length <= 11) {
      return maskTo(value, '** *****-****');
    }
  }

  if (value.length > 10) {
    return helpers.revertString(maskTo(helpers.revertString(value), helpers.revertString('*** ** ****-****')));
  }
  if (value.length <= 10) {
    return maskTo(value, '** ****-****');
  }
  return value;
}

const toOnlyLettersAndSpace = (text: string): string =>
  text.replace(/[^a-záàâãéèêíïóôõöúç\s]/i, '');

const toSnakeCase = (text: string): string => {
  return text.toLowerCase().replaceAll(' ', '_');
}

const format = {
  toAge,
  toGender,
  toPhone,
  toPhoneMask,
  toGlobalPhoneMask,
  toCPF,
  toDate,
  toOnlyNumbers,
  toOnlyLettersAndSpace,
  toSnakeCase,
};

export default format;
