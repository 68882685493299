import { UserHospitalTypes, IUserHospitalState, IUserHospitalActions, UserHospitalProfiles } from './types';
import { helpers } from '~utils';

const initialState: IUserHospitalState = {
  id: "",
  nome: "",
  email: "",
  telefone: "",
  sexo: "",
  cpf: "",
  dataNascimento: "",
  termoAceito: false,
  perfil: [],
  hospitais: [],
  userType: UserHospitalProfiles.DOCTOR,
  initialNames: "",

};

export default function userHospitalReducer(
  state = initialState,
  action: IUserHospitalActions,
): IUserHospitalState {
  switch (action.type) {
    case UserHospitalTypes.GET_USER_HOSPITAL:
      return {
        ...state,
      };

    case UserHospitalTypes.GET_USER_HOSPITAL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        initialNames: helpers.getInitialNames(action.payload.nome),
      };

    case UserHospitalTypes.GET_USER_HOSPITAL_FAILURE:
      return {
        ...state,
        ...initialState,
      };
    case UserHospitalTypes.SET_USER_HOSPITAL_FEDERAL_TAX_ID:
      return {
        ...state,
        cpf: action.federalTaxId,
      };

    default:
      return state;
  }
}
