import { call, all, takeLatest, StrictEffect } from 'redux-saga/effects';

import { UserTypes, ISetAppointmentType } from './types';
import { setSessionStorage } from '~utils/storage';

export function* setAppointmentType(
  action: ISetAppointmentType,
): Generator<StrictEffect> {
  const { appointmentType } = action;
  yield call(setSessionStorage, 'appointmentType', appointmentType);
}

export default function* userSagas(): Generator<StrictEffect> {
  yield all([
    takeLatest(UserTypes.SET_USER_APPOINTMENT_TYPE, setAppointmentType),
  ]);
}
