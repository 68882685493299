import React, { ReactChild, ReactChildren} from "react";
import logoNav from '~images/nav-logo-white.svg'
import closeWhite from '~images/close-white.svg'

import { 
  Container,
  Contents,
  Header,
  Main,
} from './style'

interface StorieProps {
  redirect?: ()=>void;
  children: ReactChild | ReactChildren;
}

const ContainerAdverts = ({redirect, children }: StorieProps): JSX.Element => {

  return (
    <Container>
      <Contents>
        <Header>
            <img src={logoNav} alt="Logo nav"/>
            <button className="close" onClick={redirect}>
                <img src={closeWhite} alt="Fechar"/>
            </button>
        </Header>
        <Main>
          {children}
        </Main>

      </Contents>
    </Container>
  )
};

  
export default ContainerAdverts