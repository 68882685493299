import { AxiosPromise } from 'axios';
import { AppConfigurationClient } from '@azure/app-configuration';
import environment from '~settings/environment';
import api, { pathTelemedicineDasa, pathPesTelemedicine } from './axios/api';
import { IFlagsPayload } from '~store/feature-flag/types';
import { IFeatureFlagListsParams } from '~/store/featureFlagLists/types';

const FeatureFlagsServices = {
  loadFeatureFlags: (): AxiosPromise =>
    api.get(
      `${pathPesTelemedicine}/feature-flags/${environment.featureFlag.environment}`,
    ),
  getFeatureFlagLists: (params: IFeatureFlagListsParams): AxiosPromise =>
    api.get(
      `${pathTelemedicineDasa}/feature-flags/list/${params.listName}/value/${params.value}`,
    ),
  getFeatureFlags: (): AxiosPromise =>
    api.get(`${pathTelemedicineDasa}/feature-flags`),
};

export default FeatureFlagsServices;
