import { StyleRules } from '@material-ui/core/styles';
import { OutlinedInputClassKey } from '@material-ui/core';
import colors from '../palette-color';

const OutlinedInput: Partial<StyleRules<OutlinedInputClassKey>> = {
  root: {
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.danger[50],
    },
  },

};

export default OutlinedInput;
