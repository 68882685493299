import React, { useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import useStyles from './styles';

interface IProps {
  open: boolean;
  handleClose: () => void;
}


const ModalRedirectToRegister = (props: IProps): JSX.Element => {
  const { open, handleClose } = props;
  const classes = useStyles();

  return (
    <Dialog className={classes.modal} open={open} onClose={handleClose}>
      <div className={classes.container}>
        <ErrorIcon style={{fill: '#E6B319', fontSize: '32px'}} />
        <h2 className={classes.title}>Atualização de cadastro</h2>
        <p className={classes.text}>
          Recentemente encontramos dados inconsistentes no seu cadastro e precisamos que você atualize.
        </p>
        <div className={classes.buttonWrap}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={handleClose}
          >
            Refazer cadastro
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalRedirectToRegister;
