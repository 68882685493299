import axios, { AxiosError } from 'axios';
import store from '~store';
import {
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
import APIMAuthServices, { ILoginTempResponse } from '~services/apim';
import { REFRESH_TOKEN } from '~/graphql/login/mutations';
import history from '~utils/history';
import { clearStorage, getStorage, setSessionStorage } from '~/utils/storage';

const isUnauthorized = (error: AxiosError) => error?.response?.status === 401;
const hasTokens = (accessToken: string | null, refreshToken: string | null) => !!accessToken && !!refreshToken;

const navigateToLoginExpired = () => {
  history.push({
    pathname: `${window.location.pathname}#login`,
    search: `?expired=true`,
  });
  window.location.reload();
};

const returnToLogin = () => {
  clearStorage();
  navigateToLoginExpired();
  return;
}

const refreshTokenGraphQL = async () => {
  const pesToken = getStorage('token');
  const accessToken = getStorage('access_token');
  const refreshToken = getStorage('refresh_token');

  const uri = `${process.env.REACT_APP_APIM_NAV_PRO_API_URL}/servicos/login/v1`;
  const apiKey = process.env.REACT_APP_APIM_NAV_PRO_API_KEY;
  
  const client = new ApolloClient({
    headers: {
      'api-key': apiKey || '',
    },
    uri,
    cache: new InMemoryCache()
  });

  if (!pesToken && !accessToken && !refreshToken) {
    returnToLogin();
    throw new Error('Token inválido');
  }

  try {
    const rs = await client.mutate({
      variables: { refreshToken },
      mutation: REFRESH_TOKEN 
    });
    const { authLoginJwt } = rs.data;
    if (authLoginJwt.code === 401 || !hasTokens(authLoginJwt.access_token, authLoginJwt.refresh_token)) {
      returnToLogin();
      throw new Error('Token inválido');
    }
    setSessionStorage('access_token', authLoginJwt.access_token);
    setSessionStorage('refresh_token', authLoginJwt.refresh_token);
    return Promise.resolve(authLoginJwt.access_token);
  } catch (_error: any) {
    throw new Error(_error);
  }
};

const refreshTokenREST = async () => {
  const refreshTokenSSO = getStorage('refresh_token');

  if (!refreshTokenSSO) {
    returnToLogin();
    throw new Error('Token inválido');
  }

  try {
    const response = await APIMAuthServices.refreshTemp(refreshTokenSSO);
    const { 
      access_token: access_token_sso,
      refresh_token: refresh_token_sso,
    } = response.data.data;

    if (!access_token_sso && !refresh_token_sso) {
      returnToLogin();
    }

    setSessionStorage('access_token', access_token_sso);
    setSessionStorage('refresh_token', refresh_token_sso);
    return Promise.resolve(access_token_sso);
  } catch (_error: any) {
    throw new Error(_error);
  }
};

const unauthorizedErrorInterceptor = async (
  error: AxiosError,
) => {
  const originalConfig = error.config;
  
  const regexLocationLogin = new RegExp('\\bauth#login\\b');
  const isLocationLogin = regexLocationLogin.test(window.location.href);

  const { featureFlag } = store.getState();

  if (!isLocationLogin) {
    if (isUnauthorized(error) && !originalConfig._retry) {
      let token = '';
      originalConfig._retry = true;
      try {
        token = featureFlag.flags['login-graphql'] ? await refreshTokenGraphQL() : await refreshTokenREST();
        if (!token) throw new Error('Token inválido');
        originalConfig.headers["Authorization"] = 'Bearer ' + token;
        return axios(originalConfig);
      } catch (_error: any) {
        return Promise.reject(_error);
      }
    }
  }

  return Promise.reject(error);
};

export default unauthorizedErrorInterceptor;
