import moment from 'moment';
import format from '~/utils/format';

const isRepeated = (text: string): boolean => {
  const firstChar = text.charAt(0);
  const regex = RegExp(`^${firstChar}+$`);

  return regex.test(text);
};

const isValidFederalTaxId = (cpf: string): boolean => {
  const unformattedCpf = format.toOnlyNumbers(cpf);
  if (
    !(
      unformattedCpf &&
      !isRepeated(unformattedCpf) &&
      unformattedCpf.length === 11
    )
  ) {
    return false;
  }

  let add = 0;
  for (let i = 0; i < 9; i += 1) {
    add += parseInt(unformattedCpf.charAt(i), 10) * (10 - i);
  }

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(unformattedCpf.charAt(9), 10)) {
    return false;
  }

  add = 0;
  for (let i = 0; i < 10; i += 1) {
    add += parseInt(unformattedCpf.charAt(i), 10) * (11 - i);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }
  if (rev !== parseInt(unformattedCpf.charAt(10), 10)) {
    return false;
  }
  return true;
};

const isValidBirthDateAppointment = (value: string): boolean => {
  if (!value || value.length < 10) return false;
  const date = moment(value, 'DD/MM/YYYY');
  const today = moment();
  const dateDiff = Math.floor(today.diff(date, 'month', true));
  if (dateDiff < 0 || dateDiff > 120 * 12) return false;
  return date.isValid();
};

const isValidDate = (value: string): boolean => {
  if (!value || value.length < 10) return false;
  const date = moment(value, 'DD/MM/YYYY');
  const today = moment();
  const dateDiff = today.diff(date, 'month');
  if (dateDiff <= 18 * 12 || dateDiff >= 120 * 12) return false;
  return date.isValid();
};

const isValidPhone = (value: string): boolean =>
  /(\(?\d{2}\)?\s)?(\d{4,5}-\d{3,4})/.test(value);

const isValidMobilePhone = (value: string): boolean =>
  /(\(?\d{2}\)?\s)?(\d{5}-\d{4})/.test(value);

const mobilePhoneHasDigitNine = (value: string): boolean =>
  /(\(?\d{2}\)?\s)?9(\d{4}-\d{4})/.test(value);

const isValidRegisterNumber = (value: string): boolean =>
  /^(?=.*\d)(?=.*[1-9]).{1,10}$/.test(value);

const isValidName = (value: string): boolean =>
  /^[-a-záàâãéèêíïóôõöúç]+(\s+[-a-záàâãéèêíïóôõöúç]+)*$/i.test(value);

const hasUpperLetter = (value: string): boolean => /[A-Z]/g.test(value);

const hasLowerLetter = (value: string): boolean => /[a-z]/g.test(value);

const hasNumber = (value: string): boolean => /[0-9]/g.test(value);

const hasLeftZeros = (value: string): boolean => /^0+[0-9]+/g.test(value);

const hasSpecialCharacters = (value: string): boolean =>
  /[!@#$%&*+=?<>(){}/|:;.,_\]\[-]/g.test(value);

const isValidGlobalPhone = (value: string): boolean => {
  let formattedValue = value.replace(/\D/g, '');
  return /(\d{2,})(\d{8})$/.test(formattedValue);
}

const isValidGlobalPhoneMobile = (value: string): boolean =>{
  let formattedValue = value.replace(/\D/g, '');
  return /(\d{2,})9(\d{8})$/.test(formattedValue);
}

const isValidPassword = (value: string): boolean => {
  if (!value) return false;
  let criteriaCheck = 0;
  if (hasUpperLetter(value)) criteriaCheck++;
  if (hasLowerLetter(value)) criteriaCheck++;
  if (hasNumber(value)) criteriaCheck++;
  if (hasSpecialCharacters(value)) criteriaCheck++;
  return criteriaCheck >= 2;
};

const isValidEmail = (value: string): boolean =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);

const validation = {
  isValidFederalTaxId,
  isValidDate,
  isValidPhone,
  isValidMobilePhone,
  mobilePhoneHasDigitNine,
  isValidRegisterNumber,
  isValidName,
  hasUpperLetter,
  hasLowerLetter,
  hasNumber,
  hasSpecialCharacters,
  isValidPassword,
  isValidEmail,
  isValidBirthDateAppointment,
  hasLeftZeros,
  isValidGlobalPhone,
  isValidGlobalPhoneMobile,
};

export default validation;