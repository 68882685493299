const palette = {
  primaryOld: {
    10: '#050f2e',
    20: '#0a1e5c',
    30: '#0f2e8a',
    40: '#143db8',
    50: '#194ce6',
    60: '#4770eb',
    70: '#7192f4',
    80: '#9eb5fa',
    90: '#cfdafc',
    97: '#f0f4ff',
  },
  primary: {
    10: '#000B33',
    20: '#001666',
    30: '#002199',
    40: '#002CCC',
    50: '#0037FF',
    60: '#335FFF',
    70: '#6687FF',
    80: '#99AFFF',
    90: '#CCD7FF',
    97: '#F0F3FF',
  },
  secondaryOld: {
    10: '#051d2e',
    20: '#0a3a5c',
    30: '#0f578a',
    40: '#1474b8',
    50: '#1991e6',
    60: '#47a7eb',
    70: '#71bdf4',
    80: '#a3d3f5',
    90: '#d1e9fa',
    97: '#f0f9ff',
  },
  secondary: {
    10: '#331400',
    20: '#662900',
    30: '#993D00',
    40: '#CC5200',
    50: '#FA6400',
    60: '#FF8433',
    70: '#FFA366',
    80: '#FFC299',
    90: '#FFE0CC',
    97: '#FFF6F0',
  },
  variant: {
    10: '#20052e',
    20: '#410a5c',
    30: '#610f8a',
    40: '#8214b8',
    50: '#a219e6',
    60: '#b547eb',
    70: '#c871f4',
    80: '#db9efa',
    90: '#eeccff',
    97: '#faf0ff',
  },
  success: {
    10: '#0a2913',
    20: '#155227',
    30: '#1f7a3a',
    40: '#29a34e',
    50: '#30c05b',
    60: '#5cd681',
    70: '#85e0a0',
    80: '#aeebc0',
    90: '#d6f5df',
    97: '#f3fcf6',
  },
  alertOld: {
    10: '#2e2405',
    20: '#5c480a',
    30: '#8a6c0f',
    40: '#b88f14',
    50: '#e6b319',
    60: '#ebc247',
    70: '#f0d275',
    80: '#f5e1a3',
    90: '#faf0d1',
    97: '#fefaf1',
  },
  alert: {
    10: '#2E2405',
    20: '#5C480A',
    30: '#8A6C0F',
    40: '#B88F14',
    50: '#E6B319',
    60: '#EBC247',
    70: '#F0D275',
    80: '#F5E1A3',
    90: '#FAF0D1',
    97: '#FDFAF1',
  },
  danger: {
    10: '#2e050e',
    20: '#5c0a1b',
    30: '#8a0f29',
    40: '#b71537',
    50: '#e51a45',
    60: '#ea486a',
    70: '#f0758f',
    80: '#f5a3b4',
    90: '#fad1da',
    97: '#fdf1f4',
  },
  grayscaleOld: {
    10: '#18191b',
    20: '#303236',
    30: '#494b50',
    40: '#61636b',
    50: '#797c86',
    60: '#94969e',
    70: '#afb1b6',
    80: '#c9cbcf',
    90: '#e4e5e7',
    97: '#f7f7f8',
  },
  grayscale: {
    10: '#18191B',
    20: '#303236',
    30: '#494B50',
    40: '#61636B',
    50: '#797C86',
    60: '#94969E',
    70: '#AFB1B6',
    80: '#C9CBCE',
    90: '#E4E5E7',
    97: '#F7F7F8',
  },
  black: {
    8: 'rgba(0, 0, 0, 0.08)',
    16: 'rgba(0, 0, 0, 0.16)',
    24: 'rgba(0, 0, 0, 0.24)',
    48: 'rgba(0, 0, 0, 0.48)',
    64: 'rgba(0, 0, 0, 0.64)',
    80: 'rgba(0, 0, 0, 0.8)',
    100: '#000',
  },
  white: {
    8: 'rgba(255, 255, 255, 0.08)',
    16: 'rgba(255, 255, 255, 0.16)',
    24: 'rgba(255, 255, 255, 0.24)',
    48: 'rgba(255, 255, 255, 0.48)',
    64: 'rgba(255, 255, 255, 0.64)',
    80: 'rgba(255, 255, 255, 0.8)',
    100: '#fff',
  },
  grey: {
    100: '#f5f5f5',
  }
};

export default palette;