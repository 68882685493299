import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.white[100],
      borderRadius: theme.spacing(1),
      boxShadow: theme.shadows[5],
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(3, 4),

      '&:focus': {
        outline: 'none',
      },
    },
  }),
);
