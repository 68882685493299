import {
  IRegisterActions,
  IRegisterState,
  RegisterTypes,
  emptyRegisterUser,
  initialDisabledFields,
} from './types';

const initialState: IRegisterState = {
  registerUser: emptyRegisterUser,
  disabledFields: initialDisabledFields,
  contactOptions: [],
  specialtyOptions: [],
  loading: false,
  failure: false,
  success: false,
  confirmTokenSuccess: false,
  confirmTokenFailure: false,
  needsValidation: false,
  confirmTokenLoading: false,
  digitalCertificate: false,
  councilAndSpecialties: [],
};

export default function authReducer(
  state = initialState,
  action: IRegisterActions,
): IRegisterState {
  switch (action.type) {
    case RegisterTypes.RESET:
      return initialState;

    case RegisterTypes.RESET_STATUS:
      return {
        ...state,
        loading: false,
        failure: false,
        success: false,
        confirmTokenSuccess: false,
        confirmTokenFailure: false,
        confirmTokenLoading: false,
      };

    case RegisterTypes.NEEDS_VALIDATION:
      return {
        ...state,
        loading: false,
        needsValidation: true,
        registerUser: {
          ...state.registerUser,
          userType: action.payload.userType as 'DOCTOR' | 'OPERATOR',
          name: action.payload.personalData.firstName,
          federalTaxId: action.payload.federalTaxId,
          id: action.payload.personalData.id,
        },
        contactOptions: action.payload.contacts,
      };

    case RegisterTypes.SET_NEW_PASSWORD:
      return {
        ...state,
        loading: false,
        registerUser: {
          ...state.registerUser,
          password: action.payload.password,
        },
      };

    case RegisterTypes.VERIFICATION_REQUEST:
    case RegisterTypes.SAVE_REQUEST:
    case RegisterTypes.RESEND_TOKEN_REQUEST:
      return { ...state, loading: true, failure: false, success: false };

    case RegisterTypes.SAVE_LEGACY_REQUEST:
      return {
        ...state,
        loading: true,
        failure: false,
        success: false,
        registerUser: {
          ...state.registerUser,
          sendById: String(action.payload.token.contacts[0]?.id),
        },
      };

    case RegisterTypes.CONFIRM_TOKEN_REQUEST:
      return {
        ...state,
        loading: false,
        failure: false,
        success: false,
        confirmTokenLoading: true,
        confirmTokenSuccess: false,
        confirmTokenFailure: false,
      };

    case RegisterTypes.VERIFICATION_SUCCESS:
    case RegisterTypes.SAVE_SUCCESS:
    case RegisterTypes.SAVE_LEGACY_SUCCESS:
    case RegisterTypes.RESEND_TOKEN_SUCCESS:
      return { ...state, loading: false, failure: false, success: true };

    case RegisterTypes.CONFIRM_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: false,
        success: false,
        confirmTokenLoading: false,
        confirmTokenSuccess: true,
        confirmTokenFailure: false,
      };

    case RegisterTypes.VERIFICATION_FAILURE:
    case RegisterTypes.SAVE_FAILURE:
    case RegisterTypes.SAVE_LEGACY_FAILURE:
      return {
        ...state,
        loading: false,
        failure: true,
        success: false,
        errorMessage: action.errorMessage,
      };

    case RegisterTypes.RESEND_TOKEN_FAILURE:
      return { ...state, loading: false, failure: true, success: false };

    case RegisterTypes.CONFIRM_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        failure: false,
        success: false,
        confirmTokenLoading: false,
        confirmTokenSuccess: false,
        confirmTokenFailure: true,
      };

    case RegisterTypes.SET_REGISTER_USER:
      return { ...state, registerUser: { ...state.registerUser, ...action.registerUser } };

    case RegisterTypes.SET_DISABLED_FIELDS:
      return { ...state, disabledFields: action.disabledFields };

    case RegisterTypes.SET_CONTACT_OPTIONS:
      return { ...state, contactOptions: action.contactOptions };

    case RegisterTypes.SET_SPECIALTY_OPTIONS:
      return { ...state, specialtyOptions: action.specialtyOptions };

    case RegisterTypes.SET_DIGITAL_CERTIFICATE:
      return { ...state, digitalCertificate: action.value };

    case RegisterTypes.SET_COUNCIL_AND_SPECIALTIES:
      return { ...state, councilAndSpecialties: action.payload };

    default:
      return state;
  }
}
