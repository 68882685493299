import { AxiosPromise } from 'axios';
import api from './axios/api';

const BASE_API_URL = process.env.REACT_APP_NAV_HOSPITAIS_API;

export interface ILoginRequest {
  username: string;
  password: string;
}

export interface ILoginHospitalResponse {
  MDM: object;
}

const AuthHospitalServices = {
  login: (data: ILoginRequest): AxiosPromise =>
    api.post(`${BASE_API_URL}/main/v3/api/public/auth/loginv2/`, data),
};

export default AuthHospitalServices;
