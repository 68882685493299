import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { createHashHistory } from 'history';

import LoadingAnimation from '~components/LoadingAnimation';
import { checkAuthentication } from '~store/auth/actions';

import { ApplicationState } from '~/store/rootReducer';
import LoginHospitals from '~pages/loginHospitals';
import Adverts from '~pages/advertsHospital';
import { pushTag } from '~/utils/googleTags/dataLayerTag';

const MyPayments = React.lazy(() => import('~/modules/pages/myPayments'));
const MyAccount = React.lazy(() => import('~pages/myAccount'));
const RegisterNewPassword = React.lazy(() => import('~/modules/pages/registerNewPassword'))
const Login = React.lazy(() => import('~pages/login'));
const LoginEmail = React.lazy(() => import('~pages/loginEmail'));
const Register = React.lazy(() => import('~pages/register'));
const ForgotPasswordNav = React.lazy(() => import('~pages/forgotPasswordNav'));
const ResetPassword = React.lazy(() => import('~pages/resetPassword'));
const SelectProduct = React.lazy(() => import('~pages/selectProduct'));
const Redirection = React.lazy(() => import('~pages/redirection'));

const PasswordChanged = React.lazy(() =>
  import('~pages/resetPassword/passwordChanged'),
);
const EmailSent = React.lazy(() => import('~pages/forgotPassword/emailSent'));
const RegisterConfirmToken = React.lazy(() =>
  import('~pages/registerConfirmToken'),
);
const ActivateToken = React.lazy(() =>
  import('~pages/activateToken'),
);
const ActivateRegistration = React.lazy(() =>
  import('~pages/activateRegistration'),
);

const FederalTaxIdHospital = React.lazy(() => import('~pages/federalTaxIdHospital'));
const NewPasswordStrong = React.lazy(() => import('~/modules/pages/newPasswordStrong'));

export const history = createHashHistory({
  hashType: 'noslash'
});

history.listen((location, action) => {
  pushTag({
    event: 'virtualPageView',
    route: `${location.pathname}${location.search}${location.hash}`,
  });
})

const AppRoutes = ({ children }: any): JSX.Element => {
  const { auth, featureFlag } = useSelector((state: ApplicationState) => state);
  const dispatch = useDispatch();

  const initialSetup = () => {
    dispatch(checkAuthentication());
  };

  const dynatraceScript = () => {
    if (featureFlag.fetchLoaded && featureFlag.flags['dynatrace-script']) {
      const loadScriptByURL = (id:any, url:any , callback:any ) => {
        const isScriptExist = document.getElementById(id);

        if (!isScriptExist) {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.id = id;
          script.onload = function () {
            if (callback) callback();
          };
          document.body.appendChild(script);
        }

        if (isScriptExist && callback) callback();
      }

      const src = '/auth/dynatrace.js?v='+ Date.now()
      // load the script by passing the URL
      loadScriptByURL("dynatrace", src, function () {});
    }
  }

  useEffect(dynatraceScript, [featureFlag]);
  useEffect(initialSetup, [dispatch]);

  if (!auth.verified) {
    return <LoadingAnimation />;
  }

  if (auth.isAuthenticated) {
    return (
      <Router history={history}>
        <Suspense fallback={<LoadingAnimation />}>
          {children ?? (
            <Switch>
              <Route exact path="/home" component={SelectProduct} />
              <Route exact path="/esqueci-senha" component={ForgotPasswordNav} />
              <Route exact path="/minha-conta" component={MyAccount} />
              <Route exact path="/meuspagamentos" component={MyPayments} />
              <Redirect exact from="/" to="/home" />
              <Redirect to="/home" />
            </Switch>
          )}
        </Suspense>
      </Router>
    )
  }

  return (
    <Router history={history}>
      <Suspense fallback={<LoadingAnimation />}>
        <Switch>
          <Route exact path="/login" component={Login} />

          <Route exact path="/esqueci-senha" component={ForgotPasswordNav} />
          <Route exact path="/nova-senha" component={ResetPassword} />
          <Route exact path="/redefinir-senha" component={ResetPassword} />
          <Route exact path="/email-enviado" component={EmailSent} />
          <Route exact path="/senha-alterada" component={PasswordChanged} />
          <Route exact path="/ativar-contato" component={ActivateRegistration} />
          <Route exact path="/ativar-token" component={ActivateToken} />
          <Route exact path="/cadastro" component={Register} />
          <Route exact path="/cpf-hospitais" component={FederalTaxIdHospital} />
          <Route exact path="/nova-senha-hospitais" component={RegisterNewPassword} />
          <Route exact path="/nova-senha-forte" component={NewPasswordStrong} />
          <Route exact path="/redirection" component={Redirection} />

          {
          // TODO: remover após ter o fluxo de integração completo
          }
          <Route exact path="/next-page" component={() => { return (<h1>TODO: Integração</h1>) }} />
          <Route exact path="/login-hospitais" component={featureFlag.flags['adverts-hospital'] ? Adverts : LoginHospitals} />
          <Route exact path="/login-email" component={LoginEmail} />
          <Route
            exact
            path="/confirmar-token"
            component={RegisterConfirmToken}
          />
          <Redirect exact from="/" to="/login" />
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
