import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  wrapper: {
    background: "#FEFAF1",
    border: "1px solid #B88F14",
    boxSizing: "border-box",
    borderRadius: "4px",
    paddingTop: '16px',
    paddingBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    padding: '0 18px',
    width: '20px',
    height: '20px',
    fill: '#B88F14'
  },
  info: {
    whiteSpace: 'nowrap',
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.8px",
    color: "#61636B",
    marginRight: '5px'
  },
  link: {
    whiteSpace: 'nowrap',
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 'bold',
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.8px",
    color: "#000000"
  },
  textWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    maxWidth: 'calc(100% - 73px)',
    flexWrap: 'wrap'
  }
}));
