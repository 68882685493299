import { StyleRules } from '@material-ui/core/styles';
import { ToolbarClassKey } from '@material-ui/core';

const Toolbar: Partial<StyleRules<ToolbarClassKey>> = {
  root: {
    minHeight: 56,
  },
  dense: {
    minHeight: 56,
  },
};

export default Toolbar;
