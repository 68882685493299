import {
  MyAccountTypes,
  IMyAccountState,
  IMyAccountActions,
  emptyPersonalData,
  emptyProfessionalData,
  emptyContactsData,
  emptyRequestStatusBySection,
} from './types';

const initialState: IMyAccountState = {
  openDigitalSignature: false,
  digital: null,
  personalData: emptyPersonalData,
  professionalData: emptyProfessionalData,
  contactsData: emptyContactsData,
  requestStatusBySection: emptyRequestStatusBySection,
  councilAndSpecialties: [],
  requestLoading: false,
  requestSuccess: false,
  requestFailure: false,
  toastMessage: '',
  trust: false,
  createdAt: '',
};

export default function myAccountReducer(
  state = initialState,
  action: IMyAccountActions,
): IMyAccountState {
  switch (action.type) {
    case MyAccountTypes.RESET:
      return initialState;

    case MyAccountTypes.RESET_REQUEST_STATUS:
      return {
        ...state,
        requestStatusBySection: { ...state.requestStatusBySection, [action.payload]: 'idle' },
        toastMessage: '',
      };

    case MyAccountTypes.GET_USER_REQUEST:
      return { ...state, requestLoading: true, requestSuccess: false, requestFailure: false };
    case MyAccountTypes.GET_USER_SUCCESS:
      return { ...state, requestLoading: false, requestSuccess: true, requestFailure: false };
    case MyAccountTypes.GET_USER_FAILURE:
      return { ...state, requestLoading: false, requestSuccess: false, requestFailure: true };

    case MyAccountTypes.UPDATE_PERSONAL_DATA:
      return {
        ...state,
        requestStatusBySection: { ...state.requestStatusBySection, personalData: 'loading' },
      };
    case MyAccountTypes.UPDATE_PROFESSIONAL_DATA:
      return {
        ...state,
        requestStatusBySection: { ...state.requestStatusBySection, professionalData: 'loading' },
      };
    case MyAccountTypes.UPDATE_CONTACTS_DATA:
      return {
        ...state,
        requestStatusBySection: { ...state.requestStatusBySection, contactsData: 'loading' },
      };
    case MyAccountTypes.UPDATE_SUCCESS:
      return {
        ...state,
        requestStatusBySection: { ...state.requestStatusBySection, [action.payload]: 'success' },
        toastMessage: action.messageSuccess || 'Dados atualizados com sucesso',
      };
    case MyAccountTypes.UPDATE_FAILURE:
      return {
        ...state,
        requestStatusBySection: { ...state.requestStatusBySection, [action.payload]: 'failure' },
      };

    case MyAccountTypes.SET_DIGITAL:
      return { ...state, digital: action.payload };

    case MyAccountTypes.SET_PERSONAL_DATA:
      return { ...state, personalData: action.payload };

    case MyAccountTypes.SET_PROFESSIONAL_DATA:
      return { ...state, professionalData: action.payload };

    case MyAccountTypes.SET_CONTACTS_DATA:
      return { ...state, contactsData: action.payload };

    case MyAccountTypes.SET_COUNCIL_AND_SPECIALTIES:
      return { ...state, councilAndSpecialties: action.payload };

    case MyAccountTypes.SET_OPEN_DIGITAL_SIGNATURE:
      return { ...state, openDigitalSignature: action.payload };

    case MyAccountTypes.SET_TRUST:
      return { ...state, trust: action.payload };

    case MyAccountTypes.SET_CREATED_AT:
      return { ...state, createdAt: action.payload };

    default:
      return state;
  }
}
