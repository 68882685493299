import { UserTypes, IUserState, IUserActions, UserProfiles } from './types';
import { helpers } from '~utils';

const emptyCouncil = {
  document: '',
  documentState: [],
  documentType: '',
  documentRegionalCouncil: '',
};

const initialState: IUserState = {
  id: '',
  appointmentType: '',
  domain: '',
  email: '',
  exp: '',
  federalTaxId: '',
  gender: '',
  iat: '',
  roles: [],
  rolesHigia: [],
  userCrmState: '',
  userCrmValue: '',
  userFederalTaxId: '',
  userId: '',
  userIdentifierValue: '',
  userName: '',
  userType: UserProfiles.DOCTOR,
  updatedPassword: true,
  version: '',
  initialNames: '',
  council: emptyCouncil,
  digital: {
    trust: false,
    show: true,
  }
};

const getUserProfile = (userProfile: string) => {
  switch (userProfile) {
    case 'OPERATOR':
      return UserProfiles.OPERATOR;
    default:
      return UserProfiles.DOCTOR;
  }
};

export default function userReducer(
  state = initialState,
  action: IUserActions,
): IUserState {
  switch (action.type) {
    case UserTypes.GET_USER:
      return {
        ...state,
      };

    case UserTypes.GET_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        userType: getUserProfile(action.payload.userType),
        initialNames: helpers.getInitialNames(action.payload.userName),
      };

    case UserTypes.GET_USER_FAILURE:
      return {
        ...state,
        ...initialState,
      };

    case UserTypes.SET_USER_APPOINTMENT_TYPE:
      return {
        ...state,
        appointmentType: action.appointmentType,
      };

    case UserTypes.SET_ROLES_HIGIA:
      return {
        ...state,
        rolesHigia: action.payload,
      };

    default:
      return state;
  }
}
