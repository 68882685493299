import { AxiosPromise } from 'axios';
import api, { pathPesTelemedicineV4, pathPesTelemedicineV5 } from '../axios/api';
import { EnumRegisterType } from '~utils/enums';
import { IRegisterLegacyPayload } from '~/store/register/types';

const basePath = `${pathPesTelemedicineV4}/register`;
const basePathV5 = `${pathPesTelemedicineV5}/register`;

export interface IVerificationHeaders {
  'User-Type': string;
  'Federal-Tax-Id': string;
  'Captcha-Token'?: string;
  'Invitation-Token'?: string;
}

interface IPersonalDataResponse {
  id?: string;
  name?: string;
  surname?: string;
  sex?: string;
  birthDate?: string;
}

export interface IContact {
  id: number;
  type: string;
  value?: string;
}

export interface ISpecialty {
  id: number;
  text: string;
  value: string;
}

export interface IVerificationResponse {
  registerType: EnumRegisterType;
  reasons?: string[];
  personalData?: IPersonalDataResponse;
  professionalData?: IProfessionalData;
  contacts?: IContact[];
  specialities?: ISpecialty[];
}

export interface ISaveResponse {
  sendBy: string;
  contactId: number;
}

interface IPersonalData {
  federalTaxId: string;
  name?: string;
  surname?: string;
  sex?: string;
  birthDate?: string;
  phoneNumber?: string;
  email?: string;
}

interface ISpecialities {
  specialityDescription: string;
  id: number;
}

interface IProfessionalData {
  uf: string;
  council: string;
  registryNumber: string;
  type?: string;
  specialities?: ISpecialities[];
}

interface ICredentials {
  password: string;
}

export interface IToken {
  sendBy: string;
  contactId?: number;
}

export interface ISavePayload {
  registerType: string;
  personalData: IPersonalData;
  professionalData: IProfessionalData;
  credentials: ICredentials;
  token: IToken;
  userType: string;
  invitationToken: string;
  doctorPatientId?: string;
}

export interface IResendTokenPayload {
  registerType: string;
  personalData: Partial<IPersonalData>;
  professionalData: Partial<IProfessionalData>;
  credentials: ICredentials;
  token: IToken;
  userType?: string;
}

export interface IValidateCouncilPayload {
  council: string;
  uf: string;
  registryNumber: string;
  federalTaxId: string;
  birthDate: string;
}

const RegisterServices = {
  verification: (headers: IVerificationHeaders): AxiosPromise<IVerificationResponse> => {
    const isIe11 = !!window.MSInputMethodContext && !!document.documentMode;
    const ie11NoCache = isIe11 ? `?d=${Date.now()}` : '';
    return api.get(`${basePathV5}/flow${ie11NoCache}`, { headers });
  },
  save: (payload: ISavePayload, recaptcha: string): AxiosPromise<ISaveResponse> =>
    api.post(`${basePathV5}`, payload, { headers: { 'Captcha-Token': recaptcha } }),
  saveLegacy: (payload: IRegisterLegacyPayload): AxiosPromise => api.post(`${basePath}`, payload),
  resendToken: (payload: IResendTokenPayload): AxiosPromise =>
    api.post(`${basePathV5}/resend-token`, payload),
  validateCouncil: (payload: IValidateCouncilPayload, recaptcha: string): AxiosPromise =>
    api.patch(`${basePath}/validate-council`, payload, { headers: { 'Captcha-Token': recaptcha } }),
};

export default RegisterServices;
