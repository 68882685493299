import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  Button,
  Checkbox,
  TextField,
  SpinnerProgress,
  ChipAlert,
  PasswordField,
} from '~components/DesignSystem';
import { Dialog } from '@material-ui/core';
import SideImagePage from '~components/SideImagePage';
import UserProfileButtons from '~components/UserProfileButtons';
import ModalTermsOfUse from '~/modules/components/ModalTermsOfUse';
import ModalRedirectToRegister from '~/modules/components/ModalRedirectToRegister';

import { ApplicationState } from '~store/rootReducer';
import {
  loginRequest,
  updateTermsOfUse,
  cleanRequestErrors,
  showModalRedirectToRegister,
} from '~/store/authHospitalCpf/actions';
import { setFederalTaxId } from '~/store/authHospital/actions';

import format from '~utils/format';
import validation from '~utils/validation';
import cryptography from '~utils/cryptography';
import { setStorage } from '~utils/storage';
import { getStorage } from '~utils/storage';
import { EnumProfile } from '~/utils/enums';

import ModalDefault, {
  IProps as IModalDefault,
} from '~components/ModalDefault';

import useStyles from './styles';
import Header from '~/modules/components/Header';
import window from '~/utils/env';

interface IFormLogin {
  federalTaxId: string;
  username: string;
  password: string;
}

const LoginHospitals = (): JSX.Element => {
  const { auth, register: registerStore } = useSelector((state: ApplicationState) => state);
  const { authHospital } = useSelector((state: ApplicationState) => state);
  const { authHospitalCpf } = useSelector((state: ApplicationState) => state);
  const { featureFlag } = useSelector((state: ApplicationState) => state);
  const [openModalTerms, setOpenModalTerms] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [userType, setUserType] = useState('DOCTOR');
  const [showModalHospitalsNotification, setshowModalHospitalsNotification] = useState('hide');
  
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, errors, handleSubmit, getValues } = useForm<IFormLogin>();

  const classes = useStyles();

  const setInformationToTokenPage = (data: IFormLogin) => {
    const information = {
      federalTaxId: data.federalTaxId,
      userType: userType,
    };
    setStorage(
      'registerFederalTaxId',
      cryptography.ObjectCryptography.encode(information.federalTaxId),
    );
    setStorage(
      'userType', 
      cryptography.ObjectCryptography.encode(information.userType)
    );
  };

  const applyFederalTaxIdMask = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (/^\d+$/.test(target.value) && target.value.length === 11) {
      target.value = format.toCPF(target.value);
    }
  };

  const handleTermsOfUseOpen = () => {
    setOpenModalTerms(true);
  };

  const handleTermsOfUseClose = () => {
    setOpenModalTerms(false);
  };

  const handleSignupClick = () => {
    history.push('/cadastro');
  }

  const handleTermsOfUseConfirmation = () => {
    const data: IFormLogin = getValues();
    dispatch(updateTermsOfUse(data));
  };

  const onSubmit = (data: IFormLogin) => {    
    const dataPayload = { 
      username: validation.isValidFederalTaxId(data.federalTaxId) ? data.federalTaxId.replace(/[.-]/g, '') : data.federalTaxId,
      password: data.password,
     };
    setInformationToTokenPage(data);
    dispatch(loginRequest(dataPayload));
  };

  const checkAuthEffect = () => {
    if (!authHospitalCpf.hasAcceptedTerms) {
      handleTermsOfUseOpen();
    } else {
      handleTermsOfUseClose();
    }

    if (auth.updatePassword && !firstUpdate.current) {
      history.push('/redefinir-senha');
    }

    if (auth.isAuthenticated) {
      history.replace('/');
    }
  };

  const checkQueryParamsEffect = () => {
    const { search } = history.location;
    const params = new URLSearchParams(search);
    const expired = params.get('expired');
    if (expired) {
      setIsExpired(true);
    }
  };

  const changeUserType = (value: string) => {
    setUserType(value);
  };

  const redirectToRegister = () => {
    dispatch(showModalRedirectToRegister(false));
    history.push('/cadastro');
  }

  const propsModalHospitalsNotification: IModalDefault = {
    title: 'Login com CPF',
    descriptions: [
      'A partir de agora você deve usar o seu CPF para fazer login no Nav Hospitais. A sua senha é a mesma de antes.',
      'Caso ainda não tenha CPF cadastrado, clique em “Atualize seu cadastro”.',
      'Baixe a última versão do aplicativo Nav Pro na loja do seu smartphone para usar os mesmos dados de acesso.'
    ],
    icon: 'warning',
    buttonName: 'Ir para o Login',
    buttonAction: () => {
      setStorage(
        'modalHospitalsNotification',
        cryptography.ObjectCryptography.encode('hide'),
      );
      setshowModalHospitalsNotification('hide');
    },
  };

  const isShowModalHospitalsNotification = () => {
    const showModalHospitalsNotification = cryptography.ObjectCryptography.decode(
      getStorage('modalHospitalsNotification') || '',
    );
    if (showModalHospitalsNotification === 'hide') {
      return false;
    }
    return true;
  }

  useEffect(checkAuthEffect, [authHospitalCpf, getValues, history]);

  useEffect(checkQueryParamsEffect, [history?.location?.search, history.location]);

  useEffect(() => {
    firstUpdate.current = false;
    return () => {
      dispatch(cleanRequestErrors());
      dispatch(setFederalTaxId(''));
    };
  }, [dispatch]);

  return (
    <SideImagePage>
      <div className={classes.formWrapper}>
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Header/>
          <h2 className={classes.applicationName}>Hospitais</h2>
          {/* <div>
            <ChipAlert>
              <span className={classes.info}>Não possui CPF?</span>
              <Link to="/login-email" className={classes.link}>Atualize seu cadastro</Link>
            </ChipAlert>
          </div> */}
          {/* <h6 className={classes.description}>
            <strong> Selecione o seu perfil </strong>
            <span>
              e acesse a plataforma com seus dados para começar os
              atendimentos online, configurar agendas ou fazer a gestão de
              profissionais.
            </span>
          </h6>

          <UserProfileButtons
            onChange={changeUserType}
            value={userType}
            loading={authHospitalCpf.loading}
          /> */}

          <TextField
            className={classes.textField}
            inputRef={register({
              required: true,
            })}
            error={!!errors.federalTaxId || authHospitalCpf.error}
            helperText={errors.federalTaxId ? 'E-mail inválido.' : ''}
            name="federalTaxId"
            label="E-mail"
            variant="outlined"
            type="text"
            fullWidth
            required
            inputProps={{ 'data-testid': 'input-federalTaxId' }}
            data-testid="textfield-federalTaxId"
            onInput={applyFederalTaxIdMask}
          />
          <PasswordField
            className={classes.textField}
            inputRef={register({ required: true, minLength: 5 })}
            error={!!errors.password || authHospitalCpf.error}
            helperText={
              errors.password ? 'Senha necessita pelo menos 5 caracteres.' : ''
            }
            name="password"
            label="Senha"
            variant="outlined"
            fullWidth
            required
            inputProps={{ 'data-testid': 'input-password' }}
            data-testid="textfield-password"
          />
          <div
            className={`${classes.reminders} ${classes.remindersUnifiedLogin}`}
          >
            <Link 
              className={classes.forgot} 
              to="/esqueci-senha">
              Esqueceu a senha?
            </Link>
          </div>
          {authHospitalCpf.errorMessage && (
            <p className={classes.textError}>{authHospitalCpf.errorMessage}</p>
          )}
          {!authHospitalCpf.error && isExpired && (
            <p className={classes.textError}>
              Sua sessão expirou. Por favor, faça login novamente.
            </p>
          )}
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              disabled={authHospitalCpf.loading}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              data-testid="button-login"
            >
              {authHospitalCpf.loading ? <SpinnerProgress /> : 'Entrar'}
            </Button>
            <h6 className={classes.buttonSeparator}>Você é médico e não tem uma conta?</h6>
            <Button
              className={classes.buttonSecondary}
              variant="contained"
              color="default"
              onClick={handleSignupClick}
              fullWidth
              data-testid="button-signup"
            >
              Cadastre-se
            </Button>
          </div>
        </form>
        {/* <ModalTermsOfUse
          open={openModalTerms}
          handleClose={handleTermsOfUseClose}
          handleConfirmation={handleTermsOfUseConfirmation}
        /> */}
        <ModalRedirectToRegister
          open={authHospitalCpf.showModalToRegister}
          handleClose={redirectToRegister}          
        />
        <Dialog
          open={showModalHospitalsNotification !== 'hide'}
          classes={
            {container: classes.modalHospitalsNotification}
          }
          disableBackdropClick
        >
          {showModalHospitalsNotification !== 'hide' && <ModalDefault {...propsModalHospitalsNotification} />}
        </Dialog>
      </div>
    </SideImagePage>
  );
};

export default LoginHospitals;
