import { StyleRules } from '@material-ui/core/styles';
import { FormHelperTextClassKey } from '@material-ui/core';
import colors from '../palette-color';

const FormHelperText: Partial<StyleRules<FormHelperTextClassKey>> = {
  root: {
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '1rem',

    '&.Mui-error': {
      color: colors.danger[40],
    },
  },
};

export default FormHelperText;
