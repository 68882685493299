import {
  AuthTypes,
  IAuthActions,
  IAuthLoginRequestPayload,
  IAuthSetVerificationTypePayload,
} from './types';

export const loginRequest = (
  payload: IAuthLoginRequestPayload,
): IAuthActions => ({
  type: AuthTypes.LOGIN_REQUEST,
  payload,
});

export const loginRequestError = (errorMessage: string): IAuthActions => ({
  type: AuthTypes.LOGIN_FAILURE,
  errorMessage,
});

export const checkAuthentication = (
  payload?: string | null,
): IAuthActions => ({
  type: AuthTypes.VERIFY,
  payload,
});

export const logoutAction = (): IAuthActions => ({
  type: AuthTypes.LOGOUT_REQUEST,
});

export const updateTermsOfUse = (
  payload: IAuthLoginRequestPayload,
): IAuthActions => ({
  type: AuthTypes.UPDATE_TERMS_OF_USE,
  payload,
});

export const setVerificationType = (
  payload: IAuthSetVerificationTypePayload,
): IAuthActions => ({
  type: AuthTypes.SET_VERIFICATION_TYPE,
  payload,
});

export const cleanRequestErrors = (): IAuthActions => ({
  type: AuthTypes.CLEAN_ERROR_MESSAGE,
});

export const setRedirectError = (payload: boolean): IAuthActions => ({
  type: AuthTypes.SET_REDIRECT_ERROR,
  payload,
});
