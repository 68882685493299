import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      height: theme.spacing(4),
    },
    line: {
      backgroundColor: theme.palette.black[24],
      flexGrow: 1,
      flexShrink: 1,
      height: 1,
      marginTop: 1,
    },
    text: {
      ...theme.typography.caption,
      color: theme.palette.grayscale[50],
      flexGrow: 0,
      flexShrink: 0,
      padding: theme.spacing(0, 3),
    },
  }),
);
