import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(4),
      minWidth: 364,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
        minWidth: 'auto',
      },
    },
    iconSuccess: {
      color: theme.palette.success[40],
    },
    iconError: {
      color: theme.palette.danger[40],
    },
    iconWarning: {
      color: theme.palette.alert[50],
    },
    title: {
      ...theme.typography.h2,
      margin: theme.spacing(3, 0, 0),
    },
    description: {
      ...theme.typography.body2,
      color: theme.palette.grayscale[30],
      margin: theme.spacing(4, 0, 0),
      whiteSpace: 'pre-line',
    },
    action: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(5),
    },
    button: {
      marginTop: 0,
      minWidth: 110,
    },
  }),
);
