import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    textWrapper: {
      display: 'flex',
      width: '100%',
      '& span::before': {
        color: theme.palette.grayscale[80],
      },
      '&:focus-within span::before': {
        color: theme.palette.primary[50],
      },
      '& .MuiFormLabel-root': {
        color: '#909090',
      },
      '& .MuiInput-underline::before, .MuiInput-underline::after': {
        borderBottom: 'none !important',
      },
      '& .MuiInputBase-input': {
        fonSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: theme.palette.danger[60],
      },
    },
    inputIcon: {
      fontSize: theme.typography.h2.fontSize,
      display: 'flex',
      marginRight: theme.spacing(1),
      '&::before': {
        marginTop: 2,
      },
    },
  }),
);
