import React from 'react';
import clsx from 'clsx';
import { Button, Icon } from '@material-ui/core';

import useStyles from './styles';

export type IProps = {
  icon?: 'success' | 'error' | 'warning' | 'help';
  textAlign?: 'left' | 'center' | 'right';
  title?: string;
  description?: string;
  descriptions? : Array<string>,
  buttonName: string;
  buttonAction: () => void;
};

const ModalDefault = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const {
    icon,
    title,
    description,
    descriptions,
    textAlign,
    buttonName,
    buttonAction,
  } = props;

  const getIcon = clsx({
    [`icon-check ${classes.iconSuccess}`]: icon === 'success',
    [`icon-error ${classes.iconError}`]: icon === 'error',
    [`icon-error ${classes.iconWarning}`]: icon === 'warning',
    [`icon-help`]: icon === 'help',
  });

  const rootStyle = { textAlign: textAlign || 'left' };

  return (
    <div className={classes.root} style={rootStyle}>
      {icon && <Icon className={getIcon} />}
      {title && <h2 className={classes.title}>{title}</h2>}

      {description && <p className={classes.description}>{description}</p>}
      {typeof descriptions === 'object' && descriptions.length > 0 && (
        <>
          {descriptions.map((item) => (
            <p className={classes.description}>{item}</p>
          ))}
        </>
      )}
      
      <div className={classes.action}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          onClick={buttonAction}
        >
          {buttonName}
        </Button>
      </div>
    </div>
  );
};

export default ModalDefault;
