export enum RegisterTypes {
  RESET = '@register/RESET',
  RESET_STATUS = '@register/RESET_STATUS',
  VERIFICATION_REQUEST = '@register/VERIFICATION_REQUEST',
  VERIFICATION_SUCCESS = '@register/VERIFICATION_SUCCESS',
  VERIFICATION_FAILURE = '@register/VERIFICATION_FAILURE',
  SET_REGISTER_USER = '@register/SET_REGISTER_USER',
  SET_DISABLED_FIELDS = '@register/SET_DISABLED_FIELDS',
  SET_CONTACT_OPTIONS = '@register/SET_CONTACT_OPTIONS',
  SET_SPECIALTY_OPTIONS = '@register/SET_SPECIALTY_OPTIONS',
  SAVE_REQUEST = '@register/SAVE_REQUEST',
  SAVE_SUCCESS = '@register/SAVE_SUCCESS',
  SAVE_FAILURE = '@register/SAVE_FAILURE',
  SAVE_LEGACY_REQUEST = '@register/SAVE_LEGACY_REQUEST',
  SAVE_LEGACY_SUCCESS = '@register/SAVE_LEGACY_SUCCESS',
  SAVE_LEGACY_FAILURE = '@register/SAVE_LEGACY_FAILURE',
  RESEND_TOKEN_REQUEST = '@register/RESEND_TOKEN_REQUEST',
  RESEND_TOKEN_SUCCESS = '@register/RESEND_TOKEN_SUCCESS',
  RESEND_TOKEN_FAILURE = '@register/RESEND_TOKEN_FAILURE',
  CONFIRM_TOKEN_REQUEST = '@register/CONFIRM_TOKEN_REQUEST',
  CONFIRM_TOKEN_SUCCESS = '@register/CONFIRM_TOKEN_SUCCESS',
  CONFIRM_TOKEN_FAILURE = '@register/CONFIRM_TOKEN_FAILURE',
  REGISTER_LEGACY = '@register/REGISTER_LEGACY',
  NEEDS_VALIDATION = '@register/NEEDS_VALIDATION',
  SET_NEW_PASSWORD = '@register/SET_NEW_PASSWORD',
  RESET_TOKEN = '@register/RESET_TOKEN',
  SET_DIGITAL_CERTIFICATE = '@register/SET_DIGITAL_CERTIFICATE',
  SET_COUNCIL_AND_SPECIALTIES = '@register/SET_COUNCIL_AND_SPECIALTIES',
}

export interface IRegisterUser {
  registerType: string;
  federalTaxId: string;
  name?: string;
  surname?: string;
  birthDate?: string;
  sex?: string;
  sexualOrientation?: string;
  speciality?: string;
  specialityId?: number;
  specialities?: [];
  documentOrg: string;
  documentState: string;
  documentNumber: string;
  phoneNumber?: string;
  email?: string;
  password: string;
  sendById: string;
  sendByType: string;
  sendByText: string;
  userType: 'DOCTOR' | 'OPERATOR';
  id?: number;
}

export interface IDisabledFields {
  name: boolean;
  surname: boolean;
  birthDate: boolean;
  sex: boolean;
  phoneNumber: boolean;
  email: boolean;
}

export const emptyRegisterUser = {
  registerType: '',
  federalTaxId: '',
  name: '',
  surname: '',
  birthDate: '',
  sex: '',
  sexualOrientation: '',
  speciality: '',
  documentOrg: '',
  documentState: '',
  documentNumber: '',
  phoneNumber: '',
  email: '',
  password: '',
  sendById: '',
  sendByType: '',
  sendByText: '',
  userType: 'DOCTOR' as 'DOCTOR' | 'OPERATOR',
  id: -1,
};

export const initialDisabledFields = {
  name: false,
  surname: false,
  birthDate: false,
  sex: false,
  phoneNumber: false,
  email: false,
};

export interface IContactOption {
  id: number;
  value: string;
  type: string;
}

export interface ISpecialtyOption {
  id: number;
  value: string;
  text: string;
}

export interface IVerificationRequestPayload {
  invitationToken?: string;
  federalTaxId: string;
  userType: string;
  recaptchaToken?: string;
}

export interface ISaveRequestPayload {
  password: string;
  sendById: string;
  userType: string;
  invitationToken: string;
  recaptchaToken?: string;
  doctorPatientId?: string;
}

export interface ISaveLegacyRequestPayload {
  registerType: 'INTERNAL_LEGACY';
  userType: 'DOCTOR' | 'OPERATOR';
  credentials: {
    password: string;
  };
  personalData: {
    federalTaxId: string;
  };
  token: {
    sendBy: 'MOBILE' | 'EMAIL' | 'ALL';
    contacts: {
      id: number;
      type: 'MOBILE' | 'EMAIL';
    }[];
  };
}

export interface ISelect {
  label: string;
  value: string | number;
}

export interface ICouncilAndSpecialty extends ISelect {
  value: string;
  text: string;
  groups: ISelect[];
  specialties: ISelect[];
}

export interface IRegisterState {
  readonly registerUser: IRegisterUser;
  readonly disabledFields: IDisabledFields;
  readonly contactOptions: IContactOption[];
  readonly specialtyOptions: ISpecialtyOption[];
  readonly loading: boolean;
  readonly success: boolean;
  readonly failure: boolean;
  readonly needsValidation: boolean;
  readonly errorMessage?: string;
  readonly confirmTokenSuccess?: boolean;
  readonly confirmTokenFailure?: boolean;
  readonly confirmTokenLoading?: boolean;
  readonly digitalCertificate?: boolean;
  readonly councilAndSpecialties: ICouncilAndSpecialty[];
}

export interface IResetAction {
  type: typeof RegisterTypes.RESET;
}
export interface IResetStatusAction {
  type: typeof RegisterTypes.RESET_STATUS;
}
export interface IVerificationRequestAction {
  type: typeof RegisterTypes.VERIFICATION_REQUEST;
  payload: IVerificationRequestPayload;
}
export interface IVerificationSuccessAction {
  type: typeof RegisterTypes.VERIFICATION_SUCCESS;
}
export interface IVerificationFailureAction {
  type: typeof RegisterTypes.VERIFICATION_FAILURE;
  errorMessage: string;
}
export interface ISetRegisterUserAction {
  type: typeof RegisterTypes.SET_REGISTER_USER;
  registerUser: Partial<IRegisterUser>;
}
export interface ISetDisabledFieldsAction {
  type: typeof RegisterTypes.SET_DISABLED_FIELDS;
  disabledFields: IDisabledFields;
}
export interface ISetContactOptionsAction {
  type: typeof RegisterTypes.SET_CONTACT_OPTIONS;
  contactOptions: IContactOption[];
}
export interface ISetSpecialtyOptionsAction {
  type: typeof RegisterTypes.SET_SPECIALTY_OPTIONS;
  specialtyOptions: ISpecialtyOption[];
}
export interface ISaveRequestAction {
  type: typeof RegisterTypes.SAVE_REQUEST;
  payload: ISaveRequestPayload;
}
export interface ISaveSuccessAction {
  type: typeof RegisterTypes.SAVE_SUCCESS;
}
export interface ISaveFailureAction {
  type: typeof RegisterTypes.SAVE_FAILURE;
  errorMessage: string;
}

export interface ISaveLegacyRequestAction {
  type: typeof RegisterTypes.SAVE_LEGACY_REQUEST;
  payload: ISaveLegacyRequestPayload;
}
export interface ISaveLegacySuccessAction {
  type: typeof RegisterTypes.SAVE_LEGACY_SUCCESS;
}
export interface ISaveLegacyFailureAction {
  type: typeof RegisterTypes.SAVE_LEGACY_FAILURE;
  errorMessage: string;
}
export interface IResetTokenAction {
  type: typeof RegisterTypes.RESET_TOKEN;
}
export interface IResendTokenRequestAction {
  type: typeof RegisterTypes.RESEND_TOKEN_REQUEST;
  userType?: string;
}
export interface IResendTokenSuccessAction {
  type: typeof RegisterTypes.RESEND_TOKEN_SUCCESS;
}
export interface IResendTokenFailureAction {
  type: typeof RegisterTypes.RESEND_TOKEN_FAILURE;
}

export interface IConfirmTokenRequestAction {
  type: typeof RegisterTypes.CONFIRM_TOKEN_REQUEST;
  token: string;
  userType: string;
}
export interface IConfirmTokenSuccessAction {
  type: typeof RegisterTypes.CONFIRM_TOKEN_SUCCESS;
}
export interface IConfirmTokenFailureAction {
  type: typeof RegisterTypes.CONFIRM_TOKEN_FAILURE;
}

export interface IRegisterLegacyPayload {
  personalData: {
    federalTaxId: string;
  };
  registerType: 'INTERNAL_LEGACY';
  userType: 'DOCTOR' | 'OPERATOR';
  credentials: {
    password: string;
  };
  token: {
    sendBy: 'MOBILE' | 'EMAIL' | 'ALL';
    contacts: {
      id: number;
      type: 'MOBILE' | 'EMAIL';
    }[];
  };
}

export interface IRegisterLegacyAction {
  type: typeof RegisterTypes.REGISTER_LEGACY;
  payload: IRegisterLegacyPayload;
}

export interface IRegisterValidationPayload {
  registerType: string;
  federalTaxId: string;
  userType: 'DOCTOR' | 'OPERATOR';
  personalData: {
    id: number;
    firstName: string;
  };
  contacts: {
    type: 'EMAIL' | 'MOBILE';
    value: string;
    id: number;
  }[];
}

export interface IRegisterValidationAction {
  type: typeof RegisterTypes.NEEDS_VALIDATION;
  payload: IRegisterValidationPayload;
}

export interface IRegisterSetUserPasswordPayload {
  password: string;
}

export interface IAuthSetPasswordAction {
  type: typeof RegisterTypes.SET_NEW_PASSWORD;
  payload: IRegisterSetUserPasswordPayload;
}

export interface ISetDigitalCertificateAction {
  type: typeof RegisterTypes.SET_DIGITAL_CERTIFICATE;
  value: boolean;
}

export interface ISetCouncilAndSpecialtiesAction {
  type: typeof RegisterTypes.SET_COUNCIL_AND_SPECIALTIES;
  payload: ICouncilAndSpecialty[];
}

export type IRegisterActions =
  | IResetAction
  | IResetStatusAction
  | IVerificationRequestAction
  | IVerificationSuccessAction
  | IVerificationFailureAction
  | ISetRegisterUserAction
  | ISetDisabledFieldsAction
  | ISetContactOptionsAction
  | ISetSpecialtyOptionsAction
  | ISaveRequestAction
  | ISaveSuccessAction
  | ISaveFailureAction
  | ISaveLegacyRequestAction
  | ISaveLegacySuccessAction
  | ISaveLegacyFailureAction
  | IResendTokenRequestAction
  | IResendTokenSuccessAction
  | IResendTokenFailureAction
  | IConfirmTokenRequestAction
  | IConfirmTokenSuccessAction
  | IConfirmTokenFailureAction
  | IRegisterLegacyAction
  | IRegisterValidationAction
  | IAuthSetPasswordAction
  | IResetTokenAction
  | ISetDigitalCertificateAction
  | ISetCouncilAndSpecialtiesAction;
