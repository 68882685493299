import axios, { AxiosInstance } from 'axios';
import { getStorage } from '~utils/storage';
import unauthorizedErrorInterceptor from './interceptors/unauthorizedErrorInterceptor';
import defaultResponseInterceptor from './interceptors/defaultResponseInterceptor';
import defaultErrorInterceptor from './interceptors/defaultErrorInterceptor';
import helpers from '~utils/helpers';

/**
 * Instance of axios
 * @returns { methods }
 */
const api: AxiosInstance = axios.create({
  baseURL: process.env.BFF_TELEMEDICINE_URL || process.env.REACT_APP_BFF_TELEMEDICINE_URL,
});

export const pathDoctorManagement = 'doctor-management/api/v1';
export const pathUserManagementDasa = `${process.env.REACT_APP_BFF_TELEMEDICINE_URL_DASA}/api/v3/auth`;
export const pathTelemedicineDasa = `${process.env.REACT_APP_BFF_TELEMEDICINE_URL_DASA}/api/v3`;
export const pathUserHospital = `${process.env.REACT_APP_NAV_USER_HOSPITAL_URL}/api/v1`;
export const pathPesTelemedicine = `${process.env.REACT_APP_BFF_TELEMEDICINE_URL_DASA}/api/v2`;
export const pathPesTelemedicineV4 = `${process.env.REACT_APP_BFF_TELEMEDICINE_URL_DASA}/api/v4`;
export const pathPesTelemedicineV5 = `${process.env.REACT_APP_BFF_TELEMEDICINE_URL_DASA}/api/v5`;
export const pathBffOnboarding = `${process.env.REACT_APP_BFF_ONBOARDING}`;
export const pathHigiaApi = `${process.env.REACT_APP_HIGIA_API}`;
export const pathNotificationApi = `${process.env.REACT_APP_APIM_NAV_PRO_API_URL}/backoffice/alerts/client`;
export const pathAPIMNavProApi = `${process.env.REACT_APP_APIM_NAV_PRO_API_URL}`;

api.defaults.headers = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

const protectedByAccessTokenApis = [pathBffOnboarding, pathNotificationApi];

const shouldSetAccessToken = (url: string) => {
  return protectedByAccessTokenApis.some(baseUrlApi => new RegExp(`\\b${baseUrlApi}\\b`).test(url));
};

api.interceptors.request.use((config: any) => {
  let jwtToken = null;
  const token = getStorage('token');
  const accessToken = getStorage('access_token');
  const resetPasswordToken = helpers.getResetPasswordTokenFromHashRoute();
  if (resetPasswordToken) {
    jwtToken = atob(resetPasswordToken);
  }
  if (token) {
    jwtToken = atob(token);
  }
  if (accessToken && shouldSetAccessToken(config.url)) {
    jwtToken = accessToken;
  }
  const headers = {
    Authorization: jwtToken ? `Bearer ${jwtToken}` : '',
    ...config.headers,
  };
  const newConfig = {
    ...config,
    headers,
  };
  return newConfig;
});

api.interceptors.response.use(defaultResponseInterceptor, unauthorizedErrorInterceptor);

export default api;
