import { IAuthHospitalActions, IAuthHospitalCpfState, AuthHospitalTypes } from './types';

const initialState: IAuthHospitalCpfState = {
  isAuthenticated: false,
  loading: false,
  error: false,
  errorMessage: '',
  verified: false,
  hasAcceptedTerms: true,
  updatePassword: false,
  showModalToRegister: false,
};

export default function AuthHospitalCpfReducer(
  state = initialState,
  action: IAuthHospitalActions,
): IAuthHospitalCpfState {
  switch (action.type) {
    case AuthHospitalTypes.LOGIN_REQUEST_HOSPITAL:
      return {
        ...state,
        loading: true,
        error: false,
        hasAcceptedTerms: true,
        errorMessage: '',
      };

    case AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL:
      return {
        ...state,
        loading: false,
        error: true,
        hasAcceptedTerms: true,
        errorMessage: action.errorMessage,
      };

    case AuthHospitalTypes.VERIFY_HOSPITAL:
      return {
        ...state,
        verified: false,
        loading: true,
        error: false,
        hasAcceptedTerms: true,
        showModalToRegister: false,
      };

    case AuthHospitalTypes.AUTHORIZE_HOSPITAL:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        verified: true,
        hasAcceptedTerms: true,
        updatePassword: false,
      };

    case AuthHospitalTypes.UNAUTHORIZE_HOSPITAL:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        verified: true,
        hasAcceptedTerms: true,
      };

    case AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL:
      return { ...initialState, hasAcceptedTerms: true, showModalToRegister: false };

    case AuthHospitalTypes.HAS_ACCEPTED_TERMS_HOSPITAL:
      return {
        ...state,
        loading: false,
        error: false,
        hasAcceptedTerms: false,
      };

    case AuthHospitalTypes.UPDATE_TERMS_OF_USE_HOSPITAL:
      return { ...state, loading: true, error: false, hasAcceptedTerms: true };

    case AuthHospitalTypes.UPDATE_PASSWORD_HOSPITAL:
      return {
        ...state,
        loading: false,
        error: false,
        verified: true,
        updatePassword: true,
      };

    case AuthHospitalTypes.CLEAN_ERROR_MESSAGE_HOSPITAL:
      return {
        ...state,
        errorMessage: '',
        error: false,
      };
      
    case AuthHospitalTypes.SHOW_MODAL_REDIRECT_TO_REGISTER:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        verified: true,
        hasAcceptedTerms: true,
        showModalToRegister: action.showModalToRegister,
      };

    default:
      return state;
  }
}
