import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { ApplicationState } from '~store/rootReducer';
import { fetchFeatureFlag } from '~store/feature-flag/actions';

const ApolloWrapper = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const { featureFlag } = useSelector((state: ApplicationState) => state);
  const dispatch = useDispatch();

  const uri = `${process.env.REACT_APP_APIM_NAV_PRO_API_URL}/servicos/login/v1`;
  const apiKey = process.env.REACT_APP_APIM_NAV_PRO_API_KEY;

  const client = new ApolloClient({
    headers: {
      'api-key': apiKey || '',
    },
    uri,
    cache: new InMemoryCache(),
  });

  const initialSetup = () => {
    dispatch(fetchFeatureFlag());
  };

  useEffect(initialSetup, []);

  return (
    <React.Fragment>
      {featureFlag.fetchLoaded && (
        <ApolloProvider client={client}>
          { children }
        </ApolloProvider>
      )}
    </React.Fragment>
  );
};

export default ApolloWrapper;
