import { AxiosPromise } from 'axios';
import api, { pathUserManagementDasa, pathTelemedicineDasa } from '../axios/api';
import { EnumAppointmentType, EnumProfile } from '~utils/enums';

const basePath = `${pathUserManagementDasa}/users`;
const mdmPath = `${pathTelemedicineDasa}/auth/user`;
const checkAccount = `${mdmPath}/check-account`;
export interface IResponseGetUser {
  id: string;
  name: string;
  email: string;
  federalTaxId: string;
  phoneNumber: string;
  firstAccess: boolean;
  dateOfBirth: string;
  userType: string;
  createdAt: string;
  updatedAt: string;
  Doctor?: {
    document: string;
  };
}

export interface IPersonalInfo {
  documentOrg: string;
  document: string;
  documentState: string;
  specialty: string;
}

export interface IPayloadCreateUser {
  application: string;
  name: string;
  gender: string;
  email: string;
  password: string;
  federalTaxId: string;
  phoneNumber: string;
  dateOfBirth: string;
  userType: EnumProfile;
  termsAccepted: boolean;
  roles?: EnumAppointmentType[];
  personalInfo: IPersonalInfo;
}

export interface IPayloadConfirmToken {
  username: string;
  token: string;
  userType: string;
}

const UserServices = {
  getUser: (id: string): AxiosPromise => api.get(`${basePath}/${id}`),
  createUser: (payload: IPayloadCreateUser): AxiosPromise =>
    api.post(basePath, payload),
  createMdmUser: (payload: IPayloadCreateUser): AxiosPromise =>
    api.post(mdmPath, payload),
  getDoctorUser: (federalTaxId: string): AxiosPromise =>
    api.get(`${basePath}/federalTaxId`, { headers: { federalTaxId } }),
  confirmToken: (payload: IPayloadConfirmToken): AxiosPromise =>
    api.put(checkAccount, payload),
};

export default UserServices;
