import { Palette } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const Typography:
  | TypographyOptions
  | ((palette: Palette) => TypographyOptions) = {
  fontFamily: ['Dasa Sans', 'Montserrat', 'Helvetica', 'sans-serif'].join(','),
  fontSize: 16,
  h1: {
    fontSize: '2.5rem', // 40px
    fontWeight: 600,
    letterSpacing: '-0.16px',
    lineHeight: '3rem',
  },
  h2: {
    fontSize: '1.5rem', // 24px
    fontWeight: 600,
    lineHeight: '2rem',
  },
  h3: {
    fontSize: '1.25rem', // 20px
    fontWeight: 600,
    lineHeight: '1.5rem',
  },
  h4: {
    fontSize: '1.125rem', // 18px
    fontWeight: 600,
    lineHeight: '1.5rem',
  },
  h5: {
    fontSize: '1rem', // 16px
    fontWeight: 600,
    lineHeight: '1.5rem',
  },
  h6: {
    fontSize: '0.875rem', // 14px
    fontWeight: 600,
    letterSpacing: '0.16px',
    lineHeight: '1.25rem',
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 500,
    letterSpacing: '0.08px',
    lineHeight: '1.5rem',
  },
  body2: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.08px',
    lineHeight: '1.5rem',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.16px',
    lineHeight: '1.5rem',
  },
  subtitle2: {
    fontSize: '0.8125rem',
    fontWeight: 400,
    letterSpacing: '0.16px',
    lineHeight: '1rem',
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.64px',
    lineHeight: '1rem',
  },
  overlineX: {
    fontSize: '0.625rem',
    fontWeight: 600,
    letterSpacing: '0.72px',
    lineHeight: '0.75rem',
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.24px',
    lineHeight: '1rem',
  },
  link: {
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.24px',
    lineHeight: '1rem',
    cursor: 'pointer',
  },
};

export default Typography;
