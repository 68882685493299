import {
  all,
  AllEffect,
  call,
  ForkEffect,
  put,
  takeLatest,
  StrictEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import FeatureFlagService from '~/services/feature-flag';
import {
  ExamsDeniedTypes,
  IExamsDeniedRequest,
  IExamsDeniedResponse,
} from './types';

export function* getExamsDenied(
  action: IExamsDeniedRequest,
): Generator<StrictEffect> {
  try {
    const response = yield call(
      FeatureFlagService.getFeatureFlagLists,
      action.params,
    );
    const { data } = response as AxiosResponse<IExamsDeniedResponse>;

    if (!data) throw new Error('Failed to get examsDenied list');
    yield put({
      type: ExamsDeniedTypes.GET_EXAMS_DENIED_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({ type: ExamsDeniedTypes.GET_EXAMS_DENIED_FAILURE });
  }
}

export default function* examsDeniedSagas(): Generator<AllEffect<ForkEffect>> {
  yield all([takeLatest(ExamsDeniedTypes.GET_EXAMS_DENIED, getExamsDenied)]);
}
