import {
  call,
  put,
  all,
  takeLatest,
  AllEffect,
  ForkEffect,
} from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { IErrorResponse } from '~services/types';
import { UserTypes, IUserState } from '~store/user/types';
import { IFeatureFlagState } from '~store/feature-flag/types';
import { ApplicationState } from '~store/rootReducer';
import {
  AuthHospitalTypes,
  IAuthHospitalLoginRequestAction,
} from './types';
import {
  AuthTypes,
} from '../auth/types';
import { decryptToken, helpers } from '~utils';
import {
  setSessionStorage,
  getStorage,
  clearStorage,
  clearMemed,
} from '~utils/storage';
import AuthHospitalServices, { ILoginHospitalResponse }from '~/services/authHospitalCpf';
import { UserHospitalTypes } from '../userHospital/types';

interface IHandleLogin {
  username: string;
  password: string;
}

const MESSAGES = {
  UNABLE_TO_LOGIN: 'Não foi possível realizar o login. Verifique seus dados e tente novamente.',
}

const redirectToHospitais = (userData : any): void => {
  const { base } = userData?.MDM;
  if (base === 'LEGACY') {
    const { usuario } = userData;
    sessionStorage.setItem('usuario', JSON.stringify(usuario));
  }
  window.location.href = '/hospitais';
}

const loginHospital = (
  username: string,
  password: string,
) =>
  AuthHospitalServices.login({
    username,
    password,
  });

export const handleLogin = async (
  payload: IHandleLogin,
): Promise<AxiosResponse> => {
  const { username, password } = payload;
  return  loginHospital(username, password);
};

export const getFeatureFlag = (state: ApplicationState): IFeatureFlagState =>
  state.featureFlag;

export function* login(action: IAuthHospitalLoginRequestAction): Generator {
  try {
    const { username, password } = action.payload;

    const payloadLogin = {
      username,
      password,
    };

    const response = (yield call(
      handleLogin,
      payloadLogin,
    )) as any;

    const { status, base, userRoles } = response?.data?.MDM;
    if (status === 'APPROVED' || status === 'NO_HOSPITAL' || status === 'CPFNOTFOUND') {
      if (base === 'MDM') {
        const { token } = response?.data?.MDM;
        const tokenDecode: unknown = decryptToken.decode(token);
        const user = tokenDecode as IUserState;

        yield call(setSessionStorage, 'token', btoa(token));
        yield call(setSessionStorage, 'userRoles', btoa(JSON.stringify(userRoles)));

        const appointmentType = helpers.translateRolesToAppointmentType(
          user.roles,
          user.userType,
        );
        const payload = { ...user, appointmentType };

        yield put({ type: UserTypes.GET_USER_SUCCESS, payload });

        if (typeof user.updatedPassword === 'undefined' || user.updatedPassword) {
          yield put({ type: AuthTypes.AUTHORIZE });
          (window as any)?.dataLayer?.push({
            event: 'login_success',
            userId: payload.userId,
            perfil: payload.roles,
          });
        } else {
          yield put({ type: AuthTypes.UPDATE_PASSWORD });
        }
      }
      redirectToHospitais(response?.data);
   } else if (status === 'REGULAR') {
    yield put({ type: AuthHospitalTypes.SHOW_MODAL_REDIRECT_TO_REGISTER, showModalToRegister: true });
   } else {
      const errorMessage = MESSAGES.UNABLE_TO_LOGIN;
      yield put({ type: AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL, errorMessage });
      yield put({ type: AuthHospitalTypes.UNAUTHORIZE_HOSPITAL });
    }
  } catch (error: any) {
    const errorMessage = MESSAGES.UNABLE_TO_LOGIN;
    yield put({ type: AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL, errorMessage });
    yield put({ type: AuthHospitalTypes.UNAUTHORIZE_HOSPITAL });
  }
}

export function* checkAuth(): Generator {
  try {
    const token = getStorage('token');
    if (!token) throw new Error('Unathorize');

    const tokenDecode: unknown = decryptToken.decode(atob(token));
    const user = tokenDecode as IUserState;
    if (!user) throw new Error('Invalid token');

    const storageAppointmentType = getStorage('appointmentType');
    const appointmentType =
      storageAppointmentType ||
      helpers.translateRolesToAppointmentType(user.roles, user.userType);
    const payload = { ...user, appointmentType };

    yield put({ type: UserTypes.GET_USER_SUCCESS, payload });

    yield put({ type: AuthTypes.AUTHORIZE });
  } catch (error: any) {
    yield put({ type: AuthTypes.UNATHORIZE });
  }
}

export function* logout(): Generator {
  clearStorage();
  clearMemed();
  yield put({ type: AuthHospitalTypes.LOGOUT_HOSPITAL });
}

export default function* authSagas(): Generator<AllEffect<ForkEffect>> {

  yield all([
    takeLatest(AuthHospitalTypes.LOGIN_REQUEST_HOSPITAL, login),
    takeLatest(AuthTypes.VERIFY, checkAuth),
    takeLatest(AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL, logout),
  ]);
}
