import { AxiosPromise } from 'axios';
import api, { pathNotificationApi } from '../axios/api';

export interface INotification {
  _id: string;
  vertical: string;
  application: string;
  active: string;
  title: string;
  name: string;
  message: string;
}

export interface IParams {
  vertical: string;
  application: string;
}

const NotificationService = {
  get: ({ vertical, application }: IParams): AxiosPromise => {
    return api.get<INotification>(`${pathNotificationApi}/activeAlerts/${vertical}`, {
      params: {
        application,
      },
    });
  },
};

export default NotificationService;
