export enum EnumAppointmentType {
  MY_PRACTITIONER = 'MY_PRACTITIONER',
  DIGITAL_PS = 'DIGITAL_PS',
}

export enum EnumProfile {
  DOCTOR = 'DOCTOR',
  OPERATOR = 'OPERATOR',
}

export enum EnumOutcomeType {
  'REFERRED_TO_PRESENTIAL_CARE' = 'REFERRED_TO_PRESENTIAL_CARE',
  'UNNECESSARY_PRESENTIAL_CARE' = 'UNNECESSARY_PRESENTIAL_CARE',
}

export enum EnumScheduleAppointmentType {
  PRESENTIAL = 'PRESENTIAL',
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE',
  HYBRID = 'HYBRID',
  PROCEDURE = 'PROCEDURE',
}

export enum EnumScheduleStatus {
  DONE = 'DONE',
  SCHEDULED = 'SCHEDULED',
  ATTENDED = 'ATTENDED',
  CALL_FAILED = 'CALL_FAILED',
  NO_SHOW = 'NO_SHOW',
}

export enum EnumReportStatus {
  COMPLETED = 'COMPLETED',
  INTERRUPTED = 'INTERRUPTED',
  PENDING = 'PENDING',
  UNDEFINED = 'UNDEFINED',
}

export enum EnumAttendanceStatus {
  ABANDONED = 'ABANDONED',
  ABSENT = 'ABSENT',
  ATTENDED = 'ATTENDED',
  CALL_ENDED = 'CALL_ENDED',
  CALL_FAILED = 'CALL_FAILED',
  DONE = 'DONE',
  ENQUEUED = 'ENQUEUED',
  EXTERNAL_CALL_ENDED = 'EXTERNAL_CALL_ENDED',
  IN_CALL = 'IN_CALL',
  READY = 'READY',
}

export enum EnumScheduleBillingUnit {
  OUTPATIENT_CARE = 'OUTPATIENT_CARE',
  TELEMED_SPECIALTIES = 'TELEMED_SPECIALTIES',
  OTHERS = 'OTHERS',
}

export const billingUnitItems = {
  OUTPATIENT_CARE: 'Rede Ambulatorial',
  TELEMED_SPECIALTIES: 'Tele Especialidades',
  OTHERS: 'Outros',
};

export enum EnumDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum EnumProductStatus {
  WITHOUT_REQUEST = 'WITHOUT_REQUEST',
  REVIEW = 'REVIEW',
  REPROVED = 'REPROVED',
  APPROVED = 'APPROVED',
  SUSPENDED = 'SUSPENDED',
  ERROR = 'ERROR',
}

export enum EnumRegisterType {
  REGULAR = 'REGULAR',
  USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
  USING_MDM = 'USING_MDM',
  USING_MDM_PRO = 'USING_MDM_PRO',
  USING_MDM_PATIENT = 'USING_MDM_PATIENT',
  PRE_USER = 'PRE_USER',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  PRE_USER_HIGIA = 'PRE_USER_HIGIA',
}

export enum EnumApplicationsId {
  DIGITAL_PS = '778a612f-71a9-4809-b6b0-4571c1fac5e0',
  EXAMS = 'b437459e-d377-4197-925d-e83c32f48fe2',
  EXAMS_NEW_EXPERIENCE = 'ae303a5a-b823-4f01-944e-ecfed473c3cc',
  MY_PRACTITIONER = '740af120-f225-4f7e-9a69-e7c7d4c0aa59',
  HOSPITALS = 'ed7fc4ac-5b2f-43ff-bc57-a6fd6fb6feae',
  ONCOLOGY = 'a640347c-d40a-4f67-b2a9-cfcb0def1c88',
  CLINICAL_SUMMARY = '5271cc7d-1c46-474a-928a-6f94147a3838',
  PATIENTS_FORWARDER = '9f806b32-6635-4875-971a-b1338856d8ea',
  MEMBERSHIP = '27ba5f4d-09b1-49b3-b115-c0d536eeffbd',
  MY_PAYMENTS = 'ca61e53e-ccdf-11ed-afa1-0242ac120002',
  DASA_EDUCA = '4e33804e-2ae2-4a12-b30a-6843c8096b87',
  DOCTOR_PRESCRIPTION = '891236d3-bcf4-49b8-a1e5-849d71286b54'
}

export namespace EnumApplicationsId {
  export function isDasaEduca(applicationId: string): boolean {
    return EnumApplicationsId.DASA_EDUCA === applicationId;
  }
  export function isPrescription(applicationId: string): boolean {
    return EnumApplicationsId.DOCTOR_PRESCRIPTION === applicationId;
  }
}

const enums = {
  EnumAttendanceStatus,
  EnumAppointmentType,
  EnumOutcomeType,
  EnumScheduleAppointmentType,
  EnumScheduleStatus,
  EnumScheduleBillingUnit,
  billingUnitItems,
  EnumDay,
  EnumProductStatus,
  EnumRegisterType,
  EnumApplicationsId,
};

export default enums;
