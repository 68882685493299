import { AxiosPromise } from 'axios';
import api, { pathBffOnboarding, pathPesTelemedicine } from '../axios/api';
import { EnumProductStatus } from '~/utils/enums';
import { IAppAccessRequestPayload } from '~/store/application/types';

export interface IAppShowCase {
  id: string;
  name: string;
  url: string;
  public: boolean;
  hasAccessToApplication: boolean;
  status?: EnumProductStatus;
  reason?: string;
  roles?: string[];
  accessRequested?: boolean;
}

export interface IAppShowCaseResponse {
  applications: [IAppShowCase];
}

const { REACT_APP_BFF_API_KEY } = process.env;
const headers = {
  apikey: REACT_APP_BFF_API_KEY,
};

const AppShowCaseService = {
  get: (): AxiosPromise<IAppShowCaseResponse> =>
    api.get<IAppShowCaseResponse>(`${pathBffOnboarding}/user/applications`, { headers }),
  accessRequest: (data: IAppAccessRequestPayload): AxiosPromise =>
    api.post(`${pathPesTelemedicine}/user/applications`, data),
};

export default AppShowCaseService;
