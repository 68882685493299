/**
 * Environment Variables
 * @returns { object }
 */
const featureFlagEnv = process.env.REACT_APP_FEATURE_FLAG_ENV;
const nodeEnv = process.env.NODE_ENV;

const env = {
  environment: nodeEnv !== 'development' ? nodeEnv : 'homolog',
  autoSaveEvolutionTime: 20 * 1000, // 20 seconds
  featureFlag: {
    environment:
      featureFlagEnv ||
      (nodeEnv !== 'development' ? process.env.NODE_ENV : 'homolog'),
  },
};


export default env;