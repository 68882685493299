import React, { useEffect, useState, useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import eventBus from '~utils/event-bus';
import useStyles from './styles';

const TransitionsModal = (): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const modalContent = useRef<JSX.Element | null>();

  const handleOpen = (comp: JSX.Element) => {
    modalContent.current = comp;
    setOpen(true);
  };

  const handleClose = () => {
    modalContent.current = null;
    setOpen(false);
  };

  useEffect(() => {
    eventBus.on('openModal', handleOpen, 'modal');
    eventBus.on('closeModal', handleClose, 'modal');

    return () => {
      eventBus.remove('openModal', 'modal');
      eventBus.remove('closeModal', 'modal');
    };
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>{modalContent.current || null}</div>
      </Fade>
    </Modal>
  );
};

export default TransitionsModal;
