import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

import useStyles from './styles';

interface ChipAlertProps {
  children: JSX.Element | JSX.Element[]
}

const ChipAlert = ({children}: ChipAlertProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ErrorIcon className={classes.icon} />
      <div className={classes.textWrap}>
        {children}
      </div>
    </div>
  );
};

export default ChipAlert;
