export const pushTag = (tag: any) => {
  let metadata;
  window.dataLayer = window.dataLayer || [];
  if (tag && tag.event === 'signature_flow') {
    metadata = {
      author: 'Digital Analytics Team',
      data_layer_version: '812abc4',
    }
  }
  const tagToPush = metadata ? { ...tag, metadata } : tag;
  window.dataLayer.push(tagToPush);
};