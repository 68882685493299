export enum AuthHospitalTypes {
  LOGIN_REQUEST_HOSPITAL = '@AuthHospitalCpf/LOGIN_REQUEST_HOSPITAL',
  LOGIN_FAILURE_HOSPITAL = '@AuthHospitalCpf/LOGIN_FAILURE_HOSPITAL',
  AUTHORIZE_HOSPITAL = '@AuthHospitalCpf/AUTHORIZE_HOSPITAL',
  UNAUTHORIZE_HOSPITAL = '@AuthHospitalCpf/UNAUTHORIZE_HOSPITAL',
  VERIFY_HOSPITAL = '@AuthHospitalCpf/VERIFY_HOSPITAL',
  LOGOUT_REQUEST_HOSPITAL = '@AuthHospitalCpf/LOGOUT_REQUEST_HOSPITAL',
  LOGOUT_HOSPITAL = '@AuthHospitalCpf/LOGOUT_HOSPITAL',
  HAS_ACCEPTED_TERMS_HOSPITAL = '@AuthHospitalCpf/HAS_ACCEPTED_TERMS_HOSPITAL',
  UPDATE_TERMS_OF_USE_HOSPITAL = '@AuthHospitalCpf/UPDATE_TERMS_OF_USE_HOSPITAL',
  UPDATE_PASSWORD_HOSPITAL = '@AuthHospitalCpf/UPDATE_PASSWORD_HOSPITAL',
  CLEAN_ERROR_MESSAGE_HOSPITAL = '@AuthHospitalCpf/CLEAN_ERROR_MESSAGE_HOSPITAL',
  SHOW_MODAL_REDIRECT_TO_REGISTER = '@AuthHospitalCpf/SHOW_MODAL_REDIRECT_TO_REGISTER',
}

export interface IAuthHospitalLoginRequestPayload {
  username: string;
  password: string;
}

export interface IAuthHospitalLoginRequestAction {
  type: typeof AuthHospitalTypes.LOGIN_REQUEST_HOSPITAL;
  payload: IAuthHospitalLoginRequestPayload;
}

export interface IAuthHospitalLoginFailureAction {
  type: typeof AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL;
  errorMessage: string;
}

export interface IAuthHospitalLogoutRequestAction {
  type: typeof AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL;
}

interface IAuthHospitalAuthHospitalorizeAction {
  type: typeof AuthHospitalTypes.AUTHORIZE_HOSPITAL;
}

interface IAuthHospitalUnathorizeAction {
  type: typeof AuthHospitalTypes.UNAUTHORIZE_HOSPITAL;
}

interface IAuthHospitalVerifyAction {
  type: typeof AuthHospitalTypes.VERIFY_HOSPITAL;
}

export interface IAuthHospitalAskTermsOfUseAction {
  type: typeof AuthHospitalTypes.HAS_ACCEPTED_TERMS_HOSPITAL;
}

export interface IAuthHospitalUpdateTermsOfUseAction {
  type: typeof AuthHospitalTypes.UPDATE_TERMS_OF_USE_HOSPITAL;
  payload: IAuthHospitalLoginRequestPayload;
}

interface IAuthHospitalUpdatePasswordAction {
  type: typeof AuthHospitalTypes.UPDATE_PASSWORD_HOSPITAL;
}

interface IAuthHospitalCleanErrorMessageAction {
  type: typeof AuthHospitalTypes.CLEAN_ERROR_MESSAGE_HOSPITAL;
}

interface IAuthHospitalShowModalToRegisterAction {
  type: typeof AuthHospitalTypes.SHOW_MODAL_REDIRECT_TO_REGISTER;
  showModalToRegister: boolean;
}

export type IAuthHospitalActions =
  | IAuthHospitalLoginRequestAction
  | IAuthHospitalLoginFailureAction
  | IAuthHospitalLogoutRequestAction
  | IAuthHospitalAuthHospitalorizeAction
  | IAuthHospitalUnathorizeAction
  | IAuthHospitalVerifyAction
  | IAuthHospitalAskTermsOfUseAction
  | IAuthHospitalUpdateTermsOfUseAction
  | IAuthHospitalUpdatePasswordAction
  | IAuthHospitalCleanErrorMessageAction
  | IAuthHospitalShowModalToRegisterAction;
export interface IAuthHospitalCpfState {
  readonly isAuthenticated: boolean;
  readonly verified: boolean;
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorMessage: string;
  readonly hasAcceptedTerms: boolean;
  readonly updatePassword: boolean;
  readonly showModalToRegister: boolean;
}
