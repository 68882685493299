import React, {useState , useEffect} from "react";
import Stories from 'react-insta-stories';
import { useHistory } from "react-router-dom";
import {Container} from './style'

import ContentsAdverts from "./ContentsAdverts";

import signin from '~images/adverts/Asset1.svg'
import facilitys from '~images/adverts/Asset2.svg'
import newPage from '~images/adverts/Asset3.svg'

import { setStorage, getStorage } from '~utils/storage';
 


const Adverts = (): JSX.Element => {

  const history = useHistory()

  const store =[
    {
      content: ()=> (
        <ContentsAdverts elements={
          {
            image:signin, 
            title:'A forma de login do Nav Hospitais mudou', 
            button:false
          }
        }>  
            <p>
              Agora, para fazer login, você deverá inserir seu <strong>CPF </strong>
              e a <strong>mesma senha</strong> utilizada anteriormente. Para acessar o 
               hospital desejado, basta clicar no card de hospitais. 
            </p>
        
        </ContentsAdverts>
      ), 
     
    },
    {
      content: ()=> (
        <ContentsAdverts elements={
          {
            image:facilitys, 
            title:'O Nav Pro foi criado para facilitar sua rotina', 
            button:false
          }
        }>
          
            <p>
              Com o Nav Pro você acessa <strong>todas as soluções para profissionais </strong>
               em um único lugar, incluindo os <strong>exames</strong> feitos pelos seus pacientes
                fora do hospital, nos laboratórios e outros hospitais da Dasa. 
            </p>
        
        </ContentsAdverts>
      ) 
    },
    {
      content: ()=> (
        <ContentsAdverts elements={
          {
            image:newPage, 
            title:'Acesse a nova página de login e seja bem-vindo.', 
            button:true
          }
        }><p></p></ContentsAdverts>
      ) 
    },
  ]

  const keyStorageFirstAccessLoginHospital = 'first-access-login-hospitals'
  useEffect(() =>{
    if(getStorage(keyStorageFirstAccessLoginHospital)){
       history.push('/login');
    }else{
      setStorage(
        keyStorageFirstAccessLoginHospital,
        'openAdvents'
      )
    }
  },[]);

  return ( 
    <Container className='store'>
      <Stories
        stories={store}
        defaultInterval={10000}
        width={'100%'}
        height={'100%'}
      />
    </Container>
  )
};
 
export default Adverts