import {
  call,
  put,
  all,
  takeLatest,
  AllEffect,
  ForkEffect,
} from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import AuthServicesV3 from '~services/v3/auth';
import { IErrorResponse } from '~services/types';
import { UserTypes, IUserState } from '~store/user/types';
import { IFeatureFlagState } from '~store/feature-flag/types';
import { ApplicationState } from '~store/rootReducer';
import {
  AuthHospitalTypes,
  IAuthHospitalLoginRequestAction,
  IAuthHospitalRegisterFederalTaxIdRequestAction,
} from './types';
import { decryptToken, helpers } from '~utils';
import {
  getStorage,
  clearStorage,
  clearMemed,
} from '~utils/storage';
import AuthHospitalServices, { ILoginHospitalResponse }from '~/services/authHospital';
import { UserHospitalTypes } from '../userHospital/types';

interface IHandleLogin {
  username: string;
  password: string;
  userType: string;
}

const handleError = (error: string | AxiosError<IErrorResponse>) => {
  if (typeof error === 'string') return error;
  if (error.response?.data) {
    const { errors: errorsData } = error.response.data;
    if (errorsData && errorsData.includes('Invalid username or password')) {
      return 'CPF ou senha inválido';
    }
    if (errorsData && errorsData.includes('Approval pending for user')) {
      return 'Usuário em fase de aprovação';
    }
    if (errorsData && errorsData.includes('user not verified')) {
      return 'Token não validado';
    }
    if (errorsData && errorsData.includes('User approvation was denied')) {
      return `No momento sua solicitação de cadastro foi reprovada.\nPara mais informações, entre em contato com seu gestor`;
    }
  }
  return 'Não foi possível realizar o login. Verifique seus dados e o perfil selecionado.';
};

const loginByUnifiedLogin = (
  username: string,
  password: string,
  userType: string,
) =>
  AuthServicesV3.login({
    username,
    password,
    application: 'LIVIA_PRO',
    userType,
  });

const loginHospital = (
  username: string,
  password: string,
) =>
  AuthHospitalServices.login({
    username,
    password,
  });

export const handleLogin = async (
  payload: IHandleLogin,
): Promise<AxiosResponse> => {
  const { username, password } = payload;
  return  loginHospital(username, password);
};

export const getFeatureFlag = (state: ApplicationState): IFeatureFlagState =>
  state.featureFlag;

export function* login(action: IAuthHospitalLoginRequestAction): Generator {
  try {
    const { username, password, userType } = action.payload;


    const payloadLogin = {
      username,
      password,
      userType,
    };

    const response = (yield call(
      handleLogin,
      payloadLogin,
    )) as AxiosResponse<any>;

    const { usuario: user } = response?.data;

    if (user.cpf) {
      yield put({ type: AuthHospitalTypes.SET_FEDERALTAX_ID, federalTaxId: user.cpf });
      yield put({ type: AuthHospitalTypes.SET_FEDERALTAX_ID_ALREADY_REGISTERED, federalTaxIdAlreadyRegistered: true });
      yield put({ type: AuthHospitalTypes.SET_SHOW_MODAL_FEEDBACK_FEDERALTAX_ID, showModalFeedBackFederalTaxId: true });
    }

    yield put({ type: UserHospitalTypes.GET_USER_HOSPITAL_SUCCESS, payload: user});
    yield put({ type: AuthHospitalTypes.AUTHORIZE_HOSPITAL });

  } catch (error: any) {
    if (error?.response?.status === 403) {
      yield put({ type: AuthHospitalTypes.HAS_ACCEPTED_TERMS_HOSPITAL });
    } else {
      const errorMessage = handleError(error);
      yield put({ type: AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL, errorMessage });
      yield put({ type: AuthHospitalTypes.UNAUTHORIZE_HOSPITAL });
    }
  }
}

export function* checkAuth(): Generator {
  try {
    const token = getStorage('token');
    if (!token) throw new Error('Unathorize');

    const tokenDecode: unknown = decryptToken.decode(atob(token));
    const user = tokenDecode as IUserState;
    if (!user) throw new Error('Invalid token');

    const storageAppointmentType = getStorage('appointmentType');
    const appointmentType =
      storageAppointmentType ||
      helpers.translateRolesToAppointmentType(user.roles, user.userType);
    const payload = { ...user, appointmentType };

    yield put({ type: UserTypes.GET_USER_SUCCESS, payload });

    yield put({ type: AuthHospitalTypes.AUTHORIZE_HOSPITAL });
  } catch (error: any) {
    yield put({ type: AuthHospitalTypes.UNAUTHORIZE_HOSPITAL });
  }
}

export function* logout(): Generator {
  clearStorage();
  clearMemed();
  yield put({ type: AuthHospitalTypes.LOGOUT_HOSPITAL });
}

interface IHandleFederalTaxId {
  cpf: string;
  username: string;
}

const registerFederalTaxIdHospital = (
  cpf: string,
  username: string,
) =>
  AuthHospitalServices.registerFederalTaxId({
    cpf,
    username,
  });

export const handleRegisterFederalTaxId = async (
  payload: IHandleFederalTaxId,
): Promise<AxiosResponse> => {
  const { cpf, username } = payload;
  return  registerFederalTaxIdHospital(cpf, username);
};

export function* registerFederalTaxId(action: IAuthHospitalRegisterFederalTaxIdRequestAction): Generator {
  try {
    const { cpf, username } = action.payload;
    const payload = {
      cpf,
      username,
    };
    const response = (yield call(
      handleRegisterFederalTaxId,
      payload,
    )) as AxiosResponse<any>;
    if (response?.data?.sucess) {
      yield put({ type: AuthHospitalTypes.SET_FEDERALTAX_ID, federalTaxId: cpf });
      yield put({ type: AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED, federalTaxIdRegistered: true });
    } else
    if (!response?.data?.sucess && response?.data?.message) {
      yield put({ type: AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_ERROR, federalTaxIdRegisteredError: true, messageFederalTaxIdRegisteredError: response?.data?.message });
    } else {
      yield put({ type: AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_FAIL, federalTaxIdRegisteredFail: true });  
    }
  } catch (error: any) {
    yield put({ type: AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_FAIL, federalTaxIdRegisteredFail: true });
  }
}

export default function* authSagas(): Generator<AllEffect<ForkEffect>> {

  yield all([
    takeLatest(AuthHospitalTypes.LOGIN_REQUEST_HOSPITAL, login),
    takeLatest(AuthHospitalTypes.VERIFY_HOSPITAL, checkAuth),
    takeLatest(AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL, logout),
    takeLatest(AuthHospitalTypes.REGISTER_FEDERAL_TAX_ID_REQUEST, registerFederalTaxId),
  ]);
}
