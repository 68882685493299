import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(3),
      '& .MuiFormLabel-root.Mui-error, & .MuiFormHelperText-root.Mui-error': {
        color: theme.palette.danger[60],
      },
    },
    select: {
      fontWeight: 400,
      '& .MuiOutlinedInput-notchedOutline legend > span': {
        paddingRight: 12,
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0,0,0,.12)',
      },
    },
    label: {
      fontWeight: 400,
    },
    textHelper: {
      ...theme.typography.subtitle2,
    },
  }),
);
