import { AxiosPromise } from 'axios';
import api, { pathUserManagementDasa } from '../axios/api';

export interface IForgotPassword {
  email: string;
}
const url = `${pathUserManagementDasa}/account/forgot-password`;
const ForgotPasswordService = {
  forgotPassword: (data: IForgotPassword): AxiosPromise => api.post(url, data),
};

export default ForgotPasswordService;
