import { call, all, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import BFFUserServices, { IGetUpdateUserPayload, IGetCouncilResponse } from '~services/bff';
import {
  IPersonalData as IPersonalDataService,
  IProfessionalData as IProfessionalDataService,
  IContact,
} from '~services/bff/types';
import format from '~utils/format';
import helpers from '~utils/helpers';
import {
  MyAccountTypes,
  IUpdatePersonalDataAction,
  IUpdateProfessionalDataAction,
  IUpdateContactsDataAction,
  IPersonalData,
  IProfessionalData,
  IContactsData,
  emptyContactsData,
} from './types';

const transformPersonalDataPayload = (data: IPersonalData): IPersonalDataService => {
  const { birthDate: dataBirthDate, federalTaxId: dataFederalTaxId, ...rest } = data;
  const birthDate = helpers.getDateFormatted(dataBirthDate);
  const federalTaxId = format.toOnlyNumbers(dataFederalTaxId);
  return { ...rest, birthDate, federalTaxId };
};

const transformPersonalDataResponse = (data: IPersonalDataService): IPersonalData => {
  const { birthDate: dataBirthDate, federalTaxId: dataFederalTaxId, ...rest } = data;
  const birthDate = helpers.getDateFromFullDate(dataBirthDate);
  const federalTaxId = format.toCPF(dataFederalTaxId);
  return { ...rest, birthDate, federalTaxId };
};

const transformProfessionalDataPayload = (data: IProfessionalData): IProfessionalDataService => {
  const formattedProfessionalData = {
    // councils: [{
    //   registryNumber: data.councilRegistryNumber || '',
    //   regionalAbbreviation: data.councilRegionalAbbreviation || '',
    //   uf: data.uf || '',
    // }],
    specialties: data.specialities.map(item => ({ id: Number(item) })) || [],
  };
  return formattedProfessionalData;
};

const transformProfessionalDataResponse = (data: IProfessionalDataService): IProfessionalData => {
  const { councils: dataCouncils, specialities: dataSpecialities } = data;
  let councilRegionalAbbreviation = '',
    councilRegistryNumber = '',
    uf = '';
  if (dataCouncils && dataCouncils[0]) {
    councilRegionalAbbreviation = dataCouncils[0].regionalAbbreviation;
    councilRegistryNumber = dataCouncils[0].registryNumber;
    uf = dataCouncils[0].uf;
  }
  const specialities = dataSpecialities?.map(item => item.id.toString()) || [];

  return { councilRegionalAbbreviation, councilRegistryNumber, uf, specialities };
};

const transformContactsDataPayload = (data: IContactsData): IContact[] => {
  let formattedContacts: IContact[] = [];
  if (data?.email.value) {
    const formattedEmail = {
      ...data.email,
      id: Number(data.email.id),
    };
    formattedContacts.push(formattedEmail);
  }
  if (data?.phone.value) {
    const formattedPhone = {
      ...data.phone,
      id: Number(data.phone.id),
    };
    formattedContacts.push(formattedPhone);
  }
  if (data?.contacts && data.contacts.length > 0) {
    const contactsFormatted = data.contacts.map(obj => {
      const id = Number(obj.id);
      const idContact = Number.isNaN(id) ? '' : id;
      return helpers.removeEmptyAttr({ ...obj, id: idContact });
    });
    formattedContacts.push(...(contactsFormatted as IContact[]));
  }
  return formattedContacts;
};

const transformContactsDataResponse = (data: IContact[]): IContactsData => {
  let email: IContact, phone: IContact;

  const emails = data.filter(o => o.contactType === 'EMAIL');
  if (emails && emails.length > 0) {
    const emailValidated = emails.find(o => o.validated);
    email = emailValidated || emails[0];
  } else {
    email = emptyContactsData.email;
  }

  const phones = data.filter(o => o.contactType === 'CELULAR');
  if (phones && phones.length > 0) {
    const phoneValidated = phones.find(o => o.validated);
    phone = phoneValidated || phones[0];
    phone = { ...phone, value: format.toGlobalPhoneMask(phone.value, true) };
  } else {
    phone = emptyContactsData.phone;
  }

  const contacts = data
    .filter(o => o.id !== email.id && o.id !== phone.id)
    .map(contact => {
      let formattedValue = contact.value;
      if (contact.contactType === 'CELULAR') {
        formattedValue = format.toGlobalPhoneMask(contact.value, true);
      }
      if (
        contact.contactType === 'COMERCIAL' ||
        contact.contactType === 'RESIDENCIAL' ||
        contact.contactType === 'INTERNACIONAL'
      ) {
        formattedValue = format.toGlobalPhoneMask(contact.value);
      }
      return { ...contact, value: formattedValue };
    });
  return { email, phone, contacts };
};

export function* getUser(): Generator {
  try {
    const { data: councilAndSpecialties } = (yield call(
      BFFUserServices.getCouncil,
    )) as AxiosResponse<IGetCouncilResponse>;
    const { data } = (yield call(BFFUserServices.getUser)) as AxiosResponse<IGetUpdateUserPayload>;
    const personalData = transformPersonalDataResponse(data.personalData);
    const professionalData = transformProfessionalDataResponse(data.professionalData);
    const contactsData = transformContactsDataResponse(data.contacts);
    yield put({ type: MyAccountTypes.SET_DIGITAL, payload: data.digital });
    yield put({ type: MyAccountTypes.SET_PERSONAL_DATA, payload: personalData });
    yield put({ type: MyAccountTypes.SET_PROFESSIONAL_DATA, payload: professionalData });
    yield put({ type: MyAccountTypes.SET_CONTACTS_DATA, payload: contactsData });
    yield put({ type: MyAccountTypes.SET_COUNCIL_AND_SPECIALTIES, payload: councilAndSpecialties });
    yield put({ type: MyAccountTypes.GET_USER_SUCCESS });
    yield put({ type: MyAccountTypes.SET_TRUST, payload: data.trust });
    yield put({ type: MyAccountTypes.SET_CREATED_AT, payload: data.createdAt });
  } catch (error: any) {
    yield put({ type: MyAccountTypes.GET_USER_FAILURE });
  }
}

export function* updatePersonalData(action: IUpdatePersonalDataAction): Generator {
  try {
    const payload = transformPersonalDataPayload(action.payload);
    yield call(BFFUserServices.updateUser, { personalData: payload });
    yield put({ type: MyAccountTypes.SET_PERSONAL_DATA, payload: action.payload });
    yield put({ type: MyAccountTypes.UPDATE_SUCCESS, payload: 'personalData' });
  } catch (error: any) {
    yield put({ type: MyAccountTypes.UPDATE_FAILURE, payload: 'personalData' });
  }
}

export function* updateProfessionalData(action: IUpdateProfessionalDataAction): Generator {
  try {
    const payload = transformProfessionalDataPayload(action.payload);
    yield call(BFFUserServices.updateUser, { professionalData: payload });
    yield put({ type: MyAccountTypes.SET_PROFESSIONAL_DATA, payload: action.payload });
    yield put({ type: MyAccountTypes.UPDATE_SUCCESS, payload: 'professionalData' });
  } catch (error: any) {
    yield put({ type: MyAccountTypes.UPDATE_FAILURE, payload: 'professionalData' });
  }
}

export function* updateContactsData(action: IUpdateContactsDataAction): Generator {
  try {
    const payload = transformContactsDataPayload(action.payload);
    const { data } = (yield call(BFFUserServices.updateUser, {
      contacts: payload,
    })) as AxiosResponse<IGetUpdateUserPayload>;
    const contactsData = transformContactsDataResponse(data.contacts);
    yield put({ type: MyAccountTypes.SET_CONTACTS_DATA, payload: contactsData });
    yield put({ type: MyAccountTypes.UPDATE_SUCCESS, payload: 'contactsData' });
  } catch (error: any) {
    yield put({ type: MyAccountTypes.UPDATE_FAILURE, payload: 'contactsData' });
  }
}

export default function* myAccountSagas() {
  yield all([
    takeLatest(MyAccountTypes.GET_USER_REQUEST, getUser),
    takeLatest(MyAccountTypes.UPDATE_PERSONAL_DATA, updatePersonalData),
    takeLatest(MyAccountTypes.UPDATE_PROFESSIONAL_DATA, updateProfessionalData),
    takeLatest(MyAccountTypes.UPDATE_CONTACTS_DATA, updateContactsData),
  ]);
}
