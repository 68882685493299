import { AxiosPromise } from 'axios';
import api, { pathUserHospital } from '../axios/api';

const basePath = `${pathUserHospital}/users`;

export interface IResponseCreateUserHospital {
  email: string;
  federalTaxId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IPayloadCreateUserHospital {
  email: string;
  password: string;
  federalTaxId: string;
  newPassword: string;
}


const UserHospitalServices = {
  createUserHospital: (payload: IPayloadCreateUserHospital): AxiosPromise =>
    api.post(basePath, payload),
};

export default UserHospitalServices;
