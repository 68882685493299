export enum AuthHospitalTypes {
  LOGIN_REQUEST_HOSPITAL = '@AuthHospital/LOGIN_REQUEST_HOSPITAL',
  LOGIN_FAILURE_HOSPITAL = '@AuthHospital/LOGIN_FAILURE_HOSPITAL',
  AUTHORIZE_HOSPITAL = '@AuthHospital/AUTHORIZE_HOSPITAL',
  UNAUTHORIZE_HOSPITAL = '@AuthHospital/UNAUTHORIZE_HOSPITAL',
  VERIFY_HOSPITAL = '@AuthHospital/VERIFY_HOSPITAL',
  LOGOUT_REQUEST_HOSPITAL = '@AuthHospital/LOGOUT_REQUEST_HOSPITAL',
  LOGOUT_HOSPITAL = '@AuthHospital/LOGOUT_HOSPITAL',
  HAS_ACCEPTED_TERMS_HOSPITAL = '@AuthHospital/HAS_ACCEPTED_TERMS_HOSPITAL',
  UPDATE_TERMS_OF_USE_HOSPITAL = '@AuthHospital/UPDATE_TERMS_OF_USE_HOSPITAL',
  UPDATE_PASSWORD_HOSPITAL = '@AuthHospital/UPDATE_PASSWORD_HOSPITAL',
  CLEAN_ERROR_MESSAGE_HOSPITAL = '@AuthHospital/CLEAN_ERROR_MESSAGE_HOSPITAL',
  SET_FEDERALTAX_ID_REGISTERED = '@AuthHospital/SET_FEDERALTAX_ID_REGISTERED',
  SET_FEDERALTAX_ID_ALREADY_REGISTERED = '@AuthHospital/SET_FEDERALTAX_ID_ALREADY_REGISTERED',
  SET_FEDERALTAX_ID_REGISTERED_ERROR = '@AuthHospital/SET_FEDERALTAX_ID_REGISTERED_ERROR',
  SET_FEDERALTAX_ID_REGISTERED_FAIL = '@AuthHospital/SET_FEDERALTAX_ID_REGISTERED_FAIL',
  SET_FEDERALTAX_ID = '@AuthHospital/SET_FEDERALTAX_ID',
  SET_SHOW_MODAL_FEEDBACK_FEDERALTAX_ID = '@AuthHospital/SET_SHOW_MODAL_FEEDBACK_FEDERALTAX_ID',
  REGISTER_FEDERAL_TAX_ID_REQUEST = '@userHospital/REGISTER_FEDERAL_TAX_ID_REQUEST',
}

export interface IAuthHospitalLoginRequestPayload {
  username: string;
  password: string;
  remind: boolean;
  userType: string;
}

export interface IAuthHospitalLoginRequestAction {
  type: typeof AuthHospitalTypes.LOGIN_REQUEST_HOSPITAL;
  payload: IAuthHospitalLoginRequestPayload;
}

export interface IAuthHospitalLoginFailureAction {
  type: typeof AuthHospitalTypes.LOGIN_FAILURE_HOSPITAL;
  errorMessage: string;
}

export interface IAuthHospitalLogoutRequestAction {
  type: typeof AuthHospitalTypes.LOGOUT_REQUEST_HOSPITAL;
}

interface IAuthHospitalAuthHospitalorizeAction {
  type: typeof AuthHospitalTypes.AUTHORIZE_HOSPITAL;
}

interface IAuthHospitalUnathorizeAction {
  type: typeof AuthHospitalTypes.UNAUTHORIZE_HOSPITAL;
}

interface IAuthHospitalVerifyAction {
  type: typeof AuthHospitalTypes.VERIFY_HOSPITAL;
}

export interface IAuthHospitalAskTermsOfUseAction {
  type: typeof AuthHospitalTypes.HAS_ACCEPTED_TERMS_HOSPITAL;
}

export interface IAuthHospitalUpdateTermsOfUseAction {
  type: typeof AuthHospitalTypes.UPDATE_TERMS_OF_USE_HOSPITAL;
  payload: IAuthHospitalLoginRequestPayload;
}

interface IAuthHospitalUpdatePasswordAction {
  type: typeof AuthHospitalTypes.UPDATE_PASSWORD_HOSPITAL;
}

interface IAuthHospitalCleanErrorMessageAction {
  type: typeof AuthHospitalTypes.CLEAN_ERROR_MESSAGE_HOSPITAL;
}

interface IAuthHospitalSetFederalTaxIdRegistered {
  type: typeof AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED;
  federalTaxIdRegistered: boolean; 
}

interface IAuthHospitalSetFederalTaxIdAlreadyRegistered {
  type: typeof AuthHospitalTypes.SET_FEDERALTAX_ID_ALREADY_REGISTERED;
  federalTaxIdAlreadyRegistered: boolean; 
}

interface IAuthHospitalSetFederalTaxIdRegisteredError {
  type: typeof AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_ERROR;
  federalTaxIdRegisteredError: boolean;
  messageFederalTaxIdRegisteredError: string;
}

interface IAuthHospitalSetFederalTaxIdRegisteredFail {
  type: typeof AuthHospitalTypes.SET_FEDERALTAX_ID_REGISTERED_FAIL;
  federalTaxIdRegisteredFail: boolean;
}

interface IAuthHospitalSetFederalTaxId {
  type: typeof AuthHospitalTypes.SET_FEDERALTAX_ID;
  federalTaxId: string; 
}

interface IAuthHospitalSetShowModalFeedBackFederalTaxId {
  type: typeof AuthHospitalTypes.SET_SHOW_MODAL_FEEDBACK_FEDERALTAX_ID;
  showModalFeedBackFederalTaxId: boolean; 
}

export interface IAuthHospitalRegisterFederalTaxIdRequestPayload {
  cpf: string;
  username: string;
}

export interface IAuthHospitalRegisterFederalTaxIdRequestAction {
  type: typeof AuthHospitalTypes.REGISTER_FEDERAL_TAX_ID_REQUEST;
  payload: IAuthHospitalRegisterFederalTaxIdRequestPayload;
}

export type IAuthHospitalActions =
  | IAuthHospitalLoginRequestAction
  | IAuthHospitalLoginFailureAction
  | IAuthHospitalLogoutRequestAction
  | IAuthHospitalAuthHospitalorizeAction
  | IAuthHospitalUnathorizeAction
  | IAuthHospitalVerifyAction
  | IAuthHospitalAskTermsOfUseAction
  | IAuthHospitalUpdateTermsOfUseAction
  | IAuthHospitalUpdatePasswordAction
  | IAuthHospitalCleanErrorMessageAction
  | IAuthHospitalSetFederalTaxIdRegistered
  | IAuthHospitalSetFederalTaxIdAlreadyRegistered
  | IAuthHospitalSetFederalTaxIdRegisteredError
  | IAuthHospitalSetFederalTaxId
  | IAuthHospitalSetShowModalFeedBackFederalTaxId
  | IAuthHospitalRegisterFederalTaxIdRequestAction
  | IAuthHospitalSetFederalTaxIdRegisteredFail;
export interface IAuthHospitalState {
  readonly isAuthenticated: boolean;
  readonly verified: boolean;
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorMessage: string;
  readonly hasAcceptedTerms: boolean;
  readonly updatePassword: boolean;
  readonly federalTaxIdRegistered: boolean;
  readonly federalTaxIdAlreadyRegistered: boolean;  
  readonly federalTaxId: string;
  readonly showModalFeedBackFederalTaxId: boolean;
  readonly federalTaxIdRegisteredError: boolean;
  readonly messageFederalTaxIdRegisteredError: string;
  readonly federalTaxIdRegisteredFail: boolean;
}
