import { AxiosError } from 'axios';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import moment from 'moment';
import { IErrorResponse } from '~services/types';
import {
  EnumOutcomeType,
  EnumScheduleAppointmentType,
  EnumScheduleStatus,
  EnumAttendanceStatus,
  EnumAppointmentType,
  EnumProfile,
  EnumReportStatus,
  EnumDay,
  EnumRegisterType,
} from './enums';

import window from './env';

type DateTime = number | string | Date;

const Helpers = {
  getInitialNames: (name: string): string => {
    const dataName = name.match(/\b(\w)/g) || [];
    const initials = dataName.join('');
    return initials.substring(0, 2) || '';
  },
  formatDate: (date: string): string => {
    let event;
    if (date) {
      event = moment(date).toDate();
      return event.toLocaleString();
    }
    return '';
  },
  buildParams: (data: any): string => {
    if (!data) return '';
    let params = '';
    params += `?${Object.keys(data)
      .filter((key) => data[key] !== '' && data[key] !== undefined)
      .map((key) => `${key}=${data[key]}`)
      .join('&')}`;
    return params;
  },
  getAge: (date: string, format = 'YYYY-MM-DD'): number => {
    const formattedDate = moment(date, 'YYYY-MM-DD', true).isValid();
    let dateYear;

    if (!formattedDate) dateYear = moment(date, 'DD/MM/YYYY');
    else dateYear = moment(date, format);

    if (!dateYear.isValid()) return -1;

    const today = moment();
    return today.diff(dateYear, 'y');
  },
  getResetPasswordToken: (): string => {
    const mapSearchUrlValues = new Map<string, string>();
    const { search } = window.location;
    const values: Array<string> = search
      .slice(1)
      .split('&')
      .map((param: string) => param.split('='))[0];

    const isValidTokenParam = values[0] === 'resetPasswordToken';

    if (isValidTokenParam) {
      mapSearchUrlValues.set(values[0], values[1]);
      const token = String(mapSearchUrlValues.get(values[0]));
      return token;
    }
    return '';
  },
  getResetPasswordTokenFromHashRoute: (): string => {
    const mapSearchUrlValues = new Map<string, string>();
    const search = window.location.hash?.split('?')[1];
    if (!search) {
      return '';
    }
    const values: Array<string> = search
      .slice(0)
      .split('&')
      .map((param: string) => param.split('='))[0];

    const isValidTokenParam = values[0] === 'resetPasswordToken';

    if (isValidTokenParam) {
      mapSearchUrlValues.set(values[0], values[1]);
      const token = String(mapSearchUrlValues.get(values[0]));
      return token;
    }
    return '';
  },
  getResetPasswordTokenByUsingSearch: (search: string): string => {
    const mapSearchUrlValues = new Map<string, string>();
    const values: Array<string> = search
      .slice(1)
      .split('&')
      .map((param: string) => param.split('='))[0];

    const isValidTokenParam = values[0] === 'resetPasswordToken';

    if (isValidTokenParam) {
      mapSearchUrlValues.set(values[0], values[1]);
      const token = String(mapSearchUrlValues.get(values[0]));
      return token;
    }
    return '';
  },
  getDateFormatted: (date: string | Date, format = 'YYYY-MM-DD'): string =>
    moment(date, 'DD/MM/YYYY').format(format),
  getDateByString: (date: string): Date | undefined => {
    if (date === '') return undefined;
    return moment(date, 'DD/MM/YYYY').toDate();
  },
  getStartDayOfMonth: (date: string): string =>
    moment(date).startOf('month').format('YYYY-MM-DD'),
  getEndDayOfMonth: (date: string): string =>
    moment(date).endOf('month').format('YYYY-MM-DD'),
  getFullDate: (date: string): string =>
    moment(date).format('DD/MM/YYYY - HH:mm'),
  getTimeFromDate: (date: string): string => moment(date).format('HH:mm'),
  getDateFromFullDate: (date: string): string =>
    moment(date).format('DD/MM/YYYY'),
  getFormattedFederalTaxId: (pFederalTaxId: string | undefined): string => {
    if (!pFederalTaxId) return '-';
    const federalTaxId = pFederalTaxId?.replace(/[^\d]/g, '');
    return federalTaxId?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  },
  getRangeBetweenDates: (start: string, end: string): number =>
    moment(end).diff(moment(start), 'days'),
  getRangeBetweenNumbers: (start: number, end: number): number[] => {
    if ((!start && !end) || start >= end) return [];
    const list = [];
    for (let i = start; i <= end; i += 1) {
      list.push(i);
    }
    return list;
  },
  getAttendanceDatetimeDuration: (
    date?: DateTime,
    startTime?: DateTime,
    endTime?: DateTime,
  ): string => {
    const dateFormatted = moment(date).format('DD MMM, ddd');
    const startTimeFormatted = moment(startTime).format('HH[h]:mm');
    const endTimeFormatted = moment(endTime).format('HH[h]:mm');
    return `${dateFormatted} — ${startTimeFormatted} - ${endTimeFormatted}`;
  },
  translateOutcomeType: (outcomeType: string): string => {
    switch (outcomeType) {
      case EnumOutcomeType.REFERRED_TO_PRESENTIAL_CARE:
        return 'Sim';
      case EnumOutcomeType.UNNECESSARY_PRESENTIAL_CARE:
        return 'Não';
      default:
        return 'Sem Registro';
    }
  },
  translateScheduleAppointmentType: (appointmentType: string): string => {
    switch (appointmentType) {
      case EnumScheduleAppointmentType.LOCAL:
      case EnumScheduleAppointmentType.PRESENTIAL:
        return 'Presencial';
      case EnumScheduleAppointmentType.REMOTE:
        return 'Telemedicina';
      case EnumScheduleAppointmentType.HYBRID:
        return 'Online/Presencial';
      case EnumScheduleAppointmentType.PROCEDURE:
        return 'Procedimento';
      default:
        return 'Sem Registro';
    }
  },
  translateToEnumScheduleAppointmentType: (
    appointmentType: string,
  ): EnumScheduleAppointmentType => {
    switch (appointmentType) {
      case 'Presencial':
        return EnumScheduleAppointmentType.LOCAL;
      case 'Telemedicina':
        return EnumScheduleAppointmentType.REMOTE;
      default:
        return EnumScheduleAppointmentType.REMOTE;
    }
  },
  translateScheduleStatus: (status: string): string => {
    switch (status) {
      case EnumScheduleStatus.SCHEDULED:
        return 'Agendado';
      case EnumScheduleStatus.ATTENDED:
        return 'Realizado';
      case EnumScheduleStatus.CALL_FAILED:
        return 'Interrompido';
      case EnumScheduleStatus.DONE:
        return 'Iniciado';
      case EnumScheduleStatus.NO_SHOW:
        return 'Ausente';
      default:
        return 'Sem Registro';
    }
  },
  translateToEnumScheduleStatus: (status: string): EnumScheduleStatus => {
    switch (status) {
      case 'Agendado':
        return EnumScheduleStatus.SCHEDULED;
      case 'Realizado':
        return EnumScheduleStatus.ATTENDED;
      case 'Interrompido':
        return EnumScheduleStatus.CALL_FAILED;
      case 'Iniciado':
        return EnumScheduleStatus.DONE;
      case 'Ausente':
      case 'Desistência':
        return EnumScheduleStatus.NO_SHOW;
      default:
        return EnumScheduleStatus.SCHEDULED;
    }
  },
  translateReportStatus: (status: string): string => {
    switch (status) {
      case EnumReportStatus.PENDING:
        return 'Pendente';
      case EnumReportStatus.COMPLETED:
        return 'Concluído';
      case EnumReportStatus.INTERRUPTED:
        return 'Interrompido';
      default:
        return 'Sem Definição';
    }
  },
  translateToEnumReportStatus: (status: string): EnumReportStatus => {
    switch (status) {
      case 'Pendente':
        return EnumReportStatus.PENDING;
      case 'Concluído':
        return EnumReportStatus.COMPLETED;
      case 'Interrompido':
        return EnumReportStatus.INTERRUPTED;
      default:
        return EnumReportStatus.UNDEFINED;
    }
  },
  translateStatus: (status: string): string => {
    switch (status) {
      case EnumAttendanceStatus.READY:
      case EnumAttendanceStatus.ENQUEUED:
      case EnumScheduleStatus.SCHEDULED:
        return 'Agendado';
      case EnumAttendanceStatus.ATTENDED:
      case EnumScheduleStatus.ATTENDED:
        return 'Realizado';
      case EnumAttendanceStatus.CALL_ENDED:
      case EnumAttendanceStatus.CALL_FAILED:
      case EnumAttendanceStatus.EXTERNAL_CALL_ENDED:
      case EnumScheduleStatus.CALL_FAILED:
        return 'Interrompido';
      case EnumAttendanceStatus.IN_CALL:
      case EnumScheduleStatus.DONE:
        return 'Iniciado';
      case EnumAttendanceStatus.ABANDONED:
        return 'Desistência';
      case EnumAttendanceStatus.ABSENT:
      case EnumScheduleStatus.NO_SHOW:
        return 'Ausente';
      default:
        return 'Sem Registro';
    }
  },
  translateAppointmentType: (type: string): string => {
    switch (type) {
      case EnumAppointmentType.MY_PRACTITIONER:
        return 'Meu consultório';
      case EnumAppointmentType.DIGITAL_PS:
      default:
        return 'COA';
    }
  },
  translateRolesToAppointmentType: (
    roles: string[],
    profile?: string,
  ): string => {
    if (profile && profile === EnumProfile.OPERATOR)
      return EnumAppointmentType.MY_PRACTITIONER;
    if (!roles || roles.length !== 1) return '';
    return roles[0];
  },
  getInitialCapitalize: (text: string): string => {
    const newText = text.toLowerCase();
    return newText[0].toUpperCase() + newText.substr(1);
  },
  translateDay: (day: string): string => {
    switch (day) {
      case EnumDay.MONDAY:
        return 'Segunda-feira';
      case EnumDay.TUESDAY:
        return 'Terça-feira';
      case EnumDay.WEDNESDAY:
        return 'Quarta-feira';
      case EnumDay.THURSDAY:
        return 'Quinta-feira';
      case EnumDay.FRIDAY:
        return 'Sexta-feira';
      case EnumDay.SATURDAY:
        return 'Sábado';
      case EnumDay.SUNDAY:
        return 'Domingo';
      default:
        return 'Sem registro';
    }
  },
  injectScript: (id: string, url: string, callback?: () => void): void => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  },
  hasScriptByRegex: (regex: RegExp) => {
    if (!document.scripts) return false;
    return Array.from(document.scripts).reduce(
      (isPresent, script) => (isPresent ? isPresent : regex.test(script.src)),
      false,
    );
  },
  handleErrorType: (error: string | AxiosError<IErrorResponse>) => {
    if (typeof error === 'string') return error;
    return error.response?.data?.error || 'UNEXPECTED_ERROR';
  },
  dynatraceAction: (description: string, event: string) => {
    if (window.dtrum) {
      const action = window.dtrum.enterAction(description, event);
      window.dtrum.leaveAction(action);
    };
  },
  injectGrecaptcha: (isEnterprise: boolean = true, callback?: any) => {
    const SITE_KEY = isEnterprise
      ? process.env.REACT_APP_GREPCAPTCHA_ENTERPRISE_SITE_KEY
      : process.env.REACT_APP_GREPCAPTCHA_FREE_SITE_KEY;

    const url = isEnterprise
      ? `https://www.google.com/recaptcha/enterprise.js?render=${SITE_KEY}`
      : `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;

    const isScriptExist = document.getElementById(isEnterprise ? 'grecaptcha-enterprise' : 'grecaptcha');

    if (!isScriptExist) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = 'grecaptcha';
      if (callback) {
        script.onload = callback;
      }
      document.body.appendChild(script);
    }
  },
  grecaptchaToken: (isEnterprise: boolean = true, action: string = 'submit'): Promise<string> => {
    const SITE_KEY = isEnterprise
      ? process.env.REACT_APP_GREPCAPTCHA_ENTERPRISE_SITE_KEY
      : process.env.REACT_APP_GREPCAPTCHA_FREE_SITE_KEY;

    const hasGrecaptcha = isEnterprise
      ? Boolean(window && window.grecaptcha && window.grecaptcha.enterprise)
      : Boolean(window && window.grecaptcha);

    return new Promise((resolve, reject) => {
      if (hasGrecaptcha) {
        const grecaptcha = isEnterprise
          ? window.grecaptcha.enterprise
          : window.grecaptcha;

        grecaptcha.ready(() => {
          grecaptcha.execute(SITE_KEY, { action })
            .then((token: any) => { resolve(token) });
        });
      } else {
        return resolve('no-captcha');
      }
    });
  },
  isRegisterTypeUsingMDM: (registerType: string): boolean => {
    return (registerType === EnumRegisterType.USING_MDM 
      || registerType === EnumRegisterType.USING_MDM_PRO 
      || registerType === EnumRegisterType.USING_MDM_PATIENT)
  },
  diffBetweenDates: (startDate: string, endDate: string, format: moment.unitOfTime.Diff = 'days'): number =>
    moment(endDate).diff(moment(startDate), format),
  breakpointMatchesUp: (breakpoint: Breakpoint | number): boolean => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(breakpoint));
  },
  removeEmptyAttr: (obj: any) => {
    return Object.entries(obj)
      .filter(([_, v]) => (typeof v === 'boolean' || !!v))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  },
  revertString: (value: string) => {
    return value.split('').reverse().join('');
  }
};

export default Helpers;
