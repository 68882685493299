import { select, call, put, all, takeLatest, AllEffect, ForkEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import RegisterServices, {
  IVerificationResponse,
  IVerificationHeaders,
  ISavePayload,
  IToken,
  IResendTokenPayload,
  IContact,
  ISaveResponse,
} from '~services/v4/register';
import UserServices from '~services/user';
import format from '~utils/format';
import helpers from '~utils/helpers';
import { EnumRegisterType, EnumProfile } from '~utils/enums';
import registerTags from '~utils/googleTags/registerTags';
import { ApplicationState } from '~store/rootReducer';
import {
  IRegisterState,
  RegisterTypes,
  IRegisterUser,
  IVerificationRequestAction,
  IVerificationRequestPayload,
  IContactOption,
  ISaveRequestAction,
  ISaveRequestPayload,
  IConfirmTokenRequestAction,
  IRegisterLegacyAction,
  IResendTokenRequestAction,
} from './types';
import BFFUserServices, { IGetCouncilResponse } from '~/services/bff';
import { MyAccountTypes } from '../myAccount/types';

export const getRegisterStore = (state: ApplicationState): IRegisterState => state.register;

const transformVerificationPayloadToHeader = (
  payload: IVerificationRequestPayload,
): IVerificationHeaders => {
  const { recaptchaToken, federalTaxId, userType, invitationToken } = payload;
  return {
    'Invitation-Token': invitationToken || undefined,
    'Captcha-Token': recaptchaToken || 'no-captcha',
    'Federal-Tax-Id': format.toOnlyNumbers(federalTaxId),
    'User-Type': userType,
  };
};

const getPhoneFromContacts = (contacts?: IContact[]): string => {
  if (!contacts || contacts.length === 0) return '';
  const phones = contacts.filter(o => o.type === 'MOBILE');
  return phones[0] && phones[0].value ? phones[0].value : '';
};

const getEmailFromContacts = (contacts?: IContact[]): string => {
  if (!contacts || contacts.length === 0) return '';
  const emails = contacts.filter(o => o.type === 'EMAIL');
  return emails[0] && emails[0].value ? emails[0].value : '';
};

const transformVerificationResponseToSetRegisterUser = (
  data: IVerificationResponse,
): Partial<IRegisterUser> => {
  const { registerType, personalData, contacts } = data;
  const isMDMPatient = registerType === EnumRegisterType.USING_MDM_PATIENT;
  const phoneNumber = !isMDMPatient ? getPhoneFromContacts(contacts) : '';
  const email = !isMDMPatient ? getEmailFromContacts(contacts) : '';
  const birthDate = personalData?.birthDate
    ? helpers.getDateFromFullDate(personalData.birthDate)
    : '';

  return {
    registerType,
    name: personalData?.name || '',
    surname: personalData?.surname || '',
    birthDate,
    sex: personalData?.sex || '',
    phoneNumber,
    email,
  };
};

const transformToPayloadSaveToken = (
  sendById: string,
  contactOptions: IContactOption[],
): IToken => {
  const sendId = parseInt(sendById, 10);
  const contactById = contactOptions.filter(option => option.id === sendId);
  const sendBy = contactById[0] ? contactById[0].type : 'ALL';
  if (sendId > 0) {
    return { sendBy, contactId: sendId };
  }
  return { sendBy };
};

const transformToPayloadSave = (
  data: ISaveRequestPayload,
  registerStore: IRegisterState,
): ISavePayload => {
  const { password, sendById, invitationToken, userType, doctorPatientId } = data;
  const { registerUser, contactOptions, disabledFields } = registerStore;

  const {
    documentNumber,
    documentOrg,
    documentState,
    speciality,
    specialityId,
    specialities,
    registerType,
    federalTaxId,
    email,
    phoneNumber,
    birthDate,
    sexualOrientation,
    sendById: sendByIdStore,
    sendByText,
    sendByType,
    ...personalDataUser
  } = registerUser;

  const personalData = {
    ...personalDataUser,
    federalTaxId: format.toOnlyNumbers(federalTaxId),
    email: email && !disabledFields.email ? email : undefined,
    phoneNumber:
      phoneNumber && !disabledFields.phoneNumber ? format.toOnlyNumbers(phoneNumber) : undefined,
    birthDate: birthDate ? helpers.getDateFormatted(birthDate) : undefined,
    sexualOrientation: sexualOrientation || undefined,
  };
  const professionalData = {
    registryNumber: documentNumber,
    council: documentOrg,
    uf: documentState,
    specialities,
  };
  const credentials = { password };
  const token = transformToPayloadSaveToken(sendById, contactOptions);

  return {
    registerType,
    personalData,
    professionalData,
    credentials,
    token,
    invitationToken,
    userType,
    doctorPatientId,
  };
};

const transformToPayloadResendToken = (
  registerStore: IRegisterState,
  userType?: string,
): IResendTokenPayload => {
  const { registerUser } = registerStore;
  const {
    federalTaxId,
    phoneNumber,
    email,
    registerType,
    sendById,
    sendByType,
    password,
    ...rest
  } = registerUser;
  const personalData = {
    federalTaxId: format.toOnlyNumbers(federalTaxId),
    phoneNumber: phoneNumber ? format.toOnlyNumbers(phoneNumber) : undefined,
    email,
  };
  const professionalData = {
    type: userType,
  };
  const credentials = { password };
  const contactId = parseInt(sendById, 10);
  const token = {
    sendBy: sendByType,
    contactId,
  };

  return {
    registerType,
    personalData,
    professionalData,
    credentials,
    token,
    userType: userType ? userType : undefined,
  };
};

const verifyRegisterType = (registerType: string) => {
  if (helpers.isRegisterTypeUsingMDM(registerType)) {
    return ' - profissional existente';
  }
  if (registerType === EnumRegisterType.REGULAR) {
    return ' - novo profissional';
  }
  return '';
};

const handleErrorVerficiationUser = (error: any) => {
  const checkErrors = error?.response?.data?.errors;
  if (checkErrors && checkErrors.includes('invitation-token must be a valid UUID')) {
    return 'invalid-invitation';
  }
  return typeof error === 'string' ? error : 'unexpected-error';
};

const userIsEligible = (data: IVerificationResponse) => {
  const { registerType, reasons, contacts } = data;
  if (registerType === EnumRegisterType.USER_ALREADY_REGISTERED) return 'already-registered';
  if (registerType === EnumRegisterType.NOT_ELIGIBLE) {
    if (reasons?.includes('no contact data on MDM')) return 'no-contact-on-mdm';
    if (
      reasons?.includes('invitation not found') ||
      reasons?.includes("invitation doesn't match user document")
    )
      return 'invalid-invitation';
    return 'not-eligible';
  }
  if (registerType === EnumRegisterType.USING_MDM_PRO) {
    if (!contacts || contacts.length === 0) return 'no-contact-on-mdm';
    const emails = contacts.filter(o => o.type === 'EMAIL');
    const phones = contacts.filter(o => o.type === 'MOBILE');
    if (!emails[0] && !phones[0]) return 'no-contact-on-mdm';
  }
};

export function* verificationUser(action: IVerificationRequestAction): Generator {
  try {
    const { payload } = action;
    const headers = transformVerificationPayloadToHeader(payload);
    const response = yield call(RegisterServices.verification, headers);
    const { data } = response as AxiosResponse<IVerificationResponse>;

    const error = userIsEligible(data);
    if (error) throw error;

    let pre_user_higia = {};
    if (data.registerType === 'PRE_USER_HIGIA') {
      const { professionalData } = data;
      pre_user_higia = {
        documentOrg: professionalData?.council,
        documentNumber: professionalData?.registryNumber,
        documentState: professionalData?.uf,
        speciality: professionalData?.specialities,
      };
    }

    const registerUser = {
      ...transformVerificationResponseToSetRegisterUser(data),
      federalTaxId: payload.federalTaxId,
      ...pre_user_higia,
    };
    const contactOptions = data.contacts || [];
    const specialtyOptions = data.specialities || [];

    const disabledFields = {
      name: !!registerUser.name,
      surname: !!registerUser.surname,
      birthDate: !!registerUser.birthDate,
      sex: !!registerUser.sex,
      phoneNumber: !!registerUser.phoneNumber,
      email: !!registerUser.email,
    };

    const { data: councilAndSpecialties } = (yield call(
      BFFUserServices.getCouncil,
    )) as AxiosResponse<IGetCouncilResponse>;
    const councilAndSpecialtiesOptions = councilAndSpecialties || [];

    yield put({ type: RegisterTypes.SET_DISABLED_FIELDS, disabledFields });
    yield put({ type: RegisterTypes.SET_REGISTER_USER, registerUser });
    yield put({ type: RegisterTypes.SET_CONTACT_OPTIONS, contactOptions });
    yield put({ type: RegisterTypes.SET_SPECIALTY_OPTIONS, specialtyOptions });
    yield put({ type: RegisterTypes.VERIFICATION_SUCCESS });
    yield put({
      type: RegisterTypes.SET_COUNCIL_AND_SPECIALTIES,
      payload: councilAndSpecialtiesOptions,
    });
  } catch (error: any) {
    const errorMessage = handleErrorVerficiationUser(error);
    yield put({ type: RegisterTypes.VERIFICATION_FAILURE, errorMessage });
  }
}

export function* save(action: ISaveRequestAction): Generator {
  try {
    const registerStore = (yield select(getRegisterStore)) as IRegisterState;
    const { payload } = action;
    const { recaptchaToken, ...restPayload } = payload;
    const recaptcha = recaptchaToken || 'no-captcha';
    const payloadSave = transformToPayloadSave(restPayload, registerStore);
    const response = yield call(RegisterServices.save, payloadSave, recaptcha);
    const { data } = response as AxiosResponse<ISaveResponse>;
    const sendBy = {
      sendById: data.contactId,
      sendByType: data.sendBy,
    };
    const registerUser = { ...payload, ...sendBy };
    yield put({ type: RegisterTypes.SET_REGISTER_USER, registerUser });
    yield put({ type: RegisterTypes.SAVE_SUCCESS });
  } catch (error: any) {
    const errorType = helpers.handleErrorType(error);
    yield put({ type: RegisterTypes.SAVE_FAILURE, errorMessage: errorType });
  }
}

export function* saveLegacy(action: IRegisterLegacyAction): Generator {
  try {
    // const registerStore = (yield select(getRegisterStore)) as IRegisterState;
    const { payload } = action;
    // const payloadSave = transformToPayloadSave(payload, registerStore);

    yield call(RegisterServices.saveLegacy, payload);

    const registerUser = { ...payload };
    yield put({ type: RegisterTypes.SET_REGISTER_USER, registerUser });
    yield put({ type: RegisterTypes.SAVE_SUCCESS });
  } catch (error: any) {
    const errorType = helpers.handleErrorType(error);
    yield put({ type: RegisterTypes.SAVE_FAILURE, errorMessage: errorType });
  }
}

export function* resendToken(action: IResendTokenRequestAction): Generator {
  try {
    const registerStore = (yield select(getRegisterStore)) as IRegisterState;
    const { userType } = action;
    const payloadResendToken = transformToPayloadResendToken(registerStore, userType);

    yield call(RegisterServices.resendToken, payloadResendToken);

    yield put({ type: RegisterTypes.RESEND_TOKEN_SUCCESS });
  } catch (error: any) {
    yield put({ type: RegisterTypes.RESEND_TOKEN_FAILURE });
  }
}

export function* confirmToken(action: IConfirmTokenRequestAction): Generator {
  try {
    const { token, userType } = action;
    const registerStore = (yield select(getRegisterStore)) as IRegisterState;
    const { federalTaxId } = registerStore.registerUser;
    const username = format.toOnlyNumbers(federalTaxId);
    const payload = { username, token, userType };

    yield call(UserServices.confirmToken, payload);
    yield put({ type: RegisterTypes.CONFIRM_TOKEN_SUCCESS });
  } catch (error: any) {
    const registerStore = (yield select(getRegisterStore)) as IRegisterState;
    const { registerType } = registerStore.registerUser;
    const flux = verifyRegisterType(registerType);
    helpers.dynatraceAction(`cadastro${flux} - token invalido`, 'click');
    registerTags.pushFormErrorTag({ errorMessage: 'Token - Token inválido' });
    yield put({ type: RegisterTypes.CONFIRM_TOKEN_FAILURE });
  }
}

export default function* registerSagas(): Generator<AllEffect<ForkEffect>> {
  yield all([
    takeLatest(RegisterTypes.VERIFICATION_REQUEST, verificationUser),
    takeLatest(RegisterTypes.SAVE_REQUEST, save),
    takeLatest(RegisterTypes.SAVE_LEGACY_REQUEST, saveLegacy),
    takeLatest(RegisterTypes.RESEND_TOKEN_REQUEST, resendToken),
    takeLatest(RegisterTypes.CONFIRM_TOKEN_REQUEST, confirmToken),
  ]);
}
