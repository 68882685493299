import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const btn = (theme: Theme): CSSProperties => ({
  ...theme.typography.h5,
  borderRadius: theme.spacing(1),
  maxWidth: '216px',
  height: '3rem',
  textTransform: 'none',
});

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
    },
    sideImage: {
      backgroundColor: 'blue',
      flexShrink: 1,
      width: '45vw',
    },
    formWrapper: {
      boxSizing: 'border-box',
      flexShrink: 0,
      flexDirection: 'column',
      marginTop: 58,
    },
    form: {
      backgroundColor: 'white',
      margin: 'auto',
      width: 'calc(100vw - 20px)',
      maxWidth: '480px',
      padding: '20px',
    },
    title: {
      ...theme.typography.h2,
      color: theme.palette.text.primary,
      margin: '0',
      textAlign: 'center',
    },
    description: {
      ...theme.typography.subtitle2,
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
    applicationName: {
      // ...theme.typography.subtitle2,
      // color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
    signup: {
      ...theme.typography.subtitle2,
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    textField: {
      marginTop: theme.spacing(3),
      '& label, & p': {
        ...theme.typography.subtitle2,
      },
      '& .MuiFormLabel-root.Mui-error, .MuiFormHelperText-root.Mui-error, .MuiFormLabel-asterisk.Mui-error': {
        color: theme.palette.danger[60],
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.danger[60],
      },
    },
    textError: {
      ...theme.typography.subtitle1,
      color: theme.palette.danger[60],
      textAlign: 'center',
      whiteSpace: 'pre-line',
    },
    reminders: {
      ...theme.typography.subtitle2,
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: 40,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    remindersUnifiedLogin: {
      justifyContent: 'flex-end',
    },
    checkbox: {
      margin: 0,
      '& > span': {
        ...theme.typography.link,
        alignSelf: 'center',
        color: theme.palette.grayscale[60],
        padding: 0,
        marginRight: theme.spacing(2),
      },
      '& svg': {
        ...theme.typography.subtitle2,
      },
    },
    forgot: {
      ...theme.typography.link,
      alignSelf: 'center',
      color: theme.palette.grayscale[60],
    },
    buttonWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between', // uncomment after sign-up exists
      // justifyContent: 'center',
    },
    buttonSeparator: {
      ...theme.typography.subtitle2,
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
    button: {
      ...btn(theme),
      maxWidth: '100%',
    },
    buttonSecondary: {
      ...btn(theme),
      border: '1px solid #797c86',
      maxWidth: '100%',
    },
    logo: {
      height: 24,
      display: 'block',
      margin: '0 auto 50px auto',
    },
    info: {
      whiteSpace: 'nowrap',
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.8px",
      color: "#61636B",
      marginRight: '5px'
    },
    link: {
      whiteSpace: 'nowrap',
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 'bold',
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.8px",
      color: "#000000"
    },
    modalHospitalsNotification: {
      margin: '0 auto',
    },
  }),
);
