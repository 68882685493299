import ButtonMaterial from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";

import navLogo from '~images/nav-logo.svg';
import useStyles from './styles';

export type  IProps = {
    onClick?: ()=> void;
    className?: string;
}

const Header = (props: IProps) => {
    const classes = useStyles();
    const { onClick, className} = props;

    return(
        <div className={classes.navBar}>
            <ButtonMaterial
                className={onClick ? classes.buttonBack : classes.buttonBackNone} 
                startIcon={<ArrowBackIcon color="primary"/>}
                color="primary"
                onClick={()=> onClick ? onClick() : ()=>{}}>
            Voltar
            </ButtonMaterial>
            <div className={clsx(onClick ? classes.logoHolder : classes.logoHolderbuttonBackNone, className)}>
                <img src={String(navLogo)} alt="Nav logo" className={classes.logo} />
            </div>
        </div>
    );
}

export default Header;