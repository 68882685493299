export enum AuthTypes {
  LOGIN_REQUEST = '@auth/LOGIN_REQUEST',
  LOGIN_FAILURE = '@auth/LOGIN_FAILURE',
  AUTHORIZE = '@auth/AUTHORIZE',
  UNATHORIZE = '@auth/UNATHORIZE',
  VERIFY = '@auth/VERIFY',
  SET_VERIFICATION_TYPE = '@auth/SET_VERIFICATION_TYPE',
  LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST',
  LOGOUT = '@auth/LOGOUT',
  HAS_ACCEPTED_TERMS = '@auth/HAS_ACCEPTED_TERMS',
  UPDATE_TERMS_OF_USE = '@auth/UPDATE_TERMS_OF_USE',
  UPDATE_PASSWORD = '@auth/UPDATE_PASSWORD',
  CLEAN_ERROR_MESSAGE = '@auth/CLEAN_ERROR_MESSAGE',
  SET_REDIRECT_ERROR = '@auth/SET_REDIRECT_ERROR',
}

export interface IAuthLoginRequestPayload {
  federalTaxId: string;
  password: string;
  remind: boolean;
  userType: string;
  recaptchaToken?: string;
  idRedirect?: string | null;
  automaticLogin?: boolean;
}

export interface IAuthSetVerificationTypePayload {
  type: string;
}

export interface IAuthLoginRequestAction {
  type: typeof AuthTypes.LOGIN_REQUEST;
  payload: IAuthLoginRequestPayload;
}

export interface IAuthLoginFailureAction {
  type: typeof AuthTypes.LOGIN_FAILURE;
  errorMessage: string;
}

export interface IAuthLogoutRequestAction {
  type: typeof AuthTypes.LOGOUT_REQUEST;
}

interface IAuthAuthorizeAction {
  type: typeof AuthTypes.AUTHORIZE;
}

interface IAuthUnathorizeAction {
  type: typeof AuthTypes.UNATHORIZE;
}

export interface IAuthVerifyAction {
  type: typeof AuthTypes.VERIFY;
  payload?: string | null;
}

interface IAuthSetVerificationTypeAction {
  type: typeof AuthTypes.SET_VERIFICATION_TYPE;
  payload: IAuthSetVerificationTypePayload;
}

export interface IAuthAskTermsOfUseAction {
  type: typeof AuthTypes.HAS_ACCEPTED_TERMS;
}

export interface IAuthUpdateTermsOfUseAction {
  type: typeof AuthTypes.UPDATE_TERMS_OF_USE;
  payload: IAuthLoginRequestPayload;
}

interface IAuthUpdatePasswordAction {
  type: typeof AuthTypes.UPDATE_PASSWORD;
}

interface IAuthCleanErrorMessageAction {
  type: typeof AuthTypes.CLEAN_ERROR_MESSAGE;
}

export interface IAuthSetRedirectErrorAction {
  type: typeof AuthTypes.SET_REDIRECT_ERROR;
  payload: boolean;
}

export type IAuthActions =
  | IAuthLoginRequestAction
  | IAuthLoginFailureAction
  | IAuthLogoutRequestAction
  | IAuthAuthorizeAction
  | IAuthUnathorizeAction
  | IAuthVerifyAction
  | IAuthSetVerificationTypeAction
  | IAuthAskTermsOfUseAction
  | IAuthUpdateTermsOfUseAction
  | IAuthUpdatePasswordAction
  | IAuthCleanErrorMessageAction
  | IAuthSetRedirectErrorAction;

export interface IAuthState {
  readonly isAuthenticated: boolean;
  readonly verified: boolean;
  readonly verificationType: string;
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorMessage: string;
  readonly hasAcceptedTerms: boolean;
  readonly updatePassword: boolean;
  readonly redirectError?: boolean;
}
