import { IAuthActions, IAuthState, AuthTypes } from './types';

const initialState: IAuthState = {
  isAuthenticated: false,
  loading: false,
  error: false,
  verificationType: '',
  errorMessage: '',
  verified: false,
  hasAcceptedTerms: true,
  updatePassword: false,
  redirectError: false,
};

export default function authReducer(
  state = initialState,
  action: IAuthActions,
): IAuthState {
  switch (action.type) {
    case AuthTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        hasAcceptedTerms: true,
        errorMessage: '',
      };

    case AuthTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        hasAcceptedTerms: true,
        errorMessage: action.errorMessage,
      };

    case AuthTypes.SET_VERIFICATION_TYPE:
      return {
        ...state,
        verificationType: action.payload.type
      };

    case AuthTypes.VERIFY:
      return {
        ...state,
        verified: false,
        loading: true,
        error: false,
        hasAcceptedTerms: true,
      };

    case AuthTypes.AUTHORIZE:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        verified: true,
        hasAcceptedTerms: true,
        updatePassword: false,
      };

    case AuthTypes.UNATHORIZE:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        verified: true,
        hasAcceptedTerms: true,
      };

    case AuthTypes.LOGOUT_REQUEST:
      return { ...initialState, hasAcceptedTerms: true };

    case AuthTypes.HAS_ACCEPTED_TERMS:
      return {
        ...state,
        loading: false,
        error: false,
        hasAcceptedTerms: false,
      };

    case AuthTypes.UPDATE_TERMS_OF_USE:
      return { ...state, loading: true, error: false, hasAcceptedTerms: true };

    case AuthTypes.UPDATE_PASSWORD:
      return {
        ...state,
        loading: false,
        error: false,
        verified: true,
        updatePassword: true,
      };

    case AuthTypes.CLEAN_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
        error: false,
      };

    case AuthTypes.SET_REDIRECT_ERROR:
      return {
        ...state,
        redirectError: action.payload,
      };

    default:
      return state;
  }
}
