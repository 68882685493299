import { ApplicationTypes, IAppAccessRequestAction, IApplicationActions } from './types';
import { UserTypes } from '~store/user/types';
import ApplicationService from '~services/application'
import { IAppShowCaseResponse } from '~/services/application';
import { EnumApplicationsId } from '~/utils/enums';

import {
    call,
    put,
    all,
    takeLatest,
    AllEffect,
    ForkEffect,
  } from 'redux-saga/effects';
import { AxiosResponse } from "axios";

export function* getApplications(action: IApplicationActions): Generator {
  try{
    const response = (yield call(ApplicationService.get)) as AxiosResponse<IAppShowCaseResponse>
    if (response.data.applications && response.data.applications.length > 0) {
      const myPayments = response.data.applications.find((item) => item.id === EnumApplicationsId.MY_PAYMENTS)
      yield put({ type: UserTypes.SET_ROLES_HIGIA, payload: myPayments?.roles || [] });
    }
    if (response?.status == 200)
      return yield put({ type: ApplicationTypes.APPLICATION_SUCCESSFUL, payload: response?.data });
    return yield put({ type: ApplicationTypes.APPLICATION_FAILED, payload: response?.data });
  }catch{
    return yield put({ type: ApplicationTypes.APPLICATION_FAILED, payload: {}});
  }
}

export function* applicationAccessRequest(action: IAppAccessRequestAction): Generator {
  try{
    const response = (yield call(ApplicationService.accessRequest, {...action.payload})) as AxiosResponse
    if (response?.status == 200 || response?.status == 201 || response?.status == 204)
      return yield put({type: ApplicationTypes.APPLICATION_ACCESS_SUCCESSFUL, payload: {...action.payload}})
    return yield put({ type: ApplicationTypes.APPLICATION_ACCESS_FAILED, payload: {...action.payload}})
  }catch{
    return yield put({ type: ApplicationTypes.APPLICATION_ACCESS_FAILED, payload: {...action.payload}})
  }
}

export default function* ApplicationSagas(): Generator<AllEffect<ForkEffect>> {
    yield all([
      takeLatest(ApplicationTypes.APPLICATION_REQUEST, getApplications),
      takeLatest(ApplicationTypes.APPLICATION_ACCESS_REQUEST, applicationAccessRequest)
    ]);
  }