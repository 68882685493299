export enum UserHospitalTypes {
  GET_USER_HOSPITAL = '@userHospital/GET_USER_HOSPITAL',
  GET_USER_HOSPITAL_SUCCESS = '@userHospital/GET_USER_HOSPITAL_SUCCESS',
  GET_USER_HOSPITAL_FAILURE = '@userHospital/GET_USER_HOSPITAL_FAILURE',
  SET_USER_HOSPITAL_APPOINTMENT_TYPE = '@userHospital/SET_USER_HOSPITAL_APPOINTMENT_TYPE',
  SET_USER_HOSPITAL_FEDERAL_TAX_ID = '@userHospital/SET_USER_HOSPITAL_FEDERAL_TAX_ID',
}

export enum UserHospitalProfiles {
  DOCTOR = 'DOCTOR',
  OPERATOR = 'OPERATOR',
}

export enum UserHospitalAttendanceType {
  DIGITAL_PS = 'DIGITAL_PS',
  MY_PRACTITIONER = 'MY_PRACTITIONER',
}

interface IGetUserHospitalAction {
  type: typeof UserHospitalTypes.GET_USER_HOSPITAL;
}

interface IGetUserHospitalSuccessAction {
  type: typeof UserHospitalTypes.GET_USER_HOSPITAL_SUCCESS;
  payload: IUserHospitalState;
}

interface IGetUserHospitalSuccessAction {
  type: typeof UserHospitalTypes.GET_USER_HOSPITAL_SUCCESS;
  payload: IUserHospitalState;
}

interface IGetUserHospitalFailureAction {
  type: typeof UserHospitalTypes.GET_USER_HOSPITAL_FAILURE;
}

interface ISetFederalTaxId {
  type: typeof UserHospitalTypes.SET_USER_HOSPITAL_FEDERAL_TAX_ID;
  federalTaxId: string;
}

export type IUserHospitalActions =
  | IGetUserHospitalAction
  | IGetUserHospitalSuccessAction
  | IGetUserHospitalFailureAction
  | ISetFederalTaxId;

export interface IUserHospitalState {
  readonly id:string,
  readonly nome:string,
  readonly email:string,
  readonly telefone:string,
  readonly sexo:string,
  readonly cpf:string,
  readonly dataNascimento:string,
  readonly termoAceito:boolean,
  readonly perfil: string[],
  readonly hospitais: object[],
  readonly userType: UserHospitalProfiles,
  readonly initialNames: string,
}
