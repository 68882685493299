import jwt from 'jsonwebtoken';

const FederalTaxIdCryptography = {
  encode: (federalTaxId: string) => jwt.sign(federalTaxId, 'federalTaxId'),
  decode: (federalTaxId: string) => jwt.decode(federalTaxId),
};

const ObjectCryptography = {
  encode: (object: string) => jwt.sign(object, 'informations'),
  decode: (object: string) => jwt.decode(object),
};

const crypto = {
  FederalTaxIdCryptography,
  ObjectCryptography,
};

export default crypto;